<template>
  <div class="form-get-demo-zh">
    <h1>申请免费试用</h1>
    <p>请完善您的资料信息，在线提交软件试用申请</p>
    <div class="demo-form form-get-demo-desktop">
      <el-form ref="form" :model="form" label-width="80px" >
        <el-form-item label="姓名" class="bitian">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号" class="bitian">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="城市" class="bitian">
          <el-input v-model="form.city"></el-input>
        </el-form-item>
        <el-form-item label="公司名称" class="bitian">
          <el-input v-model="form.companyName"></el-input>
        </el-form-item>
        <el-form-item label="备注 ">
          <el-input type="textarea" v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item >
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="demo-form form-get-demo-mobile">
      <el-form ref="form" :model="form"  label-position="top">
        <el-form-item label="姓名" class="bitian">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号" class="bitian">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="城市" class="bitian">
          <el-input v-model="form.city"></el-input>
        </el-form-item>
        <el-form-item label="公司名称" class="bitian">
          <el-input v-model="form.companyName"></el-input>
        </el-form-item>
        <el-form-item label="备注 ">
          <el-input type="textarea" v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item >
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
  import axios from "axios"

  export default {
    name: 'GetMyDemoZh',
    data() {
      return {
        form: {
          name:'',
          phone:'',
          city:'',
          companyName: '',
          desc: '',
          isPass: false,
          tip: '该字段不能为空'
        },
      }
    },
    methods: {
      check() {
        
        const form = this.form;
       
        if(form.name.replace(/\s*/g, "") ==''){  //不为空 
          form.isPass = false
          form.tip = '姓名不能为空'
          return
        }else if(!/^1[3|4|5|7|8][0-9]{9}$/.test(form.phone)){
          form.isPass = false
          form.tip = '请填写正确的手机号'
          return
        }else if(form.city.replace(/\s*/g, "") ==''){
          form.isPass = false
          form.tip = '城市不能为空'
          return
        }else if(form.companyName.replace(/\s*/g, "") ==''){
          form.isPass = false
          form.tip = '公司名称不能为空'
          return
        }else{
          form.isPass = true
        }
        
      },
      onSubmit() {
        const form = this.form;
        const self = this;
          
        this.check()
        if(!this.form.isPass){
          this.$notify({
            title: '提示',
            message: this.form.tip,
            type: 'warning'
          });
        
        }else{
          //console.log('提交')
          axios.get('https://hz.chenksoft.com/ckapi/api/1/v2/add_customer_information.jsp',{
            params: {
              token: "chenksoft!@!",
              customer_name: form.name,
              cellphone_number: form.phone,
              city: form.city,
              corporate_number: form.companyName,
              note: form.desc,
            }
          }).then(function(response){
            
            if(response.data.code==0){
              self.$notify({
                title: '成功',
                message: '您已提交成功',
                type: 'success'
              });
              window.location.href = "http://saas.chenksoft.com/"
            }else{
               self.$notify.error({
                title: '失败',
                message: '申请失败，请您稍后再试！',
              });
            }
            //清空
            self.form.name = ''
            self.form.phone = ''
            self.form.companyName = ''
            self.form.city = ''
            self.form.desc = ''
          })
        }
      }
    }
  }
</script>
<style lang="scss">
.form-get-demo-zh{
  padding: 2rem 0;
}
.form-get-demo-mobile{
  display: none;
}
</style>