<template>
  <div class="content pp-scheme">
    <!-- <h2>{{$store.state.systemName}}</h2> -->
    <h3 class="title-p">{{leftTitle}}</h3>
    <div v-for="item1 in items" :key="item1.index">
      <h4>{{item1.title}}</h4>
      <el-divider></el-divider>
      <p v-html="item1.content"></p>
      
    </div>
  </div>
</template>
<script>

import img1 from '../assets/1.png'
import { get, imageFile } from '../plugins/request.js'

export default {
  name: 'PPScheme',
  props: {
    leftTitle: String
  },
  data() {
    return {
      systemName: '晨科客户管理系统',
      item: [
        { 
          title: '客户管理',
          content:[
            { title: '客户资料' ,content:'对业务员拨打客户的电话录音进行管理1、根据对方电话号码、电话录音所属用户、通话类型、通话日期范围进行模糊查询2、支持将需要的通话记录导出3、双击可以上传、下载通话记录4、可以查看未接电话，查看之后，记录调到下一条记录，可选择是否查看' },
            { title: '公共客户' ,content:'对公共客户资料进行增删改查，导入导出，发生短信。1、根据客户名称，客户类别，客户性质，客户来源，业务员，星级进行模糊查询2、客户类别下拉框可选择潜在客户、意向客户、重点客户、成交客户，根据不同的客户其相对应的颜色也会发生相对应的改变。' },
            { title: '待处理客户' ,content:'1、可以根据website、ID、URL查询待处理客户数据信息2、销售主管可以固定选择未联系客户分配给业务员' },
          ]
        },
        {
          title: '工作提醒',
          content: [
            { title: '跟单提醒',content: '对跟单的信息进行管理1、用户可以根据客户名称、跟单日期范围、跟单人员、销售状态进行模糊查询跟单信息2、可以删除没用的跟单'}
          ]
        }
      ],
      items: [

      ]
    }
  },
  methods: {
    getData() {
      const id = this.$store.state.currentLanguage.id
      const params = this.$route.params.id;
      const self = this;
      get('/detailed.jsp', {langtype: id, sysId: params}).then(function(res){
        //console.log(res)
        const data0 = res.data.data;
        
        // for(let item of data0){
        //   item.content = item.content.replace(/(\d\、)/g, '<br\/>$1').replace(/^<br\/>/g, '')
        // }
        self.items = data0
      })
    }
  },
  created: function(){
    this.getData()
    
    // for(let item of this.items){
    //   for(let item1 of item.content){
    //     item1.content = item1.content.replace(/(\d\、)/g, '<br\/>$1').replace(/^<br\/>/g, '')
    //   }
    // }
  },
  watch: {
    '$route': function(to, from) {
      if(to.params.id){
         this.getData()
      }
    }
  }
}
</script>
<style lang="scss">
.pp-scheme {
  
    
    h2 {
      font-size: 1.4rem;
      margin: 0;
      font-weight: 400;
    }
    
    h3 {
      font-size: 1.1rem;
      margin: 1.5rem 0 1rem;
      font-weight: 400;
    }
    h4 {
      font-size: 0.9rem;
      font-weight: 400;
      margin: 1.5rem 0 1rem;
    }
    h5 {
      font-size: 0.7rem;
      margin: 1.5rem 0 0.5rem;
      font-weight: 400;
    }
    p{
      opacity: 0.7;
      line-height: 1.8;
    }
    img {
      margin-top: 2rem;
    }
}

</style>
