<template>
  <div class="content pp-introduction">
    <!-- <h2>{{$store.state.systemName}}</h2> -->
    <h3 class="title-p">{{ title }}</h3>
    <div v-html="content" class="des"></div>
    <img :src='img' alt="加载失败">
    <!-- <el-image :src='img'></el-image> -->
    <!-- <img :src='img1' alt=""> -->
  </div>
</template>
<script>
import img1 from '../assets/1.png'
import { get, imageFile } from '../plugins/request.js'

export default {
  name: 'PPIntroduction',
  props: {
    title: String
  },
  data() {
    return {
      content: '',
      img: '',
    }
  },
  methods: {
    getData() {
      const id = this.$store.state.currentLanguage.id
      const params = this.$route.params.id;
      const self = this;
      get('/system_synopsis.jsp', {langtype: id, sId: params}).then(function(res){
        //console.log(res)
        const data0 = res.data.data;
        self.$store.commit('changeSystemName', data0[0]['title_1'])
        if(data0[0]['content']){
          self.content = data0[0]['content']
        }
        //console.log(res.data.data)
        self.img = imageFile(data0[0]['picture'])
      })
    }
  },
  created: function(){
    console.log(333)
    this.getData()
  },
  watch: {
    '$route': function(to, from) {
      if(to.params.id){
         this.getData()
      }
    }
  }
}
</script>
<style lang="scss">
.pp-introduction {
  .des{
    line-height: 1.7;
  }
    h2 {
      font-size: 1.4rem;
      margin: 0;
      font-weight: 400;
      
    }
    .title {
      color: #1F87E8;
      background: #ecf5ff;
      padding: 0.5rem 0;
      &::before{
        content: '';
        display: inline-block;
        width: 0.2rem;
        height: 0.9rem;
        margin-right: 0.5rem;
        background-color: #1F87E8;
      }
    }
    h3 {
      font-size: 1.1rem;
      margin: 1.5rem 0 1rem;
      font-weight: 400;
    }
    h4 {
      font-size: 0.9rem;
      font-weight: 400;
      margin: 1.5rem 0 0.5rem;
    }
    h5 {
      font-size: 0.8rem;
      margin: 1.5rem 0 0.5rem;
      font-weight: 400;
    }
    p{
      opacity: 0.7;
      line-height: 1.8;
    }
    img {
      margin-top: 2rem;
      max-width: 100%;
    }
 
}
</style>
