<template>
  <div class="titlebar row-container">
    <h5 class="title"><span>{{msg.title}}</span></h5>
    <!-- <p class="modified">{{msg.modified}}</p> -->
  <div class="line"></div>
  <p class="subtitle">{{msg.subTitle}}</p>
  </div>
</template>

<script>
export default {
  name: 'TitleBar',
  props: {
    msg: Object
  }
}
</script>

<style lang="scss">
  .titlebar {
    padding: 3rem 0 2.5rem;
    text-align: center;
    .title {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1.5rem;
      span{
        position: relative;
        &::after{
         opacity: 0.4;
        content: '';
        display: block;
        width: 100%;
        height: 0.1rem;
        background: #1F87E8;
        position: absolute;
        left: 0;
        right: 0;
        top: 1.5rem;
        bottom: 0;
        margin: 1rem auto;
      }
      }
      //text-transform: uppercase;
    }
    .modified {
      opacity: 0.6;
      position: relative;
      font-size: 0.7rem;
      margin: 0.25rem 0 0.5rem 0;
      color: transparent;
      text-transform: uppercase;
      &::after{
        content: '';
        display: block;
        width: 5rem;
        height: 0.1rem;
        background: #aaa;
        position: absolute;
        left: 0;
        right: 0;
        top: -0.5rem;
        bottom: 0;
        margin: 1rem auto;
      }
    }
    .subtitle {
      font-size: 0.9rem;
        opacity: 0.7;
      }
  }
  
</style>