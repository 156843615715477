<template>
  <div class="pitemrow3 row-container">
    <TitleBar :msg="msg"/>
    <ul class="items">
      <li v-for="item in item3" :key="item.index">
        <a href="http://iot.chenksoft.com/" v-if="item.id==25" target="_blank"><img :src="item.image" alt=""></a>
        <router-link :to="`/solutions/${item.id}`" v-else>
          <img :src="item.image" alt=""> </router-link>
          <div class="text">
            <h3 class="title">{{ item.name }}</h3>
            <p class="description">{{ item.title }}</p>
          </div>
      </li>
    </ul>
  </div>
</template>

<script>
import TitleBar from './TitleBar.vue'

import bg1 from '../assets/贸易.png'
import bg2 from '../assets/服务行业.png'
import bg3 from '../assets/广告媒体.png'
import bg4 from '../assets/检验检测.png'
import bg5 from '../assets/健身连锁.png'
import bg6 from '../assets/教育与培训.png'
import bg7 from '../assets/金融投资行业.png'
import bg9 from '../assets/设备租赁行业.png'
import bg10 from '../assets/生产制造.png'
import bg11 from '../assets/图书.png'
import bg12 from '../assets/物联网.png'
import bg13 from '../assets/物流仓储.png'

import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

export default {
  name: 'PItemRow3',
  data() {
    return {
      msg: {
        title: '解决方案',
        subTitle: '根据流程和需求，量身定制个性化解决方案'
      },
      item3: ''
    }
  },
  components: {
    TitleBar
  },
  methods: {
    getData(params) {
      const self = this;
      get('/solution_module.jsp', params).then(function(res){
        //console.log(res.data.data)
        const data0 = res.data.data;
        //self.items = data0;
        self.item3 = data0.map(function(item){
          item.image = imageFile(item.logo)
          return item
        })
      })
    },
    getValue(params) {
      const self = this;
      
      // get('/get_value.jsp', params).then(function(res){
      //   //console.log(res.data.data[0].result)
      //   const title = getQueryString(res.data.data[0].result)
      //   self.msg.title = title['hTitle3']
      //   self.msg.subTitle = title['hTitle4']
      // })
    }
  },
  created: function(){
    const id = this.$store.state.currentLanguage.id
    this.getValue({lang: id, value: 'hTitle3;hTitle4;'})
    this.getData({langtype: id})
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      this.getData({langtype: id})
      this.getValue({lang: id, value: 'hTitle3;hTitle4;'})
    }
  }
}
</script>

<style lang="scss">
.pitemrow3 {
  text-align: center;
  padding-bottom: 3rem;
  .items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row wrap;
     -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    li {
      width: 17.5rem;
      text-align: left;
      margin-bottom: 3rem;
      background: #fff;
      border-radius: 0.5rem;
      padding: 0.75rem 0.75rem 1rem 0.75rem;
      box-sizing: border-box;
      text-align: center;
      margin: 13px;
      img {
        width: 100%;
        border: 0;
        &:hover{
          opacity: 0.8;
        }
      }
      .text{
        font-size: 0.7rem;
        .title {
          font-weight: normal;
          //text-transform: uppercase;
          margin: 1rem 0;
        }
        .description {
          opacity: 0.6;
          //hyphens:auto; 英文换行加-
          //text-align: justify;
        }
      }
    }
  }
  
}
</style>