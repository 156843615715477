<template>
  <div class="news-detail">
    <Header />
    <Banner />
    <NewsdetailContent />
    <div style="position:fixed;left: 10px; top: 545px;z-index: 99;" class="code-left-fixed">
      <img src="../assets/微信公众号.png" alt="" style="width:100px;box-shadow: 0 3px 6px 0 rgba(0,0,0,.1);">
    </div>
    <Footer />
  </div>
</template>
<script>
import Banner from '@/components/Banner.vue'
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import NewsdetailContent from '@/components/NewsdetailContent.vue'

export default {
  name: 'Newsdetail',
  components: {
    Header,
    Banner,
    Footer,
    NewsdetailContent
  }
}
</script>