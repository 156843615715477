<template>
<div class="s-solution">
  
  <!-- <TitleBarLeft :msg="msg"/> -->
  <!-- <el-tabs v-model="activeName" tab-position="left" type="card" @tab-click="handleClick">
    <el-tab-pane :label="title1" name="first">
      <SIntroduction :title="title1"/>
    </el-tab-pane>
    <el-tab-pane :label="title2" name="second">
      <SCharacter :leftTitle="title2"/>
    </el-tab-pane>
    <el-tab-pane :label="title3" name="third">
      <SFunDetails :leftTitle="title3"/>
    </el-tab-pane>
    <el-tab-pane :label="title4" name="fourth">
      <SScheme :leftTitle="title4"/>
    </el-tab-pane>
    <el-tab-pane :label="title5" name="fifth">
      <SCase :leftTitle="title5"/>
    </el-tab-pane>
  </el-tabs> -->
   <div class="solution">
      <div ref="boxFixed" class="product">
        <div :class=" isFixed ? 'tab-fixed tab-right':'tab-right'" >
          <div class="row-container" style="padding-left:5%;padding-right:5%;">
            <div class="title">
              {{ $store.state.solutionName }}
            </div>
            <el-menu
              mode="horizontal" :default-active="activeIndex2"
          @select="handleSelect" class="p-menu" id="tab0">
              <el-menu-item index="1" class="p-menu-item tab0-title">
              {{title1}}
              </el-menu-item>
              <el-menu-item index="2" class="p-menu-item tab0-title">
                {{title2}}
              </el-menu-item>
              <el-menu-item index="3" class="p-menu-item tab0-title">
              {{title3}}
              </el-menu-item>
              <el-menu-item index="4" class="p-menu-item tab0-title">
              {{title4}}
              </el-menu-item>
                <el-menu-item index="5" class="p-menu-item tab0-title">
              {{title5}}
              </el-menu-item>
            </el-menu>
          </div>
        </div>
        <div class="row-container" style="padding-left:5%;padding-right:5%;">
        <div class="tab-content">
          <SIntroduction :title="title1" id="content1"/>
          <SCharacter :leftTitle="title2" id="content2"/>
          <SFunDetails :leftTitle="title3" id="content3"/>
          <SScheme :leftTitle="title4" id="content4"/>
          <SCase :leftTitle="title5" id="content5"/>
        </div>
        </div>
      </div>
  </div>
 </div>
</template>
<script>
import TitleBarLeft from '../components/TitleBarLeft.vue'
import SIntroduction from '../components/SIntroduction.vue'
import SCharacter from '../components/SCharacter.vue'
import SFunDetails from '../components/SFunDetails.vue'
import SScheme from '../components/SScheme.vue'
import SCase from '../components/SCase.vue'

import img1 from '../assets/1.png'
import { get, imageFile } from '../plugins/request.js'

export default {
  name: 'Solution',
  components: {
    TitleBarLeft,
    SIntroduction,
    SCharacter,
    SFunDetails,
    SScheme,
    SCase
  },
  data() {
    return {
      title: '晨科客户管理系统',
      activeName: 'first',
      img: img1,
      items: [],
      isFixed: false,
      activeIndex2: '1',
      scroolI: 0,
      fixedBoxHeight:''
    }
  },
  mounted() {
    const offsetTop = this.$refs.boxFixed.offsetTop;
    const position = this.getCss(document.getElementsByClassName('my-header')[0], 'position')
    if(position=='fixed'){
      this.fixedBoxHeight = document.getElementsByClassName('banner')[0].offsetHeight+153
    }else{
      this.fixedBoxHeight = document.getElementsByClassName('banner')[0].offsetHeight+document.getElementsByClassName('my-header')[0].offsetHeight
    }
    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.watchScroll);
  },
  methods: {
    getCss(element, attr){
      if(element.currentStyle){
          return element.currentStyle[attr];
      }else{
          return window.getComputedStyle(element,null)[attr];
      }
    },
    watchScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const offsetTop = this.fixedBoxHeight;
      //判断window滚动条上下滚动
      const scroll = scrollTop - this.scroolI
      this.scroolI = scrollTop;
      //console.log(offsetTop)
      this.isFixed = scrollTop > offsetTop ? true : false;
      const ele = document.getElementsByClassName('tab0-title')
      const tab0 = document.querySelector("#tab0")

      for(let i=0; i<ele.length; i++) {
        //console.log(`#content${i+1}`)
        const offsetTop3 =  document.querySelector(`#content${i+1}`).offsetTop;
        //console.log(scrollTop,offsetTop3, offsetTop3-scrollTop)
        if((offsetTop3-scrollTop)< 300 && (offsetTop3-scrollTop)>0) {
          this.activeIndex2 = (i+1).toString()
          if(scroll<0){
            //console.log('U')
            if(i>1){
              tab0.scrollTo(ele[i-1].offsetLeft, 0)
            }
          }else{
            //console.log('D')
            tab0.scrollTo(ele[i].offsetLeft, 0)
          }
          return;
        }
      }
    },
    goScroll() {
      const offsetTopPoint = document.querySelector(`#content${this.activeIndex2}`).offsetTop;
      window.scrollTo(0, offsetTopPoint-300);
    },
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    handleSelect(key, keyPath) {
      //console.log(key);
      this.activeIndex2 = key
      this.goScroll()
    },
    getData() {
      const id = this.$store.state.currentLanguage.id
      const params = this.$route.params.id;
      const self = this;
      get('/custom-made.jsp', {langtype: id, sId: params}).then(function(res){
        const data0 = res.data.data;
        self.title = data0[0]['title-1']
      })
    }
  },
  created: function(){
    this.getData()
    this.activeName = 'first'
  },
  computed:{
    title1: function() {
      return this.$store.state.currentLanguage.id==0?'系统简介':'System Introduction'
    },
    title2: function() {
      return this.$store.state.currentLanguage.id==0?'系统特点':'System Characteristics'
    },
    title3: function() {
      return this.$store.state.currentLanguage.id==0?'功能详情':'Functional Details'
    },
    title4: function() {
      return this.$store.state.currentLanguage.id==0?'详细方案':'Detailed Scheme'
    },
    title5: function() {
      return this.$store.state.currentLanguage.id==0?'客户案例':'Customer Cases'
    }
  }
}
</script>
<style lang="scss">
.s-solution {
  //padding: 40px 0;
  .el-tabs__header{
    border: none !important;
    margin-right: 2.5rem !important;
  }
  .title-p {
      color: #1F87E8;
      background: #ecf5ff;
      padding: 0.5rem 0;
      //margin-top: 40px;
      &::before{
        content: '';
        display: inline-block;
        // width: 4px;
        // height: 18px;
        margin-right: 0.5rem;
        background-color: #1F87E8;
      }
    }
  // .el-tabs__nav {
  //   border: none !important;
    
  //   .el-tabs__item{
  //     border: none !important;
  //   }
  // }
  .solution{
      margin: 0 auto;
   }
  .el-tabs__header{
    border: none !important;
    margin-right: 5rem !important;
  }
  .tab-right {
    background: #fff;
    z-index: 90;
    width: 100%;
    .title{
      font-size: 1.4rem;
      margin-bottom:1rem;
      margin-top: 1rem;
    }
    
    &.tab-fixed{
      position: fixed;
      top: 0
    }
    .el-menu--horizontal {
    font-family: Arial,"Noto Sans",sans-serif,"Microsoft YaHei","Helvetica Neue","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
      .el-menu-item{
        font-size: 0.75rem !important;
          -webkit-tap-highlight-color:rgba(0,0,0,0);
      }
    }
  }
  .tab-content{
    padding-top: 1rem;
    
  }
}
</style>