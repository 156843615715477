<template>
<div class="pf-tools row-container">
  
  <TitleBarOnlyText :msg="msg"/>
  
   <ul class="items">
    <li v-for="item in items" :key="item.index">
      <div class="title">
        <img :src="item.image" alt=""><span>{{ item.title }}</span></div>
      <p>{{ item.description }}</p>
    </li>
  </ul>
</div>
</template>
<script>
import TitleBarOnlyText from '../components/TitleBarOnlyText.vue'
import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

import icon1 from '../assets/tools1.png'
import icon2 from '../assets/tools2.png'
import icon3 from '../assets/tools3.png'
import icon4 from '../assets/tools4.png'

export default {
  name: 'PFTools',
  components: {
    TitleBarOnlyText
  },
  data() {
    return {
      msg: {
        title: '',
        subtitle: ''
      },
      items: [
        { image: icon1, title: '', description: ''},
        { image: icon2, title: '', description: ''},
        { image: icon3, title: '', description: ''},
        { image: icon4, title: '', description: ''},
      ],
     
    }
  },
  methods: {
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.msg.title = title['p4Title1']
        self.msg.subtitle = title['p4Title2']
        for(var i=0;i<4; i++){
          self.items[i].title = title[`p4Title${i+3}`]
          self.items[i].description = title[`p4D${i+3}`]
        }
      })
    }
  },
  created: function(){
    const id = this.$store.state.currentLanguage.id
    this.getValue({lang: id, value: 'p4Title1;p4Title2;p4Title3;p4D3;p4Title4;p4D4;p4Title5;p4D5;p4Title6;p4D6;'})
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      this.getValue({lang: id, value: 'p4Title1;p4Title2;p4Title3;p4D3;p4Title4;p4D4;p4Title5;p4D5;p4Title6;p4D6;'})
    }
  }
}
</script>
<style lang="scss">
.pf-tools {
  padding: 2rem 0;
  .items {
    display: flex;
    justify-content: space-between;
    padding: 0 0 2rem 0;
    li {
      background: #fff;
      width: 13.5rem;
      border-radius: 0.3rem;
      padding: 1rem 0.65rem;
      box-sizing: border-box;
      .title{
        width: 100%;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #dedede;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
          display: inline-block;
          text-transform: uppercase;
          font-size: 0.9rem;
        }
        img {
          margin-right: 0.5rem;
          width: 1.6rem;
        }
       
      }
       p {
         padding-top: 1rem;
         opacity: 0.6;
         font-size: 0.7rem;
         line-height: 1.6;
         padding: 0.75rem 0.5rem 0 0.5rem;
        }
    }
  }
}
</style>