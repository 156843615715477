import axios from "axios"
import img1 from '../assets/未获取图片.svg'
//创建一个axios对象
const instance = axios.create({
  baseURL: 'https://hz.chenksoft.com/ckapi/api/2/v2/',
  timeout: 50*1000
})

const params0 = {
  token: 'chenksoft!@!',
  langtype: '0'
}

//请求拦截
//请求数据之前
instance.interceptors.request.use(
  function(config) {
    //console.group("全局请求拦截")
    //console.log(config)
    //console.groupEnd()
    //config.header.token = '12355'
    return config
  },
  function(err) {
    return Promise.reject(err);
  }
)
//响应拦截
//服务器返回数据之后
instance.interceptors.response.use(
  function(response) {
    //console.group("全局响应拦截")
    //console.log(response)
    //console.groupEnd()
    if(response.data.code == 5) {
      return Promise.reject('token错误');
    }else if(response.data.code == 8) {
      return Promise.reject('数据库操作异常');
    }
    return response
  },
  function(err) {
    return Promise.reject(err);
  }
)

export function get(url, params) {
  const Data = Object.assign({}, params0, params);
  return instance.get(url, {
    params: Data
  });
}

export function post(url, data) {
  const Data = Object.assign({}, params0, data);
  return instance.post(url, Data)
}

export function imageFile (file) {
  if(!file){
    return '';
    //return img1;
  }else {
    file = file.replace(';', '')
    return  'https://hz.chenksoft.com/SK_CFW_Servlet.do?method=downfile&domainid=2&fid='+file+'&filename='+file;
  }
 
}