<template>
  <div class="header-zh">
    <div class="nav-desktop">
      <el-menu :default-active="$route.fullPath" class="el-menu-nav" mode="horizontal" @select="handleSelect" text-color="#fff" active-text-color="#fff">
         <img alt="logo" :src="logo2" style="float:left;margin-top: 0.4rem; margin-right:1.8rem;width:7rem;">
        <el-menu-item index="/"><router-link to="/" class="item-a">首页</router-link></el-menu-item>
        <el-menu-item index="/platform"><router-link to="/platform" class="item-a">技术平台</router-link></el-menu-item>
        <el-submenu index="/products" :popper-append-to-body="false">
          <template slot="title">软件产品</template>
          <div style="width:30rem; padding: 18px;background-color:white;" class="ppppp">
            <div style="font-size:20px;color:#2a2a2a;padding:0px 0.5rem 1rem 1rem;background-color:#fff;border-bottom:1px solid #eeeeee;">
              <img :src="imgprojects" alt="" style="vertical-align: text-bottom;margin-right: 10px;">软件产品
            </div>
            <div style="width:80%;padding:20px;">
              <el-menu-item :index="`/products/${item.sId}`" class="header-el-menu-item" v-for="item in products" :key="item.id">
                <router-link :to="`/products/${item.sId}`"> {{item.sname}} </router-link>
              </el-menu-item>
            </div>
          </div>
        </el-submenu>
        <el-submenu index="/solutions" :popper-append-to-body="false" >
          <template slot="title"><span>解决方案</span> </template>
          <div style="width:30rem; padding: 18px;background-color:white;" class="ppppp">
            <div style="font-size:20px;color:#2a2a2a;padding:0px 0.5rem 1rem 1rem;background-color:#fff;border-bottom:1px solid #eeeeee;">
              <img :src="imgsolution" alt="" style="vertical-align: text-bottom;margin-right: 10px;">行业解决方案 
            </div>
            <div style="width:80%;padding:20px;">
              <el-menu-item :index="`/solutions/${item.id}`" class="header-el-menu-item" v-for="item in solutions" :key="item.id">
                <a href="http://iot.chenksoft.com/" v-if="item.id==25" target="_blank">{{item.name}}</a>
                <router-link :to="`/solutions/${item.id}`" v-else> {{item.name}} </router-link>
              </el-menu-item>
            </div>
          </div>
            
        </el-submenu>
        <el-menu-item index="/customize"><router-link to="/customize" class="item-a">软件定制</router-link></el-menu-item>
        <el-menu-item index="/about"><router-link to="/about" class="item-a">关于我们</router-link></el-menu-item>
        <el-menu-item index="/contact" @click="postMsgToBaidu()"><router-link to="/contact" class="item-a">联系我们</router-link></el-menu-item>
        <el-menu-item index="/cases"><router-link to="/cases" class="item-a">客户案例</router-link></el-menu-item>
      </el-menu>
    </div>
    <div class="nav-mobile clearfix">
      <div class="nav-mobile-vertical" v-show="dropShow">
        <div class="menubox">
          <div class="menuClose" @click="hideDrop()"> × </div>
          <ul class="first-layer">
          
            <li :class="activeItem=='/'?'active':''" @click="changeActiveItem()"><router-link to="/">首页</router-link></li>
            <li :class="activeItem=='/platform'?'active':''" @click="changeActiveItem()"><router-link to="/platform" >技术平台</router-link></li>
            <li>
              <div class="layer-head" @click="showSecondLayer1()">
                <span>软件产品</span>
                <i :class="productDropShow?'el-icon-arrow-down el-icon':'el-icon-arrow-right el-icon'"></i>
              </div>
              <ul class="second-layer" v-show="productDropShow">
                <li v-for="item in products" :class="activeItem==`/products/${item.sId}`?'active':''"
                @click="changeActiveItem()" :key="item.id"><router-link :to="`/products/${item.sId}`"> {{item.sname}} </router-link></li>
              </ul>
            </li>
            <li>
              <div class="layer-head" @click="showSecondLayer2()">
                <span>解决方案</span>
                <i :class="solutionDropShow?'el-icon-arrow-down el-icon':'el-icon-arrow-right el-icon'"></i>
              </div>
              <ul class="second-layer" v-show="solutionDropShow">
                <li v-for="item in solutions" :class="activeItem==`/solutions/${item.id}`?'active':''"
                @click="changeActiveItem()" :key="item.id"><router-link :to="`/solutions/${item.id}`"> {{item.name}} </router-link></li>
              </ul>
            </li>
            <li :class="activeItem=='/customize'?'active':''" @click="changeActiveItem()"><router-link to="/customize">软件定制</router-link></li>
            <li :class="activeItem=='/about'?'active':''" @click="changeActiveItem()"><router-link to="/about">关于我们</router-link></li>
            <li :class="activeItem=='/contact'?'active':''" @click="changeActiveItem()"><router-link to="/contact">联系我们</router-link></li>
            <li :class="activeItem=='/cases'?'active':''" @click="changeActiveItem()"><router-link to="/cases">客户案例</router-link></li>
          </ul>
        </div>
        <div class='maskin' @click="hideDrop()"></div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { get, imageFile } from '../plugins/request.js'
import { mapMutations } from 'vuex'
import axios from "axios"

import logo from '../assets/logo-w.png'
import logo2 from '../assets/logo.png'
import solutions from '../assets/solutions.png'
import projects from '../assets/projects.png'

export default {
  name: 'HeaderZh',
   props: {
     msgVal: Boolean,
     msgFixed:Boolean,
  },
  data() {
    return {
      imgsolution: solutions,
      imgprojects:projects,
      language: [
        { index: '0', name: '中文', path: '' },
        { index: '1', name: 'English', path: '' },
      ],
      languageDefault: { index:0, name: '中文'},
      products: [],
      solutions: [],
      activeItem: '',
      dropShow: false,
      productDropShow: false,
      solutionDropShow: false,
      headerFix:false,
      screenWidth: document.body.clientWidth
      //activeIndex: '/en/products/5'.replace(/^\/(en|cn)\//g, '\/'),
    };
  },
  methods: {
     changeActiveItem(key){
      this.dropShow = false
      this.$emit('dropdrop4', false)
      this.activeItem = this.$route.path
    },
    postMsgToBaidu(){
      var logidUrl = window.location.href

      //this.$router.push({path:'/contact'})
      axios({
        method:'post',
        url:'https://hz.chenksoft.com/mixkit/jimuyu/eventupload',
        data: 'eventType=1&logidUrl='+logidUrl,
        headers: {
            contentType:'application/json'
        },
      }).then(function(res){
        console.log(res)
      })
      
    },
     hideDrop(){
      this.dropShow = false
      document.body.style.overflow='auto'
      this.$emit('dropdrop4', false)
    },
    showSecondLayer1(){
      this.productDropShow = !this.productDropShow
    },
    showSecondLayer2(){
      this.solutionDropShow = !this.solutionDropShow
    },
    handleSelect(key, keyPath) {
      //console.log(key, keyPath);
    },
    selectLanguage(key) {
      //console.log(key)
      const path = this.$route.path
      this.$store.commit('changeLanguage', {id: key, path: path})
    },
    getMenuPro(params) {
      const self = this;
      get('/product_module.jsp', params).then(function(res){
        console.log(res.data.data)
        const data0 = res.data.data;
        self.products = data0
      })
    },
    getMenuSolutions(params) {
      const self = this;
      get('/solution_module.jsp', params).then(function(res){
        //console.log(res.data.data)
        const data0 = res.data.data;
        self.solutions = data0
      })
    }
  },
  computed: {
    logo2: function(){
      if(this.screenWidth>=767){
        if(this.headerFix){
          return logo2
        }else {
          return logo
        }
      }else{
        return logo2
      }
    },
    activeIndex: function () {
      const path = this.$route.path
      //console.log(path)
      return path.replace(/^\/(en|cn)\//g, '\/')
    }
  },
  created: function() {
    this.activeItem = this.$route.path
    this.getMenuPro({langtype:0})
    this.getMenuSolutions({langtype:0})   
  },
  mounted(){
     const that = this
     window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
        if(this.screenWidth <= 767){
          this.isFixed = true
        }
      })()
    }
  },
  watch: {
    msgVal: function(){
      this.dropShow = this.msgVal
    },
    msgFixed: function(){
      this.headerFix = this.msgFixed
    }
  }
}
</script>

<style lang="scss">
.header-zh{
   .row-container {
    margin: 1.5rem auto;
    .hotline {
      float: right;
      font-size: 1rem;
      color: #1F87E8;
      font-weight: bold;
      margin-top:0.75rem;
      display: flex;
      .el-icon {
        font-size: 1.5rem;
        vertical-align: middle;
        margin-right: 0.5rem;
      }
      .language {
        font-weight: normal;
        margin-left: 2rem;
        line-height: 1.7;
      }
    }
    
  }
  
  .el-menu-item{
    padding:0 0.5rem;
  }
  
}
.el-menu--popup{
  box-shadow: none !important;
}
.el-menu--popup-bottom-start{
  margin-top: 0 !important;
}
.el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title{
    display: inline-block;
    width: 50%;
    height: 40px;
    line-height: 40px;
  }
  .el-menu--horizontal>.el-menu-item a{
    display: inline-block;
    cursor: pointer;
  }
.el-menu--popup .el-menu-item a{
      color: #313131 !important;
      display: inline-block;
      width: 100%;
      cursor: pointer;
    }
    .ppppp .el-menu-item:not(.is-disabled):focus, .ppppp .el-menu-item:not(.is-disabled):hover a{
      color: #1f87e8 !important;
    }
    .white-bg .el-menu--horizontal>.el-menu-item{
      color: #2a2a2a !important;
      transition: color 1s;
    }
@media screen and (min-width: 320px) {
  
}

@media only screen and (min-width: 576px) {
  
}

@media only screen and (min-width: 768px) {
 
}

@media only screen and (min-width: 992px) {
  .nav-mobile{
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .nav-mobile{
    display: none;
  }
}


</style>