<template>
  <div class="pitemrow1 row-container">
    <TitleBar :msg="msg"/>
    <el-steps :active="items.length" align-center class="flow-desktop" >
      <el-step :title="item.text" v-for="item in items" :key="item.index"></el-step>
    </el-steps>
    <div class="flow-mobile">
      <div class="flow-mobile-content">
        <ul class="flow-left">
          <li v-for="item in items" :key="item.index" v-if="item.index<=4"> 
            <div class="box2" >
              <div class="step-head">
                <span class="index">{{item.index}}</span>
              </div>
              <p>{{item.text}}</p>
              <div class="line"></div>
            </div>
          </li>
        </ul>
        <ul class="flow-right">
          <li v-for="item in items" :key="item.index" v-if="item.index>4"> 
            <div class="box1" >
              <div class="step-head">
                <span class="index">{{item.index}}</span>
              </div>
              <p>{{item.text}}</p>
              <div class="line"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="text2" >
      <p>{{description1}}</p>
      <p>{{description2}}</p>
    </div>
  </div>
</template>

<script>
import TitleBar from './TitleBar.vue'

import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

export default {
  name: 'PItemRow1',
  data() {
    return {
      msg: {
        title: 'Service Process',
        subTitle: 'Customized on demand, fast response, stable and reliable, free expansion'
      },
      description1: '',
      description2: '',
      // items2: [
      //   { icon: 'el-icon-edit', text: '需求沟通', show: true},
      //   { icon: 'el-icon-edit', text: '方案确认', show: true},
      //   { icon: 'el-icon-document-checked', text: '签约立项', show: true},
      //   { icon: 'el-icon-edit', text: '项目调研', show: true},
      //   { icon: 'el-icon-edit', text: '软件开发', show: true},
      //   { icon: 'el-icon-edit', text: '测试试用', show: true},
      //   { icon: 'el-icon-edit', text: '正式上线', show: true},
      //   { icon: 'el-icon-edit', text: '系统维护', show: false}
      // ],
      items: [],
    }
  },
  components: {
    TitleBar
  },
  methods: {
    getTitle(params) {
      const self = this;
      get('/title-name.jsp', params).then(function(res){
        const data0 = res.data.data;
        self.msg.title = data0[0].name;
        self.msg.subTitle = data0[0].content;
      })
    },
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        //console.log(title)
        self.msg.title = title['hTitle8']
        self.msg.subTitle = title['hTitle9']
        self.description1 = title['processD1']
        self.description2 = title['processD2']
        
        for(var i=0;i<8; i++){
          self.items[i]= {index:i+1, text: title[`process${i+1}`]}
        }
      })
    }
  },
  created: function(){
    const id = this.$store.state.currentLanguage.id
     this.getValue({lang: id, value: 'hTitle8;hTitle9;process1;process2;process3;process4;process5;process6;process7;process8;processD1;processD2;'})
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
       this.getValue({lang: id, value: 'hTitle8;hTitle9;process1;process2;process3;process4;process5;process6;process7;process8;processD1;processD2;'})
    }
  }
}
</script>

<style lang="scss">
.flow-mobile{
  display: none;
}
.flow-mobile-content{
  display: flex;
  justify-content: space-around;
  
  ul {
    padding: 0 0.5rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 46%;
    li{
      margin-bottom: 0.5rem;
      position: relative;
      .box2{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding: 0.5rem 0;
        height: 2.4rem;
        p{
          font-size: 0.75rem;
          text-align: right;
          padding-right: 0.5rem;
        }
        
      }
      .box1{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.5rem 0;
        height: 2.4rem;
        p{
          font-size: 0.75rem;
          text-align: left;
          padding-left: 0.5rem;
        }
      }
      .step-head{
        position: relative;
        .index{
          width: 1.2rem;
          height: 1.2rem;
          display: inline-block;
          text-align: center;
          line-height: 1.5;
          font-size:0.7rem;
          border-radius: 50%;
          font-weight: bold;
          border: 2px solid;
          color: #1F87E8;
          border-color: #1F87E8;
        }
        .line{
          width: 100%;
          display: block;
          border-width: 1px;
          border-style: solid;
          border-color: inherit;
          transition: .15s ease-out;
          box-sizing: border-box;
          width: 10px;
          bottom: 1.7rem;
        }
      }
    }
    
    li+li{
      .box1{
        .line{
          width: 2px;
          height: 1.35rem;
          background: #1F87E8;
          display: inline-block;
          position: absolute;
          left: 0.55rem;
          bottom: 1.95rem;
        }
      }
      .box2{
        .line{
          width: 2px;
          height: 1.35rem;
          background: #1F87E8;
          display: inline-block;
          position: absolute;
          right: 0.55rem;
          bottom: 1.95rem;
        }
      }
    }
  }
}

.pitemrow1 {
  text-align: center;
  padding-bottom: 2rem;
  .el-step__title{
    margin-top: 0.5rem;
    line-height: 1.4;
  }
  .el-step__title.is-finish{
    color: #1F87E8 !important;
  }
  .el-step__head.is-finish{
    color: #1F87E8 !important;
  }
  .text2 {
    font-size: 0.8rem;
    line-height: 2;
    margin-top: 3rem;
  }
  
  // .items {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   font-size: 16px;
  //   li {
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //     .content{
  //       min-width: 80px;
  //       .icon {
  //         font-size: 20px;
  //         color: #1F87E8;
  //       }
  //     }
      
  //     .el-icon-arrow-right {
  //       width: 20%;
  //       font-size: 28px;
  //       opacity: 0.3;
  //       margin-top: 20px;
  //     }
  //   }
  // }
  .el-steps {
    .is-horizontal.el-step{
      width: 12.5;
    }
  }
  
  
}
</style>