<template>
  <div class="titlebar-only-text row-container">
    <h1>{{ msg.title }}</h1>
    <p v-html="msg.subtitle"></p>
  </div>
</template>

<script>
export default {
  name: 'titlebar-only-text',
  props: {
    msg: Object
  }
}
</script>

<style lang="scss">
.titlebar-only-text {
  width: 100%;
  text-align: center;
  padding: 2rem 0 3rem 0;
  h1 {
    font-weight: normal;
    font-size: 1.5rem;
    margin: 0.5rem auto;
    //text-transform: capitalize;
  }
  p {
    font-size: 0.8rem;
    opacity: 0.6;
    width: 90%;
    margin: 0.75rem auto;
    line-height: 1.6;
  }
}
</style>