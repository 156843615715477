<template>
  <div class="news-list row-container">
    <WXNews />
    <h1 class="title">文章列表</h1>
    <div class="news-box">
      <ul class="news-ul">
        <li :key="index" v-for="(item, index) in items" :class="(index+1)%5==0?'line':''">
          <h4> <router-link :to="`/newsdetail/${item.id}`">{{item.title}}</router-link> </h4>
          <span class="date">{{item.date.split(' ')[0]}}</span>
        </li>
      </ul>
      <el-pagination class="pagination-desktop" @current-change="getCurrentPage"
  background
  layout="prev, pager, next"
  :page-size="10"
  :total="total">
</el-pagination>
  <el-pagination class="pagination-mobile" @current-change="getCurrentPage"
    small
    background
    layout="prev, pager, next"
    :page-size="10"
    :total="total">
  </el-pagination>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import WXNews from './WXNews.vue'

export default {
  name: 'Newslist',
  components: {
    WXNews
  },
  data(){
    return {
      total: 1,
      currentPage: 1,
      items: []
    }
  },
  methods: {
    getTotal(){
      const self = this;
      axios.get('https://hz.chenksoft.com:443/ckapi/api/1/v2/page_count.jsp', {
        params:{
          token: "chenksoft!@!",
        }
      }).then(function(res){
        const data0 = res.data.data;
        self.total = data0[0].number;
      })
    },
    goToPage(page){
      this.$router.push(`/news/${page}`)
    },
    getData(){
      const currentPage = (parseInt(this.currentPage)-1)*10;
      const self = this;
      axios.get('https://hz.chenksoft.com:443/ckapi/api/1/v2/index_article-New.jsp', {
        params:{
          'page_list': currentPage,
          token: "chenksoft!@!",
        }
      }).then(function(res){
        const data0 = res.data.data;
        //console.log(data0)
        self.items = data0.slice(0,10)
      })
    },
    getCurrentPage(page){
      this.currentPage = page
      //this.$router.push(`/news/${page}`)
      this.getData()
      window.scrollTo(0, 0);
    }
  },
  created(){
    this.getData()
    this.getTotal()
  }
}
</script>
<style lang="scss">
.news-list{
  padding-bottom: 2rem;
  .title{
    width: 85%;
    font-size: 1.2rem;
    font-weight: normal;
    margin: 1.5rem auto;
  }
  .news-box{
    width: 85%;
    padding: 1.5rem;
    margin: 0 auto;
    .el-pagination{
      text-align: center;
    }
    .pagination-mobile{
      display: none;
    }
    .news-ul li{
      padding-left: 1rem;
      line-height: 1.8rem;
      position: relative;
      &.line{
        border-bottom: 1px solid #dedede;
        margin-bottom: 0.75rem;
        padding-bottom: 0.75rem;
      }
      &::before{
        position: absolute;
        content: '';
        width: 3px;
        height: 3px;
        display: inline-block;
        background: #444;
        top: 0.8rem;
        left: 4px;
      }
      h4{
        width: 85%;
        font-size: 0.75rem;
        font-weight: normal;
        display: inline-block;
        a:hover{
          color: #235EB6;
          text-decoration: underline;
        }
      }
      .date{
        display: inline-block;
        width: 15%;
        font-size: 0.65rem;
        opacity: 0.6;
      }
    }
  }
  .el-pagination.is-background.el-pagination--small .el-pager li,
  .el-pagination.is-background.el-pagination--small .btn-next,
  .el-pagination.is-background.el-pagination--small .btn-prev{
    margin: 0px 4px;
    min-width: 24px;
  }
  .el-pagination--small .el-pager li,.el-pagination--small .btn-prev{
    font-size: 14px;
    line-height: 24px;
    height: 24px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #235EB6;
    color: #FFF;
  }
  .el-pagination.is-background .el-pager li:not(.active):hover{
    color: #235EB6;
  }
  
}

</style>