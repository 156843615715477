<template>
<div class="c-cases-zn row-container">
  <!-- <ul class="items">
    <li v-for="item in items"><img :src="item.logo" alt=""></li>
  </ul> -->
  <div class="items">
    <el-card shadow="hover" v-for="(item, index) in items" :key="item.index" class="item-box">
      <li ><img :src="item.logo" alt=""></li>
      </el-card>
  </div>
  
  <el-tabs :tab-position="tabPosition"  class="tabs cases-desktop" >
    <el-tab-pane :label="item.name" v-for="item in companys" :key="item.index">
      <ul>
        <li v-for="item2 in item.company" :key="item2.index">
          <span v-for="item3 in item2">{{ item3 }}</span>
        </li>
      </ul>
    </el-tab-pane>
  </el-tabs>
  <ul class="cases-mobile" v-for="item in companysM">
    <li :key="item.index">
      <div class="title">{{item.name}}</div>
      <ul  class="layer2">
        <li v-for="item2 in item.company" :key="item2.index">{{item2}}</li>
      </ul>
      </li>
  </ul>
</div>
</template>
<script>
import company from '../plugins/company.js'

import logo1 from '../assets/case-阿斯.png';  
import logo2 from '../assets/case-月星.png';  import logo4 from '../assets/case-银江.png'; import logo5 from '../assets/case-浙江.png';
import logo6 from '../assets/case-浙江大学.png'; import logo7 from '../assets/case-知味观.png'; import logo8 from '../assets/case-bank.png';
import logo9 from '../assets/case1-宁波.png'; import logo10 from '../assets/case2-大搜车.png';
import logo11 from '../assets/case3-工业大学.png'; import logo12 from '../assets/case4-华大.png';
import logo13 from '../assets/case5-华东理工.png'; import logo14 from '../assets/case6-华东医药.png';
import logo15 from '../assets/case7-创维.png'; import logo16 from '../assets/case8-京.png';
import logo18 from '../assets/case10-中石化.png';import logo17 from '../assets/case9-二更.png';
import logo19 from '../assets/case12-中国铁建.png'; import logo20 from '../assets/case13-中国石油.png';
import logo21 from '../assets/case15-中国地质大学.png'; import logo22 from '../assets/case16-中国东方航空.png';
import logo23 from '../assets/case17-中储粮.png'; import logo24 from '../assets/case18-上海.png';
import logo25 from '../assets/case19-二所.png'; import logo26 from '../assets/c-en-10.png'; 

export default {
  name: 'CCaseZh',
  data() {
    return {
      tabPosition: 'left',
      companys: company.deal,
      companysM: company.original,
      items: [
        { logo: logo1 },{ logo: logo4 },{ logo: logo5 },{ logo: logo19 },{ logo: logo20 },
        { logo: logo22 },{ logo: logo21 },{ logo: logo23 },{ logo: logo24 },{ logo: logo25 },
        { logo: logo6 },{ logo: logo7 },{ logo: logo8 },{ logo: logo9 },{ logo: logo10 },
        { logo: logo11 },{ logo: logo14 },{logo:logo17},{ logo: logo15 },{logo: logo26},
        { logo: logo16 },{ logo: logo18 },{ logo: logo2 },{ logo: logo12 },{ logo: logo13 }
      ]
    }
  },
  mounted(){
    if (document.documentElement.clientWidth<992){
      this.tabPosition = 'top'
    }
  }
}
</script>
<style lang="scss">
.c-cases-zn {
   padding: 2rem 0;
  .items {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    .item-box{
      margin-bottom: 1rem;
    }
  }
  .tabs {
    margin-top: 2rem;
    border: 1px solid #EBEEF5;
   .el-tabs__header {
     padding-top: 2rem;
     padding-bottom: 2rem;
   }
    .el-tabs__item {
      font-size: 0.8rem;
    }
    .el-tabs__content {

      ul {
        margin: 2rem;
        li{
          height: 2.5rem;
          line-height: 2.5rem;
          span {
            display: inline-block;
            text-align: center;
            width: 32%;
            padding-left: 0.5rem;
          }
          &:hover:hover {
            background: #f6f7fb;
          }
          &:nth-child(even) {
            background: #fafafa;
          }
        }
      }
    }
  }
}
.cases-mobile{
  width: 100%;
  li{
    .title{
      color: #1F87E8;
      background: #ecf5ff;
      padding: 0.5rem;
      font-size: 1.1rem;
      margin: 0.5rem 0 1rem;
      font-weight: 400;
    }
    .layer2{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li{
          width: 48%;
          padding: 0.5rem 0;
        }
      }
  }
}
</style>