<template>
  <div class="HCases row-container">
    <TitleBar :msg="msg"/>
    <HCaseNew />
  </div>
</template>

<script>
import TitleBar from './TitleBar.vue'
import HCaseNew from './HCaseNew.vue'

import Cases from '../assets/customer-logos.png'


import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

export default {
  name: 'HCases',
  data() {
    return {
      msg: {title:'荣誉客户', subTitle: '七千多成功客户案例，值得用户信赖'},
      cases: Cases,
      items: [
        
      ]
    }
  },
  components: {
    TitleBar,
    HCaseNew,
  },
  methods: {
   
  },
  created: function(){
   
  },
  watch: {
    
  },
}
</script>

<style lang="scss">
.HCases {
  text-align: center;
  padding-bottom: 2rem;
}
// @media only screen and (min-width: 1200px) {
//   .HCases {
//     max-width: 1140px;
//     .cases {
//       width: 917px;
//     }
//   }
// }


// @media only screen and (min-width: 992) {
//   .HCases {
//     max-width: 960px;
//     .cases {
//       width: 100%;
//     }
//   }
// }

// @media only screen and (min-width: 768px) {
//   .HCases {
//     max-width: 760px;
//     .cases {
//       width: 100%;
//     }
//   }
// }

// @media only screen and (min-width: 576px) {
//   .HCases {
//     max-width: 540px;
//     .cases {
//       width: 100%;
//     }
//   }
// }
</style>