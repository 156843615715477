<template>
  <div class="pitemrow22">
    <div class="content">
      <div class="text">
        <p class="title1">设备管理系统</p>
        <p class="title2">晨科资产设备管理系统是一款通用性极强的管理软件，适用于各类工厂、企事业、机关、学校、医院等单位，对设备的全生命周期进行科学管理</p>
        <ul>
          <li><span class="management-icon" style="background-position:0 0"></span><p>采购管理</p></li>
          <li><span class="management-icon" style="background-position:-2.4rem 0"></span><p>设备台账</p></li>
          <li><span class="management-icon" style="background-position:-4.8rem 0"></span><p>设备监控</p></li>
          <li><span class="management-icon" style="background-position:-7.2rem 0"></span><p>巡检保养</p></li>
          <li><span class="management-icon" style="background-position:-9.6rem 0"></span><p>一键报修</p></li>
          <li><span class="management-icon" style="background-position:-12rem 0"></span><p>维修管理</p></li>
          <li><span class="management-icon" style="background-position:0 -2.4rem"></span><p>工单管理</p></li>
          <li><span class="management-icon" style="background-position:-2.4rem -2.4rem"></span><p>备件耗材</p></li>
          <li><span class="management-icon" style="background-position:-4.8rem -2.4rem"></span><p>计量仪器</p></li>
          <li><span class="management-icon" style="background-position:-7.2rem -2.4rem"></span><p>特种设备</p></li>
          <li><span class="management-icon" style="background-position:-9.6rem -2.4rem"></span><p>审批流程</p></li>
          <li><span class="management-icon" style="background-position:-12rem -2.4rem"></span><p>统计分析</p></li>
        </ul>
        <el-button class="border-button" @click="openConsultBox">免费试用</el-button>
        <router-link :to="'/get-demo'" >
           <el-button type="primary" style="margin-left:0.5rem">了解更多</el-button>
        </router-link>
      </div>
    </div>
     <img :src="aimg" alt="" style="width:100%;height:37.5rem">
  </div>
</template>

<script>
import TitleBar from './TitleBar.vue'

import arrow from '../assets/arrow-right.png'
import a from '../assets/软件产品-pow3.png'
import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'
import axios from "axios"

export default {
  name: 'PItemRow2',
  data() {
    return {
      msg:{
        title: '软件产品',
        subTitle: '资产 | 设备 | 文件档案 | eGMP | 智慧实验室'
      },
      aimg:a,
      arrow: arrow,
      item3:''
    }
  },
  components: {
    TitleBar
  },
  methods: {
   openConsultBox(){
      window.$crisp.push(['do', 'chat:open']);
    },
  },
  created: function(){
    
      
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      
    }
  }
}
</script>

<style lang="scss">
.pitemrow22 {
  max-width: 1920px;
  margin: 2rem auto;
  height: 37.9rem;
  position: relative;
  .text{
    width: 1140px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
   
  }
  .content{
    width: 57rem;
    margin-top: 5rem;
    .title1{
      font-size: 1.6rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }
    .title2{
      font-size: 0.8rem;
      width: 30rem;
      margin: 0 auto;
       margin-bottom: 2rem;
    }
    ul{
      width: 100%;
      li{
        width: 8.5rem;
        height: 7rem;
        background-color:#19223d;
        border-radius: 2px;
        display: inline-block;
        text-align: center;
        margin: 1rem 0.5rem;
        font-size: 0.9rem;
        box-sizing: border-box;
        padding: 1.6rem;
        &:hover{
          background-color: #111b33;
        }
        p{
          margin-top: 0.6rem;
        }
        .management-icon{
          display: inline-block;
          width: 2.4rem;
          height: 2.4rem;
          background-image: url(../assets/projecticons3.png);
          background-position:0 0;
          border: 0;
          background-size: 600%;
          position: relative;
          &::after{
            width: 3.4rem;
            height: 3.4rem;
            position: absolute;
          vertical-align: middle;
            content: '';
            left: -12px;
            top: -12px;
            border-radius: 50%;
            border: 1px solid rgba(193,211,244, 0.2);
          }
         
        }
      }
    }
  }
  .imgright{
    float: right;
    margin-top: 2rem;
  }
  .border-button{
    background-color:transparent;
    color: #fff;
    width: 7rem;
    margin-top:2.5rem;
  }
}
</style>