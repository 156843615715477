<template>
  <div class="pitemrow4 row-container">
    <TitleBar :msg="msg"/>
    <ul class="items">
      <li v-for="item in items" :key="item.index"> 
        <img :src="item.icon" alt=""> 
        <div class="text">
          <h3 class="title">{{ item.name }}</h3>
          <p class="description">{{ item.content }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import TitleBar from './TitleBar.vue'

import bg1 from '../assets/CustomFields-1.png'
import bg2 from '../assets/CustomFields-2.png'
import bg3 from '../assets/CustomFields-3.png'
import bg4 from '../assets/CustomFields-4.png'

import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

export default {
  name: 'PItemRow4',
  data() {
    return {
      msg: {
        title: '',
        subTitle: ''
      },
      items: [
        { image: bg1, title: 'Management software customization', description: 'Professional customized management software, including customer management, project management, ERP, equipment management, inventory management, education and training, logistics systems, production management, sales management, personnel management, OA office, asset management, etc.' },
        { image: bg2, title: 'Website system development', description: 'Professional customization of various website systems, such as corporate official websites, e-government, industry portals, e-commerce, Internet finance, C2C, B2C, O2O, online education, online transactions, online payments, etc.' },
        { image: bg3, title: 'Mobile application development', description: 'Professional custom IOS, Android mobile Internet applications, iPhone, iPad, Android phones, Android tablets, APP native development, Html5 development, mobile portals, etc.' },
        { image: bg4, title: 'WeChat development', description: 'Professional custom WeChat public account, enterprise account, subscription account, WeChat website development, WeChat mall, WeChat push, WeChat payment, WeChat marketing, WeChat and internal system docking, etc.' },
      ]
    }
  },
  components: {
    TitleBar
  },
  methods: {
    getData(params) {
      const self = this;
      get('/custom-made.jsp', params).then(function(res){
        const data0 = res.data.data;
        self.items = data0.map(function(item){
          item.icon = imageFile(item.logo)
          return item
        })
      })
    },
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.msg.title = title['hTitle5']
        self.msg.subTitle = title['hTitle13']
      })
    }
  },
  created: function(){
    const id = this.$store.state.currentLanguage.id
    this.getData({langtype: id})
    this.getValue({lang: id, value: 'hTitle5;hTitle13;'})
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      this.getData({langtype: id})
      this.getValue({lang: id, value: 'hTitle5;hTitle13;'})
    }
  },
 
}
</script>

<style lang="scss">
.pitemrow4 {
  text-align: center;
  .items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
     -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    li {
      width: 14.25rem;
      text-align: left;
      margin-bottom: 2rem;
      border-radius: 0.5rem;
      padding: 0.75rem;
      box-sizing: border-box;
      text-align: center;
      img{
        height: 7.5rem;
      }
      .text{
        font-size: 0.75rem;
        .title {
          font-weight: normal;
          //text-transform: uppercase;
          margin: 0.5rem 0;
        }
        .description {
          opacity: 0.6;
          hyphens:auto; //英文换行加-
          text-align: justify;
          text-align: center;
        }
      }
    }
  }
  
}
</style>