<template>
<div class="about-us row-container">
  <TitleBar :msg="msg" />
 <ul class="images" v-if="$store.state.currentLanguage.id==0" >
   <li><img src="../assets/Group1.png" alt=""></li>
   <li><img src="../assets/Group2.png" alt=""></li>
   <li><img src="../assets/Group3.png" alt=""></li>
 </ul>
 <ul class="images" v-else >
   <li><img src="../assets/Group1-en.png" alt=""></li>
   <li><img src="../assets/Group2-en.png" alt=""></li>
   <li><img src="../assets/Group3-en.png" alt=""></li>
 </ul>
 <ul class="text">
   <li v-for="item in items" :key="item.index">
     <h3>{{item.title}}</h3>
     <p>{{item.des}}</p>
   </li>
   
 </ul>
 
</div>
</template>
<script>
import TitleBar from '../components/TitleBar.vue'

import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

export default {
  name: 'AboutUs',
  components: {
    TitleBar
  },
  data() {
    return {
      msg: {
        title: '',
        subTitle: ''
      },
      items: [
        { title: '', des: '' },
        { title: '', des: '' },
        { title: '', des: '' }
      ]
    }
  },
  methods: {
     getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const data = res.data.data[0].result.replace(/(R & D)|(R&D)/g, '*')
        const title = getQueryString(data)
        self.msg.title = title['aTitle1']
        
        self.msg.subTitle = title['aD1'].replace(/\*/g, 'R & D')
        self.items[0].title = title['aTitle2']
        self.items[0].des = title['aD2'].replace(/\*/g, 'R & D')
        self.items[1].title = title['aTitle3']
        self.items[1].des = title['aD3']
        self.items[2].title = title['aTitle4']
        self.items[2].des = title['aD4']
      })
    }
  },
  created: function(){
    const id = this.$store.state.currentLanguage.id
    this.getValue({lang: id, value: 'aTitle1;aD1;aTitle3;aD3;aTitle2;aD2;aTitle4;aD4;'})
  
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      this.getValue({lang: id, value: 'aTitle1;aD1;aTitle3;aD3;aTitle2;aD2;aTitle4;aD4;'})
    }
  }
}
</script>
<style lang="scss">
.about-us {
  padding: 2rem 0;
  font-size: 0.8rem;
  line-height: 2;
  .titlebar {
    .modified{
      opacity: 1;
      font-size: 0.9rem;
      text-transform: none;
      color: #666;
      margin-bottom: 3rem;
      &::after {
        background:#1F87E8;
        height: 0.3rem;
        width: 4rem;
        border-radius:0.15rem;
        top: 1.3rem;
      }
    }
    .subtitle {
      line-height: 2;
      font-size: 0.8rem;
    }
  }
  .images {
    width: 60%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }
  .text {
    margin-top: 2rem;
    li {
      padding: 1rem 0;
      h3 {
        font-size: 1.2rem;
        font-weight: normal;
        //text-transform: capitalize;
      }
      p{
        opacity: 0.7;
      }
    }
  }

}
</style>