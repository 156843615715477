<template>
 <div class="pf-efficiency row-container">
   <TitleBarOnlyText :msg="msg" />
   <ul class="items">
     <li v-for="item in items" :key="item.index" :class="$store.state.currentLanguage.id==0?'zhBox':''">
       <div class="imgbox"><img :src="item.image" alt=""></div>
       <div class="text">
         <h3 class="title">{{ item.title }}</h3>
         <p>{{ item.description }}</p>
       </div>
     </li>
   </ul>
 </div>
</template>
<script>
import TitleBarOnlyText from '../components/TitleBarOnlyText.vue'
import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

import img1 from '../assets/efficiency1.png'
import img2 from '../assets/efficiency2.png'
import img3 from '../assets/efficiency3.png'
import img4 from '../assets/efficiency4.png'

export default {
  name: 'PFEfficiency',
  components: {
    TitleBarOnlyText
  },
  data() {
    return {
      msg: {
        title: '',
        subtitle: ''
      },
      items: [
        { image: img1, title: '', description: '' },
        { image: img2, title: '', description: '' },
        { image: img3, title: '', description: '' },
        { image: img4, title: '', description: '' },
      ],
    }
  },
   methods: {
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.msg.title = title['p3Title1']
        self.msg.subtitle = title['p3Title2']
        for(var i=0;i<4; i++){
          self.items[i].title = title[`p3Title${i+3}`]
          self.items[i].description = title[`p3D${i+3}`]
        }
      })
    }
  },
  created: function(){
    const id = this.$store.state.currentLanguage.id
    this.getValue({lang: id, value: 'p3Title1;p3Title2;p3Title3;p3D3;p3Title4;p3D4;p3Title5;p3D5;p3Title6;p3D6;'})
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      this.getValue({lang: id, value: 'p3Title1;p3Title2;p3Title3;p3D3;p3Title4;p3D4;p3Title5;p3D5;p3Title6;p3D6;'})
    }
  }
}
</script>

<style lang="scss">
.pf-efficiency{
  padding: 1rem 0;
  .items {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    padding: 1rem 0;
    li {
      padding: 0.5rem 0;
      flex: 0 1 50%;
      text-align: left;
      margin-bottom: 2rem;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      img {
        margin-right: 1rem;
      }
      .text{
        width: 60%;
        font-size: 0.75rem;
        .title {
          font-weight: normal;
          //text-transform: uppercase;
          margin-bottom: 0.5rem;
        }
        p {
          opacity: 0.6;
          line-height: 1.6;
        }
      }
    }
  }
}
</style>