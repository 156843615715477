<template>
<div class="c-cases-en row-container">
  <ul class="items">
    <el-card shadow="hover" v-for="item in items" :key="item.index">
      <li ><img :src="item.logo" alt=""></li>
      </el-card>
  </ul>
</div>
</template>
<script>

import logo1 from '../assets/c-en-01.png';  
import logo2 from '../assets/c-en-02.png';  
import logo3 from '../assets/c-en-03.png';  
import logo4 from '../assets/c-en-04.png';  //none
import logo5 from '../assets/c-en-05.png';  
import logo6 from '../assets/c-en-06.png';  
import logo7 from '../assets/c-en-07.png';  
import logo8 from '../assets/c-en-08.png';  
import logo9 from '../assets/c-en-09.png';  
import logo10 from '../assets/c-en-10.png';  
import logo11 from '../assets/c-en-11.png';  
import logo12 from '../assets/c-en-12.png';  
import logo13 from '../assets/c-en-13.png';  
import logo14 from '../assets/c-en-14.png';  
import logo15 from '../assets/c-en-15.png';  
import logo16 from '../assets/c-en-16.png';  
import logo17 from '../assets/c-en-17.png';  
import logo18 from '../assets/c-en-18.png';  
import logo19 from '../assets/c-en-19.png';  
import logo20 from '../assets/c-en-20.png';  
import logo21 from '../assets/c-en-21.png';  //none
import logo22 from '../assets/c-en-22.png';  
import logo23 from '../assets/c-en-23.png';  
import logo24 from '../assets/c-en-24.png';  
import logo25 from '../assets/c-en-25.png';  
import logo26 from '../assets/c-en-26.png';  
import logo27 from '../assets/c-en-27.png';  
import logo28 from '../assets/c-en-28.png';  
import logo29 from '../assets/c-en-29.png';  
import logo30 from '../assets/c-en-30.png';  
import logo31 from '../assets/c-en-31.png';  
import logo32 from '../assets/c-en-32.png';  
import logo33 from '../assets/c-en-33.png';  

export default {
  name: 'CCaseEn',
  data() {
    return {
      tabPosition: 'left',
      items: [
        {logo: logo23},{logo: logo24},{logo: logo26},{logo: logo33},{logo: logo27},{logo: logo31},
        { logo: logo1 },{logo: logo2},{logo: logo25},{logo: logo30},{logo: logo3},{logo: logo5},{logo: logo6},{logo: logo7},{logo: logo28},{logo: logo29},
        { logo: logo8 },{logo: logo9},{logo: logo10},{logo: logo11},{logo: logo12},{logo: logo13},{logo: logo14},{logo: logo32},
        { logo: logo15 },{logo: logo16},{logo: logo17},{logo: logo18},{logo: logo19},{logo: logo20}
      ]
    }
  }
}
</script>
<style lang="scss">
.c-cases-en {
  padding-bottom: 2rem;
  .items {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .el-card{
      margin-bottom: 1rem;
    }
  }
  .tabs {
    margin-top: 2rem;
    border: 1px solid #EBEEF5;
   .el-tabs__header {
     padding-top: 2rem;
     padding-bottom: 2rem;
   }
    .el-tabs__item {
      font-size: 0.8rem;
    }
    .el-tabs__content {

      ul {
        margin: 2rem;
        li{
          height: 2.5rem;
          line-height: 2.5rem;
          img{
            width: 10rem;
          }
          span {
            display: inline-block;
            text-align: center;
            width: 32%;
            padding-left: 0.5rem;
          }
          &:hover:hover {
            background: #f6f7fb;
          }
          &:nth-child(even) {
            background: #fafafa;
          }
        }
      }
    }
  }
}
</style>