<template>
<div class="c-flow row-container">
   <TitleBarOnlyText :msg="msg"/>
  <ul class="items flow" >
    <li v-for="item in items" :key="item.index">
      <img :src="item.icon" alt="">
      <p>{{ item.title }}</p>
    </li>
  </ul>

  <div class="flow-mobile">
    <div class="flow-mobile-content">
      <ul class="flow-left">
        <li v-for="item in items" :key="item.index" v-if="item.index<=4"> 
          <div class="box2" >
            <div class="step-head">
              <span class="index">{{item.index}}</span>
            </div>
            <p>{{item.title}}</p>
            <div class="line"></div>
          </div>
        </li>
      </ul>
      <ul class="flow-right">
        <li v-for="item in items" :key="item.index" v-if="item.index>4"> 
          <div class="box1" >
            <div class="step-head">
              <span class="index">{{item.index}}</span>
            </div>
            <p>{{item.title}}</p>
            <div class="line"></div>
          </div>
        </li>
      </ul>
    </div>
   </div>
</div>
</template>
<script> 
import TitleBarOnlyText from './TitleBarOnlyText.vue'
import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

import icon1 from '../assets/c-flow-1.png'
import icon2 from '../assets/c-flow-2.png'
import icon3 from '../assets/c-flow-3.png'
import icon4 from '../assets/c-flow-4.png'
import icon5 from '../assets/c-flow-5.png'
import icon6 from '../assets/c-flow-6.png'
import icon7 from '../assets/c-flow-7.png'
import icon8 from '../assets/c-flow-8.png'

export default {
  name: 'cservice',
  data() {
    return{
      msg: {
        title: '',
        subtitle: ''
      },
      items: [
        { icon: icon1, title: '', index:1},
        { icon: icon2, title: '', index:2},
        { icon: icon3, title: '', index:3},
        { icon: icon4, title: '', index:4},
        { icon: icon5, title: '', index:5},
        { icon: icon6, title: '', index:6},
        { icon: icon7, title: '', index:7},
        { icon: icon8, title: '', index:8}
      ]
    }
  },
  components: {
    TitleBarOnlyText
  },
  methods: {
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.msg.title = title['scTitle3']
        self.msg.subtitle = title['scTitle4']
        
        for(var i=0;i<8; i++){
          self.items[i].title = title[`process${i+1}`]
        }
      })
    }
  },
   created: function(){
    const id = this.$store.state.currentLanguage.id
    this.getValue({lang: id, value: 'scTitle3;scTitle4;process1;process2;process3;process4;process5;process6;process7;process8;processD1;processD2;'})
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      this.getValue({lang: id, value: 'scTitle3;scTitle4;process1;process2;process3;process4;process5;process6;process7;process8;processD1;processD2;'})
    }
  }
}
</script>

<style lang="scss">
.c-flow {
  padding: 3rem 0;
  .flow{
    width: 100%;
    height: 10.3rem;
    background: transparent url(../assets/flow.svg) no-repeat;
    background-size: 100%;
  }
  .items {
    display: flex;
    justify-content: space-between;
    
    li {
      flex: 0 1 12.5%;
      text-align: center;
      position: relative;
      img{
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 0.75rem;
        text-transform: capitalize;
        font-weight: bold;
      }
      &:nth-child(even){
        img {
          bottom: 8%;
        }
      }
      &:nth-child(odd){
        img {
          top: 8%;
        }
      }
    }
  }
}
</style>