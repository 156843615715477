import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)


//我的入口
import Home from '../views/Home.vue'
import Customize from '../views/Customize.vue'
import Cases from '../views/Cases.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Solutions from '../views/Solutions.vue'
import News from '../views/News.vue'
import Newsdetail from '../views/Newsdetail.vue'
import Products from '../views/Products.vue'
import Platform from '../views/Platform.vue'
import GetDemo from '../views/GetDemo.vue'
import Page404 from '../views/Page404.vue'
import Privacy from '../views/Privacy.vue'


const routes = [
  { path: '/', name: 'Home', component: Home, alias: '/en'},
  { path: '/cases', name: 'Cases', component: Cases, alias: '/en/cases'},
  { path: '/products/:id', name: 'Products', component: Products, alias: '/en/products/:id'},
  { path: '/news', name: 'News', component: News},
  { path: '/newsdetail/:id', name: 'Newsdetail', component: Newsdetail},
  { path: '/contact', name: 'Contact', component: Contact, alias: '/en/contact' },
  { path: '/solutions/:id', name: 'Solutions', component: Solutions, alias: '/en/solutions/:id' },
  { path: '/about', name: 'About', component: About, alias: '/en/about' },
  { path: '/customize', name: 'Customize', component: Customize, alias: '/en/customize' },
  { path: '/platform', name: 'Platform', component: Platform, alias: '/en/platform' },
  { path: '/get-demo', name: 'GetDemo', component: GetDemo, alias: '/en/get-demo' },
  { path: '/404', name: 'page404', component: Page404 },
  { path: '/privacy', name: 'Privacy', component: Privacy },
  //{ path: '*',  redirect: '/404', name: 'notFound', hidden: true} // 页面不存在的情况下会跳到404页面
]
const scrollBehavior = function (to, from, savedPosition) {
  // savedPosition 会在你使用浏览器前进或后退按钮时候生效
 // 这个跟你使用 router.go() 或 router.back() 效果一致
 // 这也是为什么我在 tab 栏结构中放入了一个 点击回退 的按钮
 if (savedPosition) {
      return savedPosition
    } else {
      // 如果不是通过上述行为切换组件，就会让页面回到顶部
      return {x: 0, y: 0}
  }
}
const router = new VueRouter({
  mode: 'history',
  scrollBehavior,
  base: process.env.BASE_URL,
  routes
})

export default router
