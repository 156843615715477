<template>
  <div class="get-my-demo row-container">
    <GetMyDemoZh v-if="$store.state.currentLanguage.id==0" />
    <GetMyDemoEn v-else />
  </div>
</template>
<script>
  import GetMyDemoEn from '../components/GetADemoEn'
  import GetMyDemoZh from '../components/GetADemoZh'

  export default {
    name: 'GetMyDemo',
    components: {
      GetMyDemoZh,
      GetMyDemoEn
    }
  }
</script>
<style lang="scss">
.get-my-demo{
  width: 60%;
 h1{
    font-weight: normal;
    font-size: 1.5rem;
  }
  p {
    font-size: 0.8rem;
    opacity: 0.6;
    margin-top: 0.5rem;
    line-height: 1.6;
  }
  .demo-form{
    padding: 3rem 0;
    width: 60%;
    margin: 0 auto;
  }
  .bitian{
    .el-form-item__label{
      width: 6rem;
      &::after{
        content:' *';
        color: red;
      }
    }
  }

  .el-button--primary{
    background-color: #1F87E8;
    border-color: #1F87E8;
    width: 9rem;
  }
}
</style>