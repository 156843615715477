<template>
  <div class="newsdetail-content row-container">
    <div class="title">
      <h2>{{title}}</h2>
    </div>
    <div class="content" v-html="content"></div>
    <div class="article-bottom clearfix">
      <div class="page more">
        <router-link to="/news"> <i class="el-icon el-icon-document-copy"></i> 查看更多</router-link>
      </div>
      <div class="article-left">
        <div class="next page" v-show="prev.exists">
          <router-link :to="`/newsdetail/${prev.id}`" >
            <span>上一篇：</span>
            <span>{{prev.title}}</span>
          </router-link>
        </div>
        <div class="prev page" v-show="next.exists">
          <router-link :to="`/newsdetail/${next.id}`" >
            <span>下一篇：</span>
            <span>{{next.title}}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get } from '../plugins/request.js'
import axios from "axios"

export default {
  name: 'NewsdetailContent',
  data(){
    return{
      title: '',
      content: '',
      next:'',
      prev: ''
    }
  },
  methods: {
    getData() {
      const params = this.$route.params.id;
      const self = this;
      axios.get('https://hz.chenksoft.com:443/ckapi/api/1/v2/article_list.jsp',{
        params:{
          id: params,
          token: "chenksoft!@!"
        }
      }).then(function(res){
        const data0 = res.data.data;
        console.log()
        self.title = data0[0].title;
        self.content = data0[0].content.replace(/font-size\:12px/g, 'font-size:1.4rem');
      })
    },
    getDataPrev(){
      const params = this.$route.params.id;
      const self = this;
      axios.get('https://hz.chenksoft.com:443/ckapi/api/1/v2/top_article.jsp', {
        params:{
          ID: params,
          token: "chenksoft!@!",
        }
      }).then(function(res){
        const data0 = res.data.data;
        if( data0.length!=0){
          self.prev = { title: data0[0].title, id: data0[0].id, exists: true}
        }else{
          self.prev = { title: '', id: '', exists: false}
        }
        
      })
    },
    getDataNext(){
      const params = this.$route.params.id;
      const self = this;
      axios.get('	https://hz.chenksoft.com:443/ckapi/api/1/v2/up_article.jsp', {
        params:{
          ID: params,
          token: "chenksoft!@!",
        }
      }).then(function(res){
        const data0 = res.data.data;
        if( data0.length!=0){
          self.next = { title: data0[0].title, id: data0[0].id, exists:true}
        }else{
          self.next = { title: '', id: '', exists: false}
        }
        
      })
    },
  },
  created(){
    this.getData()
    this.getDataPrev()
    this.getDataNext()
  }
}
</script>
<style lang="scss">
  .newsdetail-content {
    padding: 2rem 0;
    .title{
      text-align: center;
      padding: 2rem 0;
    }
    .content{
      padding-bottom: 2rem;
      line-height: 2.4;
      color: #444;
      font-size: 0.7rem;
      p{
        line-height: 1.7;
      }
      img{
        display: block;
        margin: 0 auto;
      }
    }
    .article-bottom {
      padding-top: 1rem;
      border-top: 1px solid #dedede;
      .page{
        color: #666;
        font-size: 0.75rem;
        margin-bottom: 1rem;
        a:hover{
          color: #235EB6;
          text-decoration: underline;
        }
      }
      .article-left{
        width: 80%;
        float: left;
      }
      .more{
        width: 20%;
        float: right;
        text-align: right;
        i{
          font-size: 0.8rem;
        }
      }
    }
  }
</style>