<template>
  <div id="app" class="el-container is-vertical">
    <!-- <div style="font-size:14px">font-size:14px</div>
    <div style="font-size:1.4rem">font-size:1.4rem</div>
    <div style="font-size:20px">font-size:20px</div>
    <div style="font-size:2rem">font-size:2rem</div> -->
    <keep-alive>
      <router-view :key="key"></router-view>
    </keep-alive>
    <el-backtop style="right: 22px; bottom: 100px;"></el-backtop>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import axios from "axios"

export default {
  components: {
    Header
  },
  computed:{
    key(){
      //console.log(this.$route.path)
      return this.$route.path
    }
  },
  created(){
    sessionStorage.setItem('language', 0)
    sessionStorage.setItem('spath',  '')
    var text = ''
    const languageId = sessionStorage.getItem('language')
    //console.log(languageId)
    if(languageId==0){
      text = "您好，请问有什么可以帮您？"
    }else{
      text = "How can we help you?"
    }

    var flag = 1;
    
    function mycallback(param)
    {
      
      var logidUrl = window.location.href
       
      if(flag == 1){
        axios({
          method:'post',
          url:'https://hz.chenksoft.com/mixkit/jimuyu/eventupload',
          data: 'eventType=92&logidUrl='+logidUrl,
          headers: {
              contentType:'application/json'
          },
        }).then(function(res){
          console.log('code'+res.data.code)
          if(res.data.code==0){
            flag = 0
          }
        })
      }
    }
    window.$crisp.push(['do', 'chat:open']);
    window.$crisp.push(["on", "message:sent", mycallback]);
    

    function crisp30(){
      const chatIsOpened = window.$crisp.is("chat:opened")
      //console.log(chatIsOpened)
      var domain = document.domain
      //console.log(domain)
      window.$crisp.push(["set", "session:data", [[["domain-name", domain]]]])
      if(!chatIsOpened){
         window.$crisp.push(["do", "message:show", ["text", text]])
      }
      //console.log(1)
    }
    
    setTimeout(crisp30,10000);
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial,"Microsoft YaHei",sans-serif,"Noto Sans","Helvetica Neue","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
a:link,a:visited,a:hover,a:active{
  color: initial;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}
html{
  //1rem = 20px
  font-size: 125%;
  //font-size: 20px;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

ul, li{
  list-style: none;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
#app {
 font-family: Arial,"Noto Sans",sans-serif,"Microsoft YaHei","Helvetica Neue","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222;
  font-size: 14px;
}
.row-container {
  margin: 0 auto;
}
.clearfix {
  *zoom: 1;

  &::after{ /*伪元素是行内元素 正常浏览器清除浮动方法*/
    content: "";
    display: block;
    height: 0;
    clear:both;
    visibility: hidden;
  }
}
.bg-grey{
  width: 100%;
  background: #f8f8f8;
}
.el-backtop{
  width: 3rem !important;
  height: 3rem !important;
  font-size: 1.2rem !important;
}
.padding-10{
  margin: 0 10px;
}
//隐藏百度对话框
#newBridge{
  display: none !important;
}
#aff-im-root{
  display: none !important;
}
ins#aff-im-root{
  display: none !important;
}
</style>
