<template>
  <div class="header">
    <div class="nav-desktop">
      <el-menu :default-active="activeIndex" class="el-menu-nav" mode="horizontal" @select="handleSelect" text-color="#444" active-text-color="#1F87E8">
        <el-menu-item index="/en"><router-link to="/en" class="item-a">{{menus[0]}}</router-link></el-menu-item>
        <el-menu-item index="/en/platform"><router-link to="/en/platform" class="item-a">{{menus[1]}}</router-link></el-menu-item>
        <el-submenu index="/en/products" :popper-append-to-body="false">
          <template slot="title" class="item-a">{{menus[2]}}</template>
          <el-menu-item :index="`/en/products/${item.sId}`" class="header-el-menu-item" v-for="item in products">
            <router-link :to="`/en/products/${item.sId}`"> {{item.sname}} </router-link>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="/en/solutions" :popper-append-to-body="false" >
          <template slot="title" class="item-a">{{menus[3]}}</template>
          <el-menu-item :index="`/en/solutions/${item.id}`" class="header-el-menu-item" v-for="item in solutions">
            <router-link :to="`/en/solutions/${item.id}`"> {{item.name}} </router-link>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="/en/customize"><router-link class="item-a" to="/en/customize">{{menus[4]}}</router-link></el-menu-item>
        <el-menu-item index="/en/about"><router-link class="item-a" to="/en/about">{{menus[5]}}</router-link></el-menu-item>
        <el-menu-item index="/en/contact"><router-link class="item-a" to="/en/contact">{{menus[6]}}</router-link></el-menu-item>
        <el-menu-item index="/en/cases"><router-link class="item-a" to="/en/cases">{{menus[7]}}</router-link></el-menu-item>
      </el-menu>
    </div>
    <div class="clearfix header-dropdown-menu nav-mobile">
      <div class="nav-mobile-vertical" v-show="dropShow">
        <ul class="fist-layer">
          <li :class="activeItem=='/en'?'active':''" ><router-link to="/en">{{menus[0]}}<i class="el-icon-check el-icon"></i></router-link></li>
          <li :class="activeItem=='/en/platform'?'active':''"><router-link to="/en/platform" >{{menus[1]}}<i class="el-icon-check el-icon"></i></router-link></li>
          <li>
            <div class="layer-head" @click="showSecondLayer1()">
              <span>{{menus[2]}}</span>
              <i :class="productDropShow?'el-icon-arrow-down el-icon':'el-icon-arrow-right el-icon'"></i>
            </div>
            <ul class="second-layer" v-show="productDropShow">
              <li v-for="item in products" :class="activeItem==`/en/products/${item.sId}`?'active':''"><router-link :to="`/en/products/${item.sId}`"> {{item.sname}} <i class="el-icon-check el-icon"></i></router-link></li>
            </ul>
          </li>
          <li>
            <div class="layer-head" @click="showSecondLayer2()">
              <span>{{menus[3]}}</span>
              <i :class="solutionDropShow?'el-icon-arrow-down el-icon':'el-icon-arrow-right el-icon'"></i>
            </div>
            <ul class="second-layer" v-show="solutionDropShow">
              <li v-for="item in solutions" :class="activeItem==`/en/products/${item.sId}`?'active':''"><router-link :to="`/en/solutions/${item.sId}`"> {{item.name}} <i class="el-icon-check el-icon"></i></router-link></li>
            </ul>
          </li>
          <li :class="activeItem=='/en/customize'?'active':''"><router-link to="/en/customize">{{menus[4]}}<i class="el-icon-check el-icon"></i></router-link></li>
          <li :class="activeItem=='/en/about'?'active':''"><router-link to="/en/about">{{menus[5]}}<i class="el-icon-check el-icon"></i></router-link></li>
          <li :class="activeItem=='/en/contact'?'active':''"><router-link to="/en/contact">{{menus[6]}}<i class="el-icon-check el-icon"></i></router-link></li>
          <li :class="activeItem=='/en/cases'?'active':''"><router-link to="/en/cases">{{menus[7]}}<i class="el-icon-check el-icon"></i></router-link></li>
        </ul>
        <div class='maskin' @click="dropShow=false"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, imageFile } from '../plugins/request.js'
import { mapMutations } from 'vuex'

import logo from '../assets/logo.png'
import logoen from '../assets/logo-e.png'
import getQueryString from '../plugins/getQueryString.js'

export default {
  name: 'PcHeader',
  props: {
    msgVal: Boolean
  },
  data() {
    return {
      menus:[],
      hotline:'',
      products: [],
      solutions: [],
      activeItem: '',
      dropShow: false,
      productDropShow: true,
      solutionDropShow: false
      //activeIndex: '/en/products/5'.replace(/^\/(en|cn)\//g, '\/'),
    };
  },
  methods: {
    showSecondLayer1(){
      this.productDropShow = !this.productDropShow
    },
    showSecondLayer2(){
      this.solutionDropShow = !this.solutionDropShow
    },
    handleSelect(key, keyPath) {
      //console.log(key, keyPath);
    },
    getMenuPro(params) {
      const self = this;
      get('/product_module.jsp', params).then(function(res){
        //console.log(res.data.data)
        const data0 = res.data.data;
        self.products = data0
      })
    },
    getMenuSolutions(params) {
      const self = this;
      get('/solution_module.jsp', params).then(function(res){
        //console.log(res.data.data)
        const data0 = res.data.data;
        self.solutions = data0
      })
    },
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.hotline = title['hotline']
        for(let i=0; i<8; i++){
          self.menus[i] = title[`hMenu${i+1}`]
        }
        console.log(self.menus)
      })
    }
  },
  computed: {
    activeIndex: function () {
      const path = this.$route.path
      return path
    },
  },
  created: function() {
    this.activeItem = this.$route.path
    const id = this.$store.state.currentLanguage.id
    this.getMenuPro({langtype:id})
    this.getMenuSolutions({langtype:id})
    this.getValue({lang: id, value: 'hotline;hMenu1;hMenu2;hMenu3;hMenu4;hMenu5;hMenu6;hMenu7;hMenu8;'})
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      this.getValue({lang: id, value: 'hotline;hMenu1;hMenu2;hMenu3;hMenu4;hMenu5;hMenu6;hMenu7;hMenu8;'})
    },
    dropShow: function(){
      if(this.dropShow) {
        document.body.style.overflow='hidden'
      }else {
        document.body.style.overflow='auto'
      }
    },
    msgVal: function(){
      this.dropShow = this.msgVal
    }
  },
}
</script>

<style lang="scss">
.pc-header {
  padding-bottom:0.95rem;
}
@media only screen and (max-width: 640px){
  .row-container{
    margin: 0 auto !important;
  }
}

@media only screen and (min-width: 641px) and (max-width: 767px){
  .row-container{
    margin: 0 auto !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
  .nav-mobile{
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px){
  .nav-mobile{
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .nav-mobile{
    display: none;
  }
}

</style>