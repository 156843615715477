<template>
  <div class="pitemrow26">
    <div class="pitemrow26-item">
      <div class="content">
        <p class="title1">智慧实验室管理系统</p>
        <p class="title2">智慧实验室平台全面覆盖实验室、仪器设备、试剂耗材、样品等对象管理，人员的培训、考试、档案等管理， 科研项目的过程管理，支持实验室环境的监测管理，以及试验室规章制度建设</p>
        <ul>
          <li><span class="management-icon" style="background-position:0 -152px"></span><p>用户管理</p></li>
          <li><span class="management-icon" style="background-position:-38px -152px"></span><p>培训管理</p></li>
          <li><span class="management-icon" style="background-position:-76px -152px"></span><p>考试管理</p></li>
          <li><span class="management-icon" style="background-position:-114px -152px"></span><p>项目管理</p></li>
          <li><span class="management-icon" style="background-position:-152px -152px"></span><p>实验室管理</p></li>
          <li><span class="management-icon" style="background-position:0 -190px"></span><p>动物房</p></li>
          <li><span class="management-icon" style="background-position:-38px -190px"></span><p>试剂耗材</p></li>
          <li><span class="management-icon" style="background-position:-76px -190px"></span><p>样品管理</p></li>
          <li><span class="management-icon" style="background-position:-114px -190px"></span><p>预约管理</p></li>
          <li><span class="management-icon" style="background-position:-152px -190px"></span><p>数据报告</p></li>
        </ul>
        <el-button type="primary" style="margin-top:1.5rem" @click="openConsultBox">免费试用</el-button>
      </div>
      <div class="imgright">
        <img :src="aimg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import TitleBar from './TitleBar.vue'

import arrow from '../assets/arrow-right.png'
import a from '../assets/软件产品-pow6.png'
import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'
import axios from "axios"

export default {
  name: 'PItemRow2',
  data() {
    return {
      msg:{
        title: '软件产品',
        subTitle: '资产 | 设备 | 文件档案 | eGMP | 智慧实验室'
      },
      aimg:a,
      arrow: arrow,
      item3:''
    }
  },
  components: {
    TitleBar
  },
  methods: {
    openConsultBox(){
      window.$crisp.push(['do', 'chat:open']);
    },
  },
  created: function(){
    
      
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      
    }
  }
}
</script>

<style lang="scss">
.pitemrow26 {
  max-width: 1440px;
  margin: 2rem auto;
  margin-bottom: 5rem;
  .pitemrow26-item{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    max-width: 1440px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .content{
    width: 20.6rem;
    margin-top: 5rem;
    .title1{
      font-size: 1.6rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }
    .title2{
      font-size: 0.8rem;
      margin-bottom: 2.5rem;
      color:#666666;
      line-height: 1.7;
    }
    ul{
      width: 22rem;
      li{
        display: inline-block;
        text-align: center;
        margin-right: 0.8rem;
        margin-bottom:1rem;
        font-size: 0.7rem;
        width: 3.5rem;
        .management-icon{
          display: inline-block;
          width: 38px;
          height: 38px;
          background-image: url(../assets/projecticons.png);
          background-size: 500%;
          background-position:0 0;
          border: 0;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  .imgright{
    margin-top: 2rem;
  }
}
</style>