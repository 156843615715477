<template>
  <div class="home">
    <Header />
    <Banner />
    <PItemRow2 />
    <PItemRow22 />
    <PItemRow23 />
    <PItemRow24 />
     <PItemRow26 />
    <PItemRow25 />
    
    <div style="background-color:#161616"><PItemRowVideo /></div>
    <div class="bg-grey"><PItemRow3-image-left /></div>
    <PItemRow1 />
    <div class="bg-grey"><HCases /></div>
    <HService />
    <div style="position:fixed;left: 10px; top: 545px;z-index: 99;" class="code-left-fixed">
      <img src="../assets/微信公众号.png" alt="" style="width:100px;box-shadow: 0 3px 6px 0 rgba(0,0,0,.1);">
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/Banner.vue'
import PItemRow2 from '@/components/PItemRow2.vue'
import PItemRow22 from '@/components/PItemRow2-2.vue'
import PItemRow23 from '@/components/PItemRow2-3.vue'
import PItemRow24 from '@/components/PItemRow2-4.vue'
import PItemRow25 from '@/components/PItemRow2-5.vue'
import PItemRow26 from '@/components/PItemRow2-6.vue'
import PItemRow3 from '@/components/PItemRow3.vue'
import PItemRow4 from '@/components/PItemRow4.vue'
import PItemRow1 from '@/components/PItemRow1.vue'
import PItemRowVideo from '@/components/PItemRowVideo.vue'
import PItemRow3ImageLeft from '@/components/PItemRow3-image-left.vue'
import HCases from '@/components/HCases.vue'
import HService from '@/components/HService.vue'
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'

export default {
  name: 'home',
  components: {
    Header,
    Banner,
    PItemRow2,
    PItemRow23,
    PItemRow22,
    PItemRow24,
    PItemRow25,
    PItemRow26,
    PItemRow1,
    PItemRowVideo,
    PItemRow3ImageLeft,
    HCases,
    HService,
    Footer
  },
  created: function() {
    
  }
}
</script>
<style lang="scss">

</style>