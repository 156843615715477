<template>
  <div class="m-header nav-mobile">
    <div class="clearfix header-dropdown-menu">
      <div class="nav-mobile-vertical" v-show="dropShow">
        <ul class="first-layer">
          <li :class="activeItem==item.id?'active':''" v-for="item in $store.state.language" @click="selectLanguage(item.id)">
           <a>
          {{ item.name}}
          <!-- <i class="el-icon-check el-icon"></i> -->
            </a>
          </li>
        </ul>
        <div class='maskin' @click="hideDrop()"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

export default {
  name: 'MDropMenu',
  props: {
    msgVal: Boolean
  },
  data() {
    return {
      menus:[],
      activeItem: 0,
      dropShow: false,
      routerTo:'',
      metaAndTitle :{
        title: {
          'zh':'助力数字化转型 驱动业务创新 | 晨科软件',
          'en':'Boost Digital Transformation, Drive Business Innovation | Chenksoft'
        },
        description: {
          'zh':'晨科软件以先进的平台开发技术为基础，凭借专业的本地化团队和十多年软件开发经验，根据客户个性化需求进行快速开发、敏捷定制，致力于通过“软件产品+定制服务”的业务模式，全方位满足客户需求，为客户的持续发展打造合身的业务系统，提供强有力的信息化支持。',
          'en':'Based on advanced platform development technology, with a professional localization team and more than ten years of software development experience, Chenksoft conducts rapid development and agile customization according to the individual needs of customers, and is committed to adopting the business model of "Software Products + Custom Services" , to meet the needs of customers in all aspects, to create a suitable business system for the continuous development of customers, and to provide strong informationization support.'
        },
        keywords: {
          'zh':'快速开发,敏捷定制,定制开发,软件开发,管理软件,管理系统,系统开发,信息系统,网站开发,软件外包,后台系统,二次开发,app开发,app定制,HTML5开发,网页开发,app外包',
          'en':'custom software development,custom software,software development company,custom software programming,software development firm,custom software design,custom software development services,custom software development company,software application development company,custom software solution,custom software company,custom software application development,tailored software,bespoke development'
        }
      }
      //activeIndex: '/en/products/5'.replace(/^\/(en|cn)\//g, '\/'),
    };
  },
  methods: {
    hideDrop(){
      this.dropShow = false
      this.$emit('dropdrop', false)
    },
    handleSelect(key, keyPath) {
      //console.log(key, keyPath);
    },
    selectLanguage(key) {
      this.dropShow = false
      this.$emit('dropdrop', false)
      this.activeItem = key
      const path = this.$route.path
      this.$store.commit('changeLanguage', {id: key, path: path})
      if(key==0){
        const path0 = path.replace(/^\/(en|cn)(\/)?/g, '\/')
        if(path!=path0){
          this.$router.push(path0)
        }
        //console.log(path0)
      }else if(key==1){
        const path1 = '/en'+path.replace(/^\/(en|cn)(\/)?/g, '\/').replace(/\/$/g, '')
        if(path!=path1){
          this.$router.push(path1)
        }
      }
    },
    getMenuPro(params) {
      const self = this;
      get('/product_module.jsp', params).then(function(res){
        //console.log(res.data.data)
        const data0 = res.data.data;
        self.products = data0
      })
    },
    getMenuSolutions(params) {
      const self = this;
      get('/solution_module.jsp', params).then(function(res){
        //console.log(res.data.data)
        const data0 = res.data.data;
        self.solutions = data0
      })
    },
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.hotline = title['hotline']
        
        for(let i=0; i<8; i++){
          self.menus[i] = title[`hMenu${i+1}`]
        }
      })
    }
  },
  computed: {
    activeIndex: function () {
      const path = this.$route.path
      return path
    },
    demo: function() {
      if(this.$store.state.currentLanguage.id == 0){
          return '免费试用'
      }else {
        return 'Demo'
      }
    }
  },
  created: function() {
    this.activeItem = parseInt(sessionStorage.getItem('language'))
  },
  watch: {
    msgVal: function(){
      this.dropShow = this.msgVal
    }
  },
}
</script>

<style lang="scss">
.m-header {
  .header-dropdown-menu{
    .call {
      font-size: 1rem;
      color: #235EB6;
      font-weight: bold;
      float: right;
      .el-icon{
        font-size: 1.3rem;
        margin-right: 0.5rem;
      }
    }
    .menu{
      font-size: 1.4rem;
      float: left;
      line-height: 2rem;
      cursor: pointer;
      .el-icon{
        margin-right: 0.5rem;
        vertical-align: middle;
        font-size: 1.4rem;
      }
      img{
        vertical-align: middle;
        margin-right: 0.5rem;
      }
      span{
        font-size: 1.4rem;
      }
    }
    .language{
      float: right;
    }
  }
  
}
@media only screen and (max-width: 640px){
  .row-container{
    margin: 0 auto !important;
  }
}

@media only screen and (min-width: 641px) and (max-width: 767px){
  .row-container{
    margin: 0 auto !important;
  }
  
}

@media only screen and (min-width: 768px) and (max-width: 991px){
  .nav-mobile{
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px){
  .nav-mobile{
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .nav-mobile{
    display: none;
  }
}

</style>