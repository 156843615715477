<template>
  <div class="page-404">
    <img src="../assets/404.svg" alt="">
    <h2>The page doesn't exist</h2>
  </div>
</template>

<script>

export default {
  name: 'Page404'
}
</script>
<style lang="scss">
  .page-404{
    text-align: center;
    img{
      width: 40rem;
      opacity: 0.2;
      margin-top: 20rem;
    }
   h2{
     color: #ddd;
     font-size: 3rem;
   }
  }
</style>