<template>
<div class="contact-en row-container">
  <img src="../assets/word.png" alt="" class="word-desktop">
  <img src="../assets/word-m.png" alt="" class="word-m">
  <!-- <el-card class="box-card">
    <el-popover placement="top" width="96" trigger="manual" content="Copy Success!" v-model="visible1">
      <div class="list" slot="reference" @click="copyToClipboard1('hotline')"><i class="el-icon-phone-outline el-icon"></i>{{hotline}}</div>
    </el-popover>
    <el-popover placement="left" width="96" trigger="manual" content="Copy success!" v-model="visible2">
      <div class="list" slot="reference" @click="copyToClipboard2('hotline')"><i class="el-icon-message el-icon"></i>{{email}}</div>
     </el-popover>
  </el-card> -->
  <div class="table-contact">
    <el-table
      :data="tableData" class="table">
       <el-table-column
        prop="address"
        label="Country">
      </el-table-column>
      <el-table-column
        prop="phone"
        label="Phone">
      </el-table-column>
      <el-table-column
        prop="email"
        label="Email">
      </el-table-column>
    </el-table>
  </div>
  
</div>
</template>
<script>
import TitleBarOnlyText from '../components/TitleBarOnlyText.vue'
import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

export default {
  name: 'ContactEn',
  components: {
    TitleBarOnlyText
  },
  data() {
    return {
      msg: {
        title: 'contact us',
        subtitle: 'Senior expert team, provide localized professional services'
      },
      tableData: [{
            phone: '+230 58435433',
            address: 'Mauritius',
            email: 'info@chenksoft.com',
          }, {
            phone: '+65 98586237',
            address: 'Singapore',
            email: 'info@chenksoft.com',
          },{
            phone: '+86 15382379615 ',
            address: 'others',
            email: 'info@chenksoft.com',
          }],
      visible1: false,
      visible2: false,
      timer: '',
      hotline: '',
      email: ''
    }
  },
  mounted: function(){
    
  },
  methods: {
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        
        self.hotline = title['hotline'].split(':')[1]
        self.email = title['email'].split(':')[1]
        console.log(self.hotline,self.email)
      })
    },
    createInpt(txt){
      var oInput = document.createElement('input');
			oInput.value = txt;
			document.body.appendChild(oInput);
			oInput.select(); // 选择对象
			document.execCommand("Copy"); // 执行浏览器复制命令
			oInput.className = 'oInput';
			document.body.removeChild(oInput);
    },
    copyToClipboard1(txt) {
      this.createInpt(txt)
      this.visible1 = true
      var self = this
      this.timer = setTimeout(function(){self.visible1 = false}, 1500)
    },
    copyToClipboard2(txt) {
      this.createInpt(txt)
      this.visible2 = true
      var self = this
      this.timer = setTimeout(function(){self.visible2 = false}, 1500)
    }
  },
  created: function(){
    const id = this.$store.state.currentLanguage.id
    this.getValue({lang: id, value: 'hotline;email;'})
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      this.getValue({lang: id, value: 'hotline;email;'})
    }
  },
  beforeDestroy() {
     window.clearTimeout(this.timer);
  }
}
</script>
<style lang="scss">
.table-contact{
   margin-bottom: 2rem;
   .table{
      border: 0.05rem solid #EBEEF5;
      border-radius: 0.4rem;
      padding: 1rem 2rem 2rem 2rem;
       &::before {
        background-color: transparent !important;
      }
   }
   .el-table .cell{
     font-size: 0.8rem;
   }
}
.el-popover{
    min-width: 3rem !important;
  }
.contact-en {
  position: relative;
  .el-card__body{
    padding: 1rem !important;
  }
  .word-m{
    display: none;
  }
  .box-card {
    position: absolute;
    right: 2rem;
    top: 10rem;
    width: 13rem;
    font-size: 0.9rem;
   font-family: Arial,"Noto Sans",sans-serif,"Microsoft YaHei","Helvetica Neue","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    .list {
      margin: 0.5rem 0;
      &:hover{
        color: #1F87E8;
        cursor: pointer;
      }
    }
    .el-icon{
      margin-right: 0.5rem;
    }
  }
  .area {
    margin-bottom: 0.5rem;
    
    p {
      font-size: 0.9rem;
      padding: 1rem 0.5rem;
    }
    .table {
      border: 0.05rem solid #EBEEF5;
      border-radius: 0.2rem;
      padding: 1rem 2rem;
      &::before {
        background-color: transparent !important;
      }
      
    }

  }
}
</style>