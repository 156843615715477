import Vue from 'vue'
import { Button, Icon, Menu, Submenu, MenuItem,Carousel,CarouselItem, Steps, Step,
  Table, TableColumn,Tabs, TabPane,Backtop, Image, Divider, Card, Dropdown, DropdownMenu, 
  DropdownItem, Tooltip, Popover, Form, FormItem, Input, Select,Option, Notification, Pagination, Checkbox, CheckboxGroup} from 'element-ui'


Vue.use(Button)
Vue.use(Icon)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(TabPane)
Vue.use(Tabs)
Vue.use(Backtop)
Vue.use(Image)
Vue.use(Divider)
Vue.use(Card)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Tooltip)
Vue.use(Popover)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Pagination)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.component(Notification.name, Notification)
Vue.prototype.$notify = Notification