var docEl=document.documentElement;

var dpr=window.devicePixelRatio||1;
var rem=docEl.clientWidth*dpr/10;
var scale=1/dpr;

export function addFontsize(){
  //判断是否为手机
  const flag = isMobile()
  if(flag) {
    //修改viewport
    document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui')
    fontsize()
  }else{
    document.querySelector('meta[name="viewport"]').setAttribute('content', "width=device-width, initial-scale=1.0, user-scalable=no")
  }
  //修改根目录font-size 
  //视窗监听
  
}


function fontsize(params) {
  var docEl = document.documentElement,
  resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
  
  recalc = function () {
    console.log(dpr)
    if(dpr==1){
      docEl.style.fontSize = '10px'; 
    }else{
      var clientWidth = docEl.clientWidth>828 ? 828 :docEl.clientWidth;
      if (!clientWidth) return;
      docEl.style.fontSize = 40*clientWidth/828+'px'; 
    }
     
  };
  if (!document.addEventListener) return;
  window.addEventListener(resizeEvt, recalc, false);
  document.addEventListener('DOMContentLoaded', recalc, false);
}

function isMobile (){
  var userAgentInfo = navigator.userAgent;
  var Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];
  var flag = false;
  for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = true;
          break;
      }
  }
  return flag;
}

export function isMobile2 (){
  var userAgentInfo = navigator.userAgent;
  var Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];
  var flag = false;
  for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = true;
          break;
      }
  }
  console.log(flag)
  return flag;
}
