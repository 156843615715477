<template>
  <div class="header-en ">
    <div class="nav-desktop">
      <el-menu :default-active="activeIndex" class="el-menu-nav" mode="horizontal" @select="handleSelect" text-color="#444" active-text-color="#1F87E8">
        <el-menu-item index="/en"><router-link to="/en" class="item-a">Home</router-link></el-menu-item>
        <el-menu-item index="/en/platform"><router-link to="/en/platform" class="item-a">Platform</router-link></el-menu-item>
        <el-submenu index="/en/products" :popper-append-to-body="false">
          <template slot="title" class="item-a">Products</template>
          <el-menu-item :index="`/en/products/${item.sId}`" class="header-el-menu-item" :key="index" v-for="(item, index) in products" >
            <router-link :to="`/en/products/${item.sId}`"> {{item.sname}} </router-link>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="/en/solutions" :popper-append-to-body="false" >
          <template slot="title" class="item-a">Solutions</template>
          <el-menu-item :key="index" :index="`/en/solutions/${item.id}`" class="header-el-menu-item" v-for="(item, index) in solutions">
            <router-link :to="`/en/solutions/${item.id}`"> {{item.name}} </router-link>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="/en/customize"><router-link class="item-a" to="/en/customize">Services</router-link></el-menu-item>
        <el-menu-item index="/en/about"><router-link class="item-a" to="/en/about">About Us</router-link></el-menu-item>
        <el-menu-item index="/en/contact"><router-link class="item-a" to="/en/contact">Contact Us</router-link></el-menu-item>
        <el-menu-item index="/en/cases"><router-link class="item-a" to="/en/cases">Our Customers</router-link></el-menu-item>
      </el-menu>
    </div>
    
    <div class="nav-mobile clearfix">
      <div class="nav-mobile-vertical" v-show="dropShow">
        <ul class="first-layer">
          <li :class="activeItem=='/en'?'active':''"  @click="changeActiveItem()"><router-link to="/en">Home <i class="el-icon-check el-icon"></i></router-link></li>
          <li :class="activeItem=='/en/platform'?'active':''" @click="changeActiveItem()"><router-link to="/en/platform" >Platform <i class="el-icon-check el-icon"></i></router-link></li>
          <li>
            <div class="layer-head" @click="showSecondLayer1()">
              <span>Products</span>
              <i :class="productDropShow?'el-icon-arrow-down el-icon':'el-icon-arrow-right el-icon'"></i>
            </div>
            <ul class="second-layer" v-show="productDropShow">
              <li :key="index" v-for="(item, index) in products" :class="activeItem==`/en/products/${item.sId}`?'active':''" 
              @click="changeActiveItem()"><router-link :to="`/en/products/${item.sId}`"> {{item.sname}} <i class="el-icon-check el-icon"></i></router-link></li>
            </ul>
          </li>
          <li>
            <div class="layer-head" @click="showSecondLayer2()">
              <span>Solutions</span>
              <i :class="solutionDropShow?'el-icon-arrow-down el-icon':'el-icon-arrow-right el-icon'"></i>
            </div>
            <ul class="second-layer" v-show="solutionDropShow">
              <li :key="index" v-for="(item, index) in solutions" :class="activeItem==`/en/solutions/${item.id}`?'active':''"
              @click="changeActiveItem()"><router-link :to="`/en/solutions/${item.id}`"> {{item.name}} <i class="el-icon-check el-icon"></i></router-link></li>
            </ul>
          </li>
          <li :class="activeItem=='/en/customize'?'active':''" @click="changeActiveItem()"><router-link to="/en/customize">Services <i class="el-icon-check el-icon"></i></router-link></li>
          <li :class="activeItem=='/en/about'?'active':''" @click="changeActiveItem()"><router-link to="/en/about">About Us<i class="el-icon-check el-icon"></i></router-link></li>
          <li :class="activeItem=='/en/contact'?'active':''" @click="changeActiveItem()"><router-link to="/en/contact">Contact Us<i class="el-icon-check el-icon"></i></router-link></li>
          <li :class="activeItem=='/en/cases'?'active':''" @click="changeActiveItem()"><router-link to="/en/cases">Our Customers<i class="el-icon-check el-icon"></i></router-link></li>
        </ul>
        <div class='maskin' @click="hideDrop()"></div>
      </div>
      <!-- <router-link :to="$store.state.currentLanguage.id==0?'/get-demo':'/en/get-demo'" class="language" >
        <el-button type="primary" round style="margin-left:20px">{{demo}}</el-button>
      </router-link>
      <el-dropdown class="language" @command="selectLanguage" >
        <el-button round>
          {{$store.state.currentLanguage.name}}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <router-link :to="item.path" v-for="item in $store.state.language"><el-dropdown-item   :command="item.id">{{ item.name}}</el-dropdown-item></router-link>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
  </div>
</template>

<script>
import { get, imageFile } from '../plugins/request.js'
import { mapMutations } from 'vuex'

import logo from '../assets/logo.png'
import logoen from '../assets/logo-e.png'
import getQueryString from '../plugins/getQueryString.js'

export default {
  name: 'HeaderEn',
  props: {
     msgVal: Boolean
  },
  data() {
    return {
      hotline:'',
      products: [],
      solutions: [],
      //activeItem: '',
      dropShow: false,
      productDropShow: false,
      solutionDropShow: false
      //activeIndex: '/en/products/5'.replace(/^\/(en|cn)\//g, '\/'),
    };
  },
  methods: {
    changeActiveItem(){
      this.dropShow = false
      this.$emit('dropdrop4', false)
      //this.activeItem = this.$route.path
    },
    hideDrop(){
      this.dropShow = false
      this.$emit('dropdrop4', false)
    },
    showSecondLayer1(){
      this.productDropShow = !this.productDropShow
    },
    showSecondLayer2(){
      this.solutionDropShow = !this.solutionDropShow
    },

    handleSelect(key, keyPath) {
      //console.log(key, keyPath);
    },
    selectLanguage(key) {
      //console.log(key)
      const path = this.$route.path
      this.$store.commit('changeLanguage', {id: key, path: path})
    },
    getMenuPro(params) {
      const self = this;
      get('/product_module.jsp', params).then(function(res){
        //console.log(res.data.data)
        const data0 = res.data.data;
        self.products = data0
      })
    },
    getMenuSolutions(params) {
      const self = this;
      get('/solution_module.jsp', params).then(function(res){
        //console.log(res.data.data)
        const data0 = res.data.data;
        self.solutions = data0
      })
    },
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.hotline = title['hotline']
      })
    }
  },
  computed: {
    activeIndex: function () {
      const path = this.$route.path
      return path
    },
    activeItem: function(){
      const path = this.$route.path
      return path
    },
    demo: function() {
      if(this.$store.state.currentLanguage.id == 0){
          return '免费试用'
      }else {
        return 'Demo'
      }
    }
  },
  created: function() {
    //this.activeItem = this.$route.path
    this.getMenuPro({langtype:1})
    this.getMenuSolutions({langtype:1})
    this.getValue({lang: 1, value: 'hotline;'})
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      this.getValue({lang: id, value: 'hotline;'})
    },
    // dropShow: function(){
    //   console.log(this.dropShow)
    //   if(this.dropShow) {
    //     document.body.style.overflow='hidden'
    //   }else {
    //     document.body.style.overflow='auto'
    //   }
    // },
    msgVal: function(){
      this.dropShow = this.msgVal
    }
  }
}
</script>

<style lang="scss">
.header-en {
  .nav-mobile{
    .call {
      font-size: 1rem;
      color: #1F87E8;
      font-weight: bold;
      float: right;
      .el-icon{
        font-size: 1.3rem;
        margin-right: 0.5rem;
      }
    }
    .menu{
      font-size: 1.4rem;
      float: left;
      line-height: 2rem;
      cursor: pointer;
      .el-icon{
        margin-right: 0.5rem;
        vertical-align: middle;
        font-size: 1.4rem;
      }
      img{
        vertical-align: middle;
        margin-right: 0.5rem;
      }
      span{
        font-size: 0.9rem;
      }
    }
    .language{
      float: right;
    }
    
   
  }
  
}
@media only screen and (max-width: 640px){
  .row-container{
    margin: 0 auto !important;
  }
}

@media only screen and (min-width: 641px) and (max-width: 767px){
  .row-container{
    margin: 0 auto !important;
  }
  
}

@media only screen and (min-width: 768px) and (max-width: 991px){
  .nav-mobile{
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px){
  .nav-mobile{
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .nav-mobile{
    display: none;
  }
}

</style>