<template>
  <div class="HCasesNew row-container">
    <ul class="items">
     <li class="first">
       <div>
         <p>世界500强 <span>30+</span> </p>
         <ul class="logo-content">
          <li><img :src="items[0]" alt=""></li><li><img :src="items[1]" alt=""></li>
          <li><img :src="items[27]" alt=""></li>
          <li><img :src="items[28]" alt=""></li><li><img :src="items[29]" alt=""></li><li><img :src="items[30]" alt=""></li>
           <li><img :src="items[31]" alt=""></li><li><img :src="items[32]" alt=""></li><li><img :src="items[17]" alt=""></li>
           <li><img :src="items[34]" alt=""></li><li><img :src="items[35]" alt=""></li><li><img :src="items[44]" alt=""></li>
          <li><img :src="items[48]" alt=""></li><li><img :src="items[2]" alt=""></li>
          <li><img :src="items[4]" alt=""></li><li><img :src="items[5]" alt=""></li><li><img :src="items[6]" alt=""></li>
          <li><img :src="items[7]" alt=""></li>
          
         </ul>
       </div>
     </li>
     <li class="first">
       <div>
         <p>中国500强 <span>100+</span> </p>
         <ul class="logo-content">
            <li><img :src="items[10]" alt=""></li><li><img :src="items[11]" alt=""></li><li><img :src="items[12]" alt=""></li>
            <li><img :src="items[13]" alt=""></li><li><img :src="items[14]" alt=""></li><li><img :src="items[15]" alt=""></li>
            <li><img :src="items[16]" alt=""></li><li><img :src="items[33]" alt=""></li>
           <li><img :src="items[18]" alt=""></li><li><img :src="items[19]" alt=""></li><li><img :src="items[20]" alt=""></li><li><img :src="items[21]" alt=""></li>
           <li><img :src="items[22]" alt=""></li><li><img :src="items[23]" alt=""></li><li><img :src="items[24]" alt=""></li>
           <li><img :src="items[3]" alt=""></li><li><img :src="items[26]" alt=""></li><li><img :src="items[8]" alt=""></li>
         </ul>
       </div>
     </li>
     <li class="first">
       <div>
         <p>上市公司 <span>200+</span> </p>
         <ul class="logo-content">
           <li><img :src="items[36]" alt=""></li><li><img :src="items[37]" alt=""></li><li><img :src="items[38]" alt=""></li><li><img :src="items[39]" alt=""></li>
           <li><img :src="items[40]" alt=""></li><li><img :src="items[41]" alt=""></li><li><img :src="items[42]" alt=""></li>
           <li><img :src="items[43]" alt=""></li><li><img :src="items[9]" alt=""></li><li><img :src="items[45]" alt=""></li>
           <li><img :src="items[46]" alt=""></li><li><img :src="items[47]" alt=""></li><li><img :src="items[25]" alt=""></li>
           <li><img :src="items[49]" alt=""></li><li><img :src="items[50]" alt=""></li><li><img :src="items[51]" alt=""></li>
           <li><img :src="items[52]" alt=""></li><li><img :src="items[53]" alt=""></li>
         </ul>
       </div>
     </li>
    </ul>
  </div>
</template>

<script>
import TitleBar from './TitleBar.vue'
import HNews from './HNews.vue'
import Cases from '../assets/customer-logos.png'

import logo1 from '../assets/case-logo-1.png';  
import logo2 from '../assets/case-logo-2.png';  import logo3 from '../assets/case-logo-3.png'; import logo4 from '../assets/case-logo-4.png';
import logo5 from '../assets/case-logo-5.png'; import logo6 from '../assets/case-logo-6.png'; import logo7 from '../assets/case-logo-7.png'; 
import logo8 from '../assets/case-logo-8.png'; import logo9 from '../assets/case-logo-9.png'; import logo10 from '../assets/case-logo-10.png'; 
import logo11 from '../assets/case-logo-11.png'; import logo12 from '../assets/case-logo-12.png'; import logo13 from '../assets/case-logo-13.png'; 
import logo14 from '../assets/case-logo-14.png'; import logo15 from '../assets/case-logo-15.png'; import logo16 from '../assets/case-logo-16.png'; 
import logo17 from '../assets/case-logo-17.png'; import logo18 from '../assets/case-logo-18.png'; import logo19 from '../assets/case-logo-19.png'; 
import logo20 from '../assets/case-logo-20.png'; import logo21 from '../assets/case-logo-21.png'; import logo22 from '../assets/case-logo-22.png'; 
import logo23 from '../assets/case-logo-23.png'; import logo24 from '../assets/case-logo-24.png'; import logo25 from '../assets/case-logo-25.png'; 
import logo26 from '../assets/case-logo-26.png'; import logo27 from '../assets/case-logo-27.png'; import logo28 from '../assets/case-logo-28.png'; 
import logo29 from '../assets/case-logo-29.png'; import logo30 from '../assets/case-logo-30.png'; import logo31 from '../assets/case-logo-31.png'; 
import logo32 from '../assets/case-logo-32.png'; import logo33 from '../assets/case-logo-33.png'; import logo34 from '../assets/case-logo-34.png'; 
import logo35 from '../assets/case-logo-35.png'; import logo36 from '../assets/case-logo-36.png'; import logo37 from '../assets/case-logo-37.png'; 
import logo38 from '../assets/case-logo-38.png'; import logo39 from '../assets/case-logo-39.png'; import logo40 from '../assets/case-logo-40.png'; 
import logo41 from '../assets/case-logo-41.png'; import logo42 from '../assets/case-logo-42.png'; import logo43 from '../assets/case-logo-43.png'; 
import logo44 from '../assets/case-logo-44.png'; import logo45 from '../assets/case-logo-45.png'; import logo46 from '../assets/case-logo-46.png'; 
import logo47 from '../assets/case-logo-47.png'; import logo48 from '../assets/case-logo-48.png'; import logo49 from '../assets/case-logo-49.png';
import logo50 from '../assets/case-logo-50.png'; import logo51 from '../assets/case-logo-51.png'; import logo52 from '../assets/case-logo-52.png';
import logo53 from '../assets/case-logo-53.png'; import logo54 from '../assets/case-logo-54.png'; 

import { get, imageFile } from '../plugins/request.js'

export default {
  name: 'HCaseszh',
  components: {
    HNews
  },
  data() {
    return {
      // msg: {
      //   title: 'Customer case',
      //   modified: 'CUSTOMER CASES',
      //   subTitle: 'More than 5,000 successful cases, worthy of customer trust'
      // },
      cases: Cases,
      items: [logo1,logo2,logo3,logo4,logo5,logo6,logo7,logo8,logo9,logo10,logo11,logo12,logo13,logo14,logo15,logo16,logo17,logo18,
          logo19,logo20,logo21,logo22,logo23,logo24,logo25,logo26,logo27,logo28,logo29,logo30,logo31,logo32,logo33,logo34,logo35,logo36,
          logo37,logo38,logo39,logo40,logo41,logo42,logo43,logo44,logo45,logo46,logo47,logo48,logo49,logo50,logo51,logo52,logo53,logo54
      ]
    }
  }
}
</script>

<style lang="scss">
.HCasesNew{
  text-align: center;
  margin: 2rem 0;
  .items{
    display: flex;
    flex-wrap:nowrap;
    padding: 0.5rem 0;
    .first{
      p{
        font-size: 0.9rem;
        font-weight: 600;
        margin-bottom: 1rem;
        span{
          color: #00a2e4;
        }
      }
      .logo-content{
        li{
          display: inline-block;
          width: 6.75rem;
          height: 2.5rem;
          border-radius: 4px;
          overflow: hidden;
          background: #fff;
          box-shadow: 0 0 2px #dcdcdc;
          margin:0.25rem;
          padding: 5px 10px;
          img{
            width: 100%;
            height: auto;
            image-rendering: -webkit-optimize-contrast;
            -ms-interpolation-mode: bicubic;
            object-fit: cover;
          }
        }
      }
    }
  }
}

</style>