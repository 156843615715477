<template>
  <div class="pitemrow23">
    <div class="pitemrow23-item">
      <div class="content">
        <p class="title1">文件档案管理系统</p>
        <p class="title2">文件档案管理系统既可以自成系统，为用户提供完整的文件档案管理和网络查询功能</p>
        <ul>
          <li><span class="management-icon" style="background-position:0 -76px"></span><p>文件管理</p></li>
          <li><span class="management-icon" style="background-position:-38px -76px"></span><p>档案收集</p></li>
          <li><span class="management-icon" style="background-position:-76px -76px"></span><p>档案整理</p></li>
          <li><span class="management-icon" style="background-position:-114px -76px"></span><p>档案设置</p></li>
          <li><span class="management-icon" style="background-position:-152px -76px"></span><p>档案鉴定</p></li>
          <li><span class="management-icon" style="background-position:0 -114px"></span><p>文件借阅</p></li>
          <li><span class="management-icon" style="background-position:-38px -114px"></span><p>开发利用</p></li>
          <li><span class="management-icon" style="background-position:-76px -114px"></span><p>全文检索</p></li>
          <li><span class="management-icon" style="background-position:-114px -114px"></span><p>在线预览</p></li>
          <li><span class="management-icon" style="background-position:-152px -114px"></span><p>协同编辑</p></li>
        </ul>
        <el-button type="primary" style="margin-top:1.5rem" @click="openConsultBox">免费试用</el-button>
      </div>
      <div class="imgright">
        <img :src="aimg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import TitleBar from './TitleBar.vue'

import arrow from '../assets/arrow-right.png'
import a from '../assets/软件产品-pow2.png'
import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'
import axios from "axios"

export default {
  name: 'PItemRow2',
  data() {
    return {
      msg:{
        title: '软件产品',
        subTitle: '资产 | 设备 | 文件档案 | eGMP | 智慧实验室'
      },
      aimg:a,
      arrow: arrow,
      item3:''
    }
  },
  components: {
    TitleBar
  },
  methods: {
    openConsultBox(){
      window.$crisp.push(['do', 'chat:open']);
    },
  },
  created: function(){
    
      
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      
    }
  }
}
</script>

<style lang="scss">
.pitemrow23 {
  max-width: 1440px;
  margin: 2rem auto;
  .pitemrow23-item{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    max-width: 1440px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .content{
    width: 20.6rem;
    margin-top: 5rem;
    .title1{
      font-size: 1.6rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }
    .title2{
      font-size: 0.8rem;
      margin-bottom: 2.5rem;
      color:#666666;
    }
    ul{
      width: 18rem;
      li{
        display: inline-block;
        text-align: center;
        margin-right: 0.8rem;
        margin-bottom:1rem;
        font-size: 0.7rem;
        .management-icon{
          display: inline-block;
          width: 38px;
          height: 38px;
          background-image: url(../assets/projecticons.png);
          background-size: 500%;
          background-position:0 0;
          border: 0;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  .imgright{
    margin-top: 2rem;
  }
}
</style>