<template>
  <div class="hservice row-container">
    <TitleBar :msg="msg"/>
    <ul class="items">
      <li v-for="item in items" :key="item.index"> 
        <img :src="item.icon" alt=""> 
        <div class="text">
          <h3 class="title">{{ item.name }}</h3>
         <p class="description">{{ item.content }}</p>
        </div>
      </li>
    </ul>
    <div class="text2 padding-top20">
      <p>{{description1}}</p>
      <p>{{description2}}</p>
    </div>
  </div>
</template>

<script>
import TitleBar from './TitleBar.vue'

import bg1 from '../assets/service-1.png'
import bg2 from '../assets/service-2.png'
import bg3 from '../assets/service-3.png'
import bg4 from '../assets/service-4.png'

import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

export default {
  name: 'PItemRow4',
  data() {
    return {
      msg: {
        title: 'service advantage',
        subTitle: 'Enjoy professional customization, technology creates value'
      },
      description1: '',
      description2: '',
      items: [
        { image: bg1, title: 'Experience Ten years of software customization development experience', description: 'Professional customized management software, including customer management, project management, ERP, equipment management, inventory management, education and training, logistics systems, production management, sales management, personnel management, OA office, asset management, etc.' },
        { image: bg2, title: 'Deep customization Meet various customized needs of customers', description: 'Professional customization of various website systems, such as corporate official websites, e-government, industry portals, e-commerce, Internet finance, C2C, B2C, O2O, online education, online transactions, online payments, etc.' },
        { image: bg3, title: 'Leading technology Chenke full business management platform', description: 'Professional custom IOS, Android mobile Internet applications, iPhone, iPad, Android phones, Android tablets, APP native development, Html5 development, mobile portals, etc.' },
        { image: bg4, title: 'professional team Senior expert localization service', description: 'Professional custom WeChat public account, enterprise account, subscription account, WeChat website development, WeChat mall, WeChat push, WeChat payment, WeChat marketing, WeChat and internal system docking, etc.' },
      ]
    }
  },
  components: {
    TitleBar
  },
  methods: {
    getData(params) {
      const self = this;
      get('/service.jsp', params).then(function(res){
        const data0 = res.data.data;
        self.items = data0.map(function(item){
          item.icon = imageFile(item.logo)
          return item
        })
      })
    },
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.msg.title = title['hTitle12']
        self.msg.subTitle = '助力数字化转型，驱动业务创新'
        self.description1 = title['advantagesD1']
        self.description2 = title['advantagesD2']
      })
    }
  },
  created: function(){
    const id = this.$store.state.currentLanguage.id
    this.getData({langtype: id})
    this.getValue({lang: id, value: 'hTitle12;hTitle14;advantagesD2;advantagesD1;'})
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      this.getData({langtype: id})
      this.getValue({lang: id, value: 'hTitle12;hTitle14;advantagesD2;advantagesD1;'})
    }
  },
}
</script>

<style lang="scss">
.hservice {
  text-align: center;
  padding-bottom: 2rem;
  .items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: space-between;
     -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    padding: 0 8rem;
    li {
      flex: 0 0 25%;
      text-align: left;
      margin-bottom: 2rem;
      margin-top: 0.75rem;
      border-radius: 0.5rem;
      box-sizing: border-box;
      text-align: center;
      img{
        width: 7.5rem;
      }
      .text{
        font-size: 0.75rem;
        .title {
          font-weight: normal;
          text-transform: uppercase;
          margin: 0.5rem 0;
        }
        .description {
          opacity: 0.6;
          hyphens:auto; //英文换行加-
          text-align: justify;
          text-align: center;
        }
      }
    }
  }
  .text2 {
    font-size: 0.8rem;
    line-height: 2;
  }
}
</style>