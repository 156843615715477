<template>
<div class="contact row-container">
  <TitleBarOnlyText :msg="msg" />
  <ContactZh v-if="$store.state.currentLanguage.id==0" />
  <ContactEn v-else />
</div>
</template>
<script>
import TitleBarOnlyText from '../components/TitleBarOnlyText.vue'
import { AREA } from '../plugins/address.js'
import ContactEn from './ContactEn.vue'
import ContactZh from './ContactZh.vue'
import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

export default {
  name: 'Contact',
  components: {
    ContactZh,
    ContactEn,
    TitleBarOnlyText
  },
  data() {
    return {
      msg: {
        title: 'contact us',
        subtitle: 'Senior expert team, provide localized professional services'
      },
      area: AREA
    }
  },
  methods: {
     getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.msg.title = title['cTitle2']
        self.msg.subtitle = title['cTitle1']
       
      })
    },
  },
  created: function(){
    const id = this.$store.state.currentLanguage.id
    this.getValue({lang: id, value: 'cTitle1;cTitle2;'})
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      this.getValue({lang: id, value: 'cTitle1;cTitle2;'})
    }
  },
}
</script>
<style lang="scss">
.contact {
  .area {
    margin-bottom: 2rem;
    
    p {
      font-size: 0.9rem;
      padding: 1rem 0.5rem;
    }
    .table {
      border: 0.05rem solid #EBEEF5;
      border-radius: 0.2rem;
      padding: 1rem 2rem;
      &::before {
        background-color: transparent !important;
      }
      
    }

  }
}
</style>