<template>
  <div class="form-get-demo-en">
    <h1>Request a demo</h1>
    <p>Please fill out your information below and our customer representative will contact you shortly.</p>
    <div class="demo-form">
      <el-form ref="form" :model="form" label-width="80px" label-position="top" >
        <div>
          <el-form-item label="First name" class="bitian"  style="width: 48%; display: inline-block;margin-right:4%">
            <el-input v-model="form.firstName"></el-input>
          </el-form-item>
          <el-form-item label="Last name" class="bitian"  style="width: 48%; display: inline-block">
            <el-input v-model="form.lastName"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="Email" class="bitian" >
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="Country" class="bitian" >
          <el-input v-model="form.country"></el-input>
        </el-form-item>
        <el-form-item label="Company Name" class="bitian" >
          <el-input v-model="form.companyName"></el-input>
        </el-form-item>
        <el-form-item label="Phone" class="bitian" >
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="Comment" >
          <el-input type="textarea" v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item  >
          <el-button type="primary" @click="onSubmit">Submit</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
  import axios from "axios"

  export default {
    name: 'GetMyDemoEn',
    data() {
      return {
        form: {
          firstName:'',
          lastName: '',
          email:'',
          phone:'',
          country:'',
          companyName: '',
          desc: '',
          isPass: false,
          tip: 'Can\'t be empty'
        },
      }
    },
    methods: {
      check() {
        
        const form = this.form;
       
        if(form.firstName.replace(/\s*/g, "") ==''){  //不为空 
          form.isPass = false
          form.tip = '"First name" can\'t be empty'
          return
        }else if(form.lastName.replace(/\s*/g, "") ==''){
          form.isPass = false
          form.tip = '"Last name" can\'t be empty'
          return
        }else if(!/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(form.email)){
          form.isPass = false
          form.tip = '"Email" is not right'
          return
        }else if(form.country.replace(/\s*/g, "") ==''){
          form.isPass = false
          form.tip = '"Country" can\'t be empty'
          return
        }else if(form.companyName.replace(/\s*/g, "") ==''){
          form.isPass = false
          form.tip = '"companyName" can\'t be empty'
          return
        }else if(form.phone.replace(/\s*/g, "") ==''){
          form.isPass = false
          form.tip = '"Phone" can\'t be empty'
          return
        }else{
          form.isPass = true
        }
        
      },
      onSubmit() {
        const form = this.form;
        const self = this;
        this.check()
        if(!this.form.isPass){
          this.$notify({
            title: 'prompt',
            message: this.form.tip,
            type: 'warning'
          });
        }else{
          //console.log('提交')
          
          axios.get('https://hz.chenksoft.com/ckapi/api/1/v2/add_customer_information.jsp',{
            params: {
              token: "chenksoft!@!",
              customer_name: form.firstName+' '+form.lastName,
              cellphone_number: form.phone,
              city: form.country,
              corporate_number: form.companyName,
              email: form.email,
              note: form.desc,
            }
          }).then(function(response){
            
            if(response.data.code==0){
              self.$notify({
                title: 'success',
                message: 'You have submitted successfully!',
                type: 'success'
              });
              window.location.href = "http://saas.chenksoft.com/"
            }else{
              self.$notify.error({
                title: 'failed',
                message: 'Application failed, please try again later!',
              });
            }
            //清空
            self.form.firstName = ''
            self.form.lastName = ''
            self.form.phone = ''
            self.form.email = ''
            self.form.companyName = ''
            self.form.country = ''
            self.form.desc = ''
          })
        }
      }
    }
  }
</script>
<style lang="scss">
.form-get-demo-en{
  padding: 2rem 0;
}
</style>