import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import 'default-passive-events'  
 
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

import './plugins/element.js'
import 'element-ui/lib/theme-chalk/index.css';
import './media.scss';

import wcSwiper from 'wc-swiper'
import 'wc-swiper/style.css'
Vue.use(wcSwiper);


import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'
import VueVideoPlayer from 'vue-video-player'
Vue.use(VueVideoPlayer)

import { addFontsize }from './plugins/isMobile'

addFontsize()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

/*const router = new Router({
  routes: routes
})
*/

// 加入百度统计
/*router.beforeEach((to, from, next) => {
  if (window._hmt) {
    if (to.fullPath) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next()
})*/

