<template>
  <div class="pitemrow24">
    <div class="content">
      <ul>
        <li class="width25">
          <div class="title"> <span class="icons-1" style="background-position:0 0"></span> DRAMS</div>
          <ul>
            <li>文件管理</li>
            <li>在线编辑</li>
            <li>文件审批</li>
            <li>记录管理</li>
            <li>档案创建</li>
            <li>档案整理</li>
            <li>档案保管</li>
            <li>档案鉴定</li>
            <li>全文检索</li>
            <li>开发利用</li>
            <li>...</li>
          </ul>
        </li>
        <li class="width25">
          <div class="title"> <span class="icons-1" style="background-position:-48px 0"></span> TMS</div>
          <ul>
            <li>课件管理</li>
            <li>讲师管理</li>
            <li>试卷管理</li>
            <li>培训矩阵</li>
            <li>培训计划</li>
            <li>岗位培训</li>
            <li>过程管理</li>
            <li>在线考试</li>
            <li>培训报表</li>
          </ul>
        </li>
        <li class="width25">
          <div class="title"> <span class="icons-1" style="background-position:-96px 0"></span> QMS</div>
          <ul>
            <li>事件管理</li>
            <li>CAPA管理</li>
            <li>偏差管理</li>
            <li>变更管理</li>
            <li>验证管理</li>
            <li>投诉管理</li>
            <li>供应商管理</li>
            <li>审计管理</li>
          </ul>
        </li>
        <li class="width50">
          <div class="text">
            <p class="title1">eGMP管理系统</p>
            <p class="title2">各个环节依据权限控制功能，文件、记录、档案、库房线上线下一体化</p>
          </div>
          <ul>
            <li class="dot"> DRAMS</li>
            <li class="dot">TMS</li>
            <li class="dot">QMS</li>
          </ul>
          <el-button type="primary" style="margin-top:1.5rem"  @click="openConsultBox">了解更多</el-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import TitleBar from './TitleBar.vue'

import arrow from '../assets/arrow-right.png'
import a from '../assets/软件产品-pow3.png'
import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'
import axios from "axios"

export default {
  name: 'PItemRow2',
  data() {
    return {
      msg:{
        title: '软件产品',
        subTitle: '资产 | 设备 | 文件档案 | eGMP | 智慧实验室'
      },
      aimg:a,
      arrow: arrow,
      item3:''
    }
  },
  components: {
    TitleBar
  },
  methods: {
    openConsultBox(){
      window.$crisp.push(['do', 'chat:open']);
    },
  },
  created: function(){
    
      
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      
    }
  }
}
</script>

<style lang="scss">
.pitemrow24 {
  margin: 0 auto;
  background-color: #f9f9fb;
  width: 100%;
  .content{
    max-width: 72rem;
    margin: 0 auto;
    &>ul{
      width: 100%;
      display: flex;
      color: #fff;
      font-size: 14px;
      line-height: 1.7;
      padding: 3rem 0;
      .text{
        .title1{
          font-size: 1.6rem;
          font-weight: 600;
          margin-top: 3rem;
          margin-bottom: 0.6rem;
        }
        .title2{
          margin-bottom: 1rem;
          font-size: 0.8rem;
          width: 20rem;
        }
        
      }
      &>li{
        width: 20%;
        height: 22.5rem;
        border-radius: 18px;
        display: inline-block;
        text-align: left;
        margin: 1rem 0.5rem;
        font-size: 0.9rem;
        box-sizing: border-box;
        padding: 2rem;
        &>ul>li{
          font-size: 0.7rem;
        }
        .dot{
          display: inline-block;
          font-size: 0.75rem;
          margin-right: 1.5rem;
        }
        .title{
          font-size: 1.6rem;
          font-weight: 600;
          margin-bottom: 1rem;
          span{
            vertical-align: text-bottom;
            display: inline-block;
            width: 48px;
            height: 48px;
            background-image: url(../assets/projecticons4.png);
            background-size: 300%;
            background-position:0 0;
            border: 0;
          }
        }
        .dot::before{
            content: '';
            width: 0.5rem;
            height: 0.5rem;
            background: #00cca7;
            display: inline-block;
            border-radius: 50%;
            margin-right: 0.2rem;
          }
           .dot:nth-of-type(2)::before{
                background-color:#8668f0;
           }
           .dot:nth-of-type(3)::before{
                background-color:#00a2e4;
           }
      }
      &>li:nth-of-type(1){
        background-color:#00cca7;
      }
      &>li:nth-of-type(2){
        background-color:#8668f0;
      }
      &>li:nth-of-type(3){
        background-color:#00a2e4;
      }
      &>li:nth-of-type(4){
        width: 40%;
        background: url(../assets/软件产品-pow4.png);
        padding-left: 3rem;
        background-size: cover;
      }
    }
    
  }
  
}
</style>