<template>
  <div class="privacy">
    <div class="privacy">
			<h2>隐私政策</h2>
			<h5 class="H_5_l">生效日期：【 2015 】年【 4 】月【 07 】日</h5>
			<p>感谢您选择使用由杭州晨科软件技术有限公司（以下简称“晨科软件”）提供的互联网软件服务（以下简称“软件服务”）。本《隐私保护政策》（以下简称为“本政策”）讲述了当您使用软件服务时，我们收集、使用、保存、共享您的个人信息的目的、方式和范围。<span>我们十分重视您的隐私，在您使用软件服务前，请您务必仔细阅读本政策，重点内容我们已经以粗体字加下划线表示，希望您特别关注。一旦您开始使用本产品，即表示您同意我们按照本政策处理您的相关信息。</span>我们将按法律法规要求，并且尽商业上合理的一切努力保护在您使用软件服务过程中晨科软件收集的个人信息。您通过网络页面点击确认、实际购买或使用          所提供的软件服务等操作均表示您已阅读并充分理解本政策之内容，同意晨科软件按照本政策收集、使用、保存、共享您的个人信息，且认可本政策全部条款并同意受其约束。本政策将帮助您了解以下内容：</p>
			<p>一、我们如何收集和使用您的个人信息 <br>
				二、我们如何共享、转让、公开披露您的个人信息<br>
				三、我们如何存储您的个人信息<br>
				四、我们如何保护您的个人信息<br>
				五、我们如何使用&nbsp;Cookie&nbsp;和同类技术<br>
				六、我们如何处理未成年人的个人信息<br>
				七、您如何行使管理个人信息的权利<br>
				八、政策的修订与更新<br>
				九、如何联系我们</p>

			<p>需要特别说明的是，<span>本政策不适用于其他第三方向您提供的软件、产品或服务。</span> </p>
			<h3>一、我们如何收集和使用您的个人信息</h3>
			<p>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。我们仅会出于本政策所述的以下目的，收集和使用您的个人信息：<br>为了向您提供软件服务，维护软件服务的正常运行，改进及优化我们的服务体验并保障您的账号安全，我们会出于本政策下述目的及方式收集您在注册、使用软件服务时主动提供、授权提供或基于您使用软件服务时产生的信息。</p>
			<h3 class="h3_16 mt20">(一)为向您提供产品核心功能须收集和使用您个人信息的情形</h3>
			<p>为实现本产品的核心业务功能，我们可能需要向您收集个人信息。以下将详细列出本产品的核心业务功能及为实现该功能所需收集的个人信息，若您拒绝收集，则无法使用本产品。</p>
			<p class="text">1、账号信息：为便于我们为您提供服务，您需要提供基本注册或登录信息，包括手机号码、电子邮箱地址，并创建您的账号、用户名和密码。<br>
			</p><p class="text">2、在您主动注销账户之后，我们将停止为您提供服务，并根据适用法律的要求删除您的个人信息或做匿名化处理。</p>
			<p class="text">3、设备信息：我们会根据您在软件安装及使用中授予的具体权限，可能会接收并记录您所使用的设备属性信息（例如您的<span>设备型号、硬件型号、操作系统版本、设备配置、唯一设备标识符、</span>国际移动设备身份码IMEI、网络设备硬件地址MAC、广告标识符IDFA等），设备连接信息（浏览器的类型、电信运营商、使用的语言）以及设备状态信息（例如设备传感器数据，设备应用安装列表）。</p>
			<p class="text">4、软件信息：软件版本号、序列号、渠道号、邮箱域名、邮箱登录端口、登录协议或其他用于连接在线服务的软件相关数据。</p>
			<p class="text">5、日志信息：当您使用我们的网站或软件服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您对软件服务、相关网站的使用情况、IP地址、所访问服务的URL、浏览器的类型和使用的语言、下载、安装或使用移动应用和软件的信息、与通讯软件通讯的信息以及访问服务、相关网站的日期、时间、时长等。</p>
			<p class="text">6、支持信息：当您与我们联系，如寻求服务支持时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</p>
			<p class="text">7、支付信息：在您购买产品/服务进行支付时，我们可能会收集您的第三方支付账号（例如支付宝账号、微信账号、Apple Pay 账号或其他形式的银行卡信息），如果您开通了指纹支付或面部识别支付，需要使用您的指纹或面部信息进行验证，我们不会采集您的指纹或面部原图，您的指纹原图仅保存在您授权采集指纹的设备上。</p>
			<h3 class="h3_16 mt20">(二)我们从第三方间接获得您个人信息的情形</h3>
			<p class="text">1、我们可能从关联方、第三方合作伙伴获取您授权共享的相关信息。例如，我们可能从第三方获取您授权共享的账户信息（头像、昵称）并在您同意本政策后将您的第三方账户与您的服务账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们将在符合相关法律法规规定，并依据与关联方或第三方合作伙伴的约定、确信其提供的信息来源合法的前提下，收集并使用您的这些信息。</p>
			<p class="text">2、我们在向您提供业务功能或具体服务时，我们会按照本政策以及相应的产品服务协议的约定收集、使用、存储、对外提供及保护您的个人信息；如果需要超出本政策以及相应的产品服务协议约定收集您的个人信息的，我们会另行向您说明信息收集的范围与目的，并征得您的同意后方收集提供相应服务所必要的您的信息。&nbsp;</p>
			<h3 class="h3_16 mt20">(三)征得授权同意收集、使用您个人信息的例外</h3>
			<p class="text">您充分了解，根据相关法律法规的规定，<span>在以下情形中，我们收集、使用个人信息无需征得您的授权同意：</span></p>
			<p class="text">1、与国家安全、国防安全直接相关的；</p>
			<p class="text">2、与公共安全、公共卫生、重大公共利益直接相关的；</p>
			<p class="text">3、与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
			<p class="text">4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
			<p class="text">5、所收集的个人信息是您自行向社会公众公开的；</p>
			<p class="text">6、从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；</p>
			<p class="text">7、根据您的要求签订和履行合同所必需的；</p>
			<p class="text">8、用于维护本产品和相关服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
			<p class="text">9、法律法规规定的其他情形。</p>
			<h3 class="h3_16 mt20">(四)个人信息的使用规则</h3>
			<p class="text">1、我们会根据本政策的约定对所收集的个人信息进行使用。</p>
			<p class="text">2、您根据本政策所提供的或同意我们收集的您的个人信息，将在您使用本产品期间持续授权我们使用。在您注销账号时，我们停止使用并删除您的个人信息或进行匿名化处理。</p>
			<p class="text">3、<span>当我们将您的个人信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</span></p>
			<h3>二、我们如何共享、转让、公开披露您的个人信息</h3>
			<h3 class="h3_16 mt20">(一)共享</h3>
			<p class="text"><span>我们不会与任何公司、组织和个人分享您的个人信息，但以下情况除外：</span></p>
			<p class="text">1、我们不会与任何公司、组织和个人分享您的个人信息，但以下情况除外：</p>
			<p class="text">2、在法定情形下的共享：我们可能会根据法律法规规定、诉讼、仲裁解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息；</p>
			<p class="text">3、在您被他人投诉侵犯知识产权或其他合法权利时，需要向投诉人披露您的必要资料，以便进行投诉处理的；</p>
			<p class="text">4、只有共享您的信息，才能实现我们的产品功能或向您提供您需要的服务；</p>
			<p class="text">5、<span>与我们的关联公司共享：</span>您的个人信息可能会在我们的关联公司之间共享。我们只会共享必要的个人信息，且这种共享受到本政策所声明目的的约束。我们的关联公司如要改变个人信息的处理目的，将再次征求您的授权同意；</p>
			<p class="text">6、<span>授权合作伙伴共享：</span>仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以保障为您提供的服务顺利完成。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。同时，我们会与合作伙伴签署严格的保密协定，要求他们按照本政策以及其他任何相关的保密和安全措施来处理您的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。
			<br><b>目前，我们的授权合作伙伴包括以下类型：</b>
			<br>①　供应商、服务提供商和其他合作伙伴。我们将信息共享给支持我们产品功能的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、数据处理、开展营销活动、进行服务质量监控、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。
			<br>②　分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息（指可以识别您身份的信息，例如姓名或电子邮箱，通过这些信息可以联系到您或识别您的身份）与提供广告、分析服务的合作伙伴分享。我们会向这些合作伙伴提供有关其广告覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。
			</p>
			<h3 class="h3_16 mt20">(二)转让</h3>
			<p class="text">1、我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：事先获得您明确的同意或授权；</p>
			<p class="text">2、根据适用的法律法规、法律程序的要求、政府主管部门的强制性要求或司法裁决所必须的情况进行转让；</p>
			<p class="text">3、<span>在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。</span> </p>
			<h3 class="h3_16 mt20">(三)公开披露</h3>
			<p class="text">我们仅会在以下情况下，公开披露您的个人信息：</p>
			<p class="text">1、事先获得您明确的同意或授权；</p>
			<p class="text">2、根据适用的法律法规、法律程序的要求、政府主管部门的强制性要求或司法裁决所必须的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在上述情况发生时，我们会要求披露请求方必须出具与之相应的有效法律文件，并对被披露的信息采取符合法律和业界标准的安全防护措施。</p>
			<h3 class="h3_16 mt20">(四)共享、转让、公开披露个人信息时事先征得授权同意的例外</h3>
			<p class="text">根据相关法律法规的规定，<span>在以下情形中，我们可以在不征得您的授权同意的情况下共享、转让、公开披露您的个人信息：</span></p> 
			<p class="">
				（1）与国家安全、国防安全有关的；<br>
				（2）与公共安全、公共卫生、重大公共利益有关的；<br>
				（3）与犯罪侦查、起诉、审判和判决执行等有关的；<br>
				（4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br>
				（5）您自行向社会公众公开的个人信息；<br>
				（6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。<br>
				（7）根据您的要求签订和履行合同所必需的；<br>
				（8）&nbsp;用于维护本产品和相关服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；<br>
				（9）法律法规规定的其他情形。<br>
				（10）<span>根据法律规定，若我们对个人信息采取技术措施和其他必要措施进行去标识化处理，且确保数据接收方无法复原并重新识别个人信息主体的，对此类数据的共享、转让、公开披露将无需另行向您通知并征得您的同意。</span> 
			</p><br>


			<h3>三、我们如何存储您的个人信息</h3>
			<p>
				1、存储地点：按照法律法规的要求，我们将在中华人民共和国境内收集和产生的个人信息，存储在中华人民共和国境内。&nbsp;<br>
				2、存储时间：我们将只在您的帐户有效期内或者在向您提供产品相关服务和技术支持所需的期限内保留您的信息。我们还将在为遵守适用的法律义务、解决争议以及执行协议而必要的期限内保留您的个人信息。<br>
				3、对于超出期限的个人信息，我们会立即删除或做匿名化处理。
			</p>
			<h3>四、我们如何保护您的个人信息</h3> 
			<p>
				1、我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在传输敏感信息时，我们使用加密来保护您信息的安全；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。&nbsp;<br>
				2、我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。&nbsp;<br>
				3、<span>互联网并非绝对安全的环境，没有任何产品、服务或数据传输可以保证绝对安全。</span>如果不幸发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您；难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
			</p>
			<h3>五、我们如何使用Cookie和同类技术</h3>
			<h3 class="h3_16 mt20">（一）Cookie</h3>
			<p class="text">1、为帮助您获得更好的网站访问体验，我们会使用Cookie，例如Google Analytics和DoubleClick cookie，以存储和帮助跟踪关于您的信息。我们会在您的计算机或移动设备上存储名为 Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，网站能够存储互联网协议 (IP) 地址、浏览器类型、互联网服务供应商 (ISP)、引用/退出页面、操作系统、日期/时间戳和/或点击流数据、您的偏好等数据。使得您无需在每次访问时都要重新输入；同时，我们还可能利用上述技术获取的流量模式、访问模式等信息，来简化网站浏览、进行产品推荐以及帮助设计我们的网站以让您的体验更高效、更愉悦。</p>
			<p class="text">2、&nbsp;您可根据自己的偏好管理Cookie，您也可以清除计算机上保存的所有 Cookie。大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。</p>
			<p class="text">3、我们不会将Cookie&nbsp;用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。有关详情，请参见AboutCookies.org。</p>
			
			<h3 class="h3_16 mt20">（二）网站信标和其他技术</h3>
			<p class="text">1、网络信标是一种电子图像，可用于在您查看网页或电子邮件时识别您设备上的Cookie。网络信标可帮助我们衡量网站和广告的效果。例如，网络信标可以计算出通过特定推广链接访问我们网站的人数，或在查看特定页面后从我们的网站注册购买产品或服务的人数，也可以告诉我们用户查看网页的时间，并提供对网络信标所在页面的描述。网络信标还可以衡量我们通讯的效果，具体方法是计算出打开消息或根据消息采取行动的人数。网络信标会确定用户打开消息的时间以及转发消息的次数。</p>
			<p class="text">2、我们会在我们的网站、通讯、应用程序中使用网络信标。同样，在我们网站上显示内容或广告的第三方也可能会在他们的广告或通讯中放置网络信标以衡量广告和通讯的效果。</p>
			<p class="text">3、网络信标允许我们通过访问Cookie来识别用户。我们可能将通过网络信标收集的信息与已经从您那里收集的其他个人信息结合起来。我们使用所有这些信息来更好地调整我们与您的互动。</p>
			<p class="text">4、您可以通过拒绝Cookie来禁用某些网络信标。</p><br>
			<h3>六、我们如何处理未成年人的个人信息</h3>
			<p>
				1、我们主要面向成人提供产品和服务。如您为未成年人，我们要求您请您的父母或监护人仔细阅读本政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。我们非常重视对未成年人个人信息的保护。<br>
			    2、<span>如果您是年满14周岁的未成年人，在使用本产品前，请您务必在监护人的陪同下阅读本政策，并确保已征得您的监护人明确同意后，方可使用本产品并向我们提供您的个人信息。对于经监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、监护人明确同意或者保护未成年人所必要的情况下使用或披露此信息。</span><br>
			    3、<span>我们不会寻求收集未满&nbsp;14&nbsp;岁的未成年人的信息。未满14&nbsp;岁的用户不应通过本产品提供任何信息。如果我们获知未满14&nbsp;岁的未成年人通过本产品提交了个人信息，我们将会采取适当的步骤尽快删除该信息且不会将该信息用于任何目的。</span>	<br>
			</p>
			<h3>七、您如何行使管理个人信息的权利</h3>
			<h3 class="h3_16 mt20">(一)按照法律、法规及相关国家标准的要求，我们保障您对自己的个人信息行使以下权利：</h3>
			<p class="text">
				1、访问和更正您的个人信息<br>
				您有权访问您的个人信息，法律法规规定的特例情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：<br>
				（1）账户信息：如果您希望访问或编辑您的账户中的个人资料信息和支付信息、更改您的密码、添加安全信息或关闭您的账户等，您可通过访问我们的产品或服务的“账号设置”功能执行此类操作。<br>
				（2）如果您无法通过上述功能模块访问这些信息，您可以随时发送电子邮件至：info@chenksoft.com。我们将在30天内回复您的访问请求。<br>
				（3）对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，请发送电子邮件至：info@chenksoft.com。&nbsp;
			</p>
			<p class="text">
				2、删除您的个人信息<br>
				在以下情形中，您可以向我们提出删除个人信息的请求：<br>
				（1）如果我们处理个人信息的行为违反法律法规；<br>
				（2）如果我们收集、使用您的个人信息，却未征得您的同意；<br>
				（3）如果我们处理个人信息的行为违反了与您的约定；<br>
				（4）如果您不再使用我们的产品或服务，或您注销了账号；<br>
				（5）如果我们不再为您提供产品或服务。<br>
				（6）当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。&nbsp;<br>
				（7）若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
			</p>
			<p class="text">
				3、改变您授权同意的范围或撤回您的授权<br>
				每个业务功能需要一些基本的个人信息才能得以完成（见本政策第一条）。您可以通过删除信息、关闭特定功能、进行隐私设置等方式给予或撤回您的授权同意。<br><br>
				<span>但请您理解，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</span>
			</p>
			<p class="text">
				4、注销您的用户账户<br>
				您可以向我们提出注销账户的请求。我们将在验证您的身份并清理账户中的资产（如有）后，为您提供账号注销服务。 <span>您注销成功后，我们将停止为您提供产品或服务，并根据适用法律法规的要求删除您的个人信息或做匿名化处理。</span>
			</p>
			<h3 class="h3_16 mt20">(二)我们如何响应您的上述请求</h3>
			<p>
				除了以上方式外，您还可以通过“联系我们”中列出的方式向我们提出上述请求。&nbsp;为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们会在收到您的请求并验证您的身份后的三十天内处理您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份中信息）的请求，我们可能会予以拒绝。在以下情形中，按照法律法规要求，我们将无法响应您的请求：<br>
				（1）与国家安全、国防安全直接相关的； <br>
				（2）与公共安全、公共卫生、重大公共利益直接相关的； <br>
				（3）与犯罪侦查、起诉、审判和判决执行等直接相关的； <br>
				（4）有充分证据表明您存在主观恶意或滥用权利的； <br>
				（5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。<br> 
				（6）涉及商业秘密的。  
			</p>
			<h3>八、政策的修订与更新</h3>
			<p>我们保留根据业务调整、法律法规或政策的变化而不时更新或修改本政策的权利。未经您明确同意，我们不会削减您按照本政策所应享有的权利。如果我们对本政策作出任何重大变更，我们会在该等变更生效之前通过客户端推送通知、弹窗形式或者发送电子邮件等方式告知您，以便您能及时了解该等变更。若您不同意修改后的本政策，您有权并应立即停止使用本产品。</p>

			<p>本政策所指的重大变更包括但不限于：<br>
				(1)我们的产品或服务模式发生重大变化。如产品业务功能、处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；<br>
				(2)我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；<br>
				(3)您参与个人信息处理方面的权利及其行使方式发生重大变化；<br>
				(4)我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；<br>
				(5)个人信息安全影响评估报告表明存在高风险时。</p>

			<p>我们会将本政策的旧版本存档，供您查阅。</p>

			<h3>九、如何联系我们</h3>
			<p>
				如果您对本政策有任何疑问、意见或建议，可以通过以下方式与我们联系：<br>
				1、发送电子邮件至：info@chenksoft.com<br>
				2、我们的本部地址为：浙江省杭州市西湖区申花路789号剑桥公社F座1107室，您可发送信件至：浙江省杭州市西湖区申花路789号剑桥公社F座1107室，邮编310012。<br>
				我们将在收到您提出的疑问、意见或建议后及时处理您的问题。一般情况下，我们将在三十天内回复。
			</p>
			<p>附录：定义<br>
				“我们”，指杭州晨科软件技术有限公司<br>
				“您”，指我司产品的注册用户及收费服务的购买方<br>
			</p>
		</div>
  </div>
</template>

<script>
import { Crisp } from "crisp-sdk-web";
export default {
  name: 'Privacy',
	activated(){
      Crisp.configure("e126a0bf-12f1-4d90-b4b0-7c81f61e90f8")
      Crisp.chat.hide()
	}
}
</script>
<style lang="scss">
.privacy{
    padding-top: 20px;
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px 15px 0;
    text-align: left;
    h2{
      text-align: center;
      margin-top: 30px;
      padding-bottom: 30px;
      font-size: 36px;
      font-weight: 600;
    }
    .H_5_l {
      line-height: 30px;
      font-size: 16px;
      margin-bottom: 20px;
      margin-top: 30px;
    }
    p.text{
      text-indent: -28px;
      margin-bottom: 0;
      padding-left: 32px;
    }
    p{
     font-size: 16px;
     line-height: 32px;
     margin-bottom: 35px;
     span{
      font-weight: 600;
      text-decoration: underline;
     }
    }
    h3{
      font-size: 18px;
      line-height: 40px;
      font-weight: 600;
    }
    .h3_16{
      font-size: 16px;
    }
    .mt20{
      margin-top: 20px;
    }
}
</style>