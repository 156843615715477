<template>
  <div style="background:#f8f8f8;height:100vh;">
   <Header v-if="flag"/>
   <HeaderPc  v-else/>
   <GetMyDemo v-if="flag"/>
   <GetMyDemoPc v-else />
  </div>
</template>
<script>

import GetMyDemo  from '../components/GetADemo.vue'
import GetMyDemoPc  from '../components/GetADemoNew.vue'
import HeaderPc from '@/components/HeaderOnlylogo.vue'
import Header from '@/components/Header.vue'
import { isMobile2 } from '../plugins/isMobile.js'

export default {
  name: 'GetDemo',
  data(){
    return {
      flag: false
    }
  },
  components: {
    GetMyDemo,
    GetMyDemoPc,
    Header,
    HeaderPc,
  },
  created(){
    this.flag = isMobile2()
    console.log(this.flag)
  }
}
</script>