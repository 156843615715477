<template>
  <div :class="{'my-header':true, 'white-bg':isFixed, 'isFixed': isFixed}" ref="myHeader" >
    <div class="el-header row-container">
      <div v-if="$store.state.currentLanguage.id==0" class="header-box">
        <HeaderZh :msgVal="mobileMenu" @dropdrop4="dropMenu4" :msgFixed="isFixed"/>
      </div>
      <div v-else class="header-box">
        <HeaderEn :msgVal="mobileMenu" @dropdrop4="dropMenu4"/>
      </div>
      <div class="header-right">
        <div class="hotline hotline-desktop">
          <i class="el-icon-phone-outline el-icon"></i>
          <span class="call">400-699-0220</span>
        </div>
        <div class="language-mobile">
          <span class="el-dropdown-link"  @click="dropMenu2()">
            {{$store.state.currentLanguage.name}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <!-- <router-link :to="$store.state.currentLanguage.id==0?'/get-demo':'/en/get-demo'" class="get-demo-mobile">
            {{demo}}
          </router-link> -->
        </div>
        <div class="menu" @click="dropMenu()">
          <img src="../assets/menu-black.svg" alt="">
        </div>
        <!-- <el-dropdown class="language" @command="selectLanguage" >
          <el-button round>
            {{$store.state.currentLanguage.name}}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button> -->
          <!-- <span class="el-dropdown-link">
            {{$store.state.currentLanguage.name}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span> -->
          <!-- <el-dropdown-menu slot="dropdown">
            <router-link :to="item.path" v-for="item in $store.state.language" :key="item.id"><el-dropdown-item :command="item.id">{{ item.name}}</el-dropdown-item></router-link>
          </el-dropdown-menu>
        </el-dropdown> -->
        <router-link :to="$store.state.currentLanguage.id==0?'/get-demo':'/en/get-demo'" class="get-demo">
          <el-button type="primary" round style="margin-left:1rem;width:5rem">{{demo}}</el-button>
        </router-link>
      </div>
    </div>
    <!-- <PcHeader :msgVal="mobileMenu"/> -->
    <!-- <MDropMenu :msgVal="mobileMenu2" @dropdrop="dropMenu3"/> -->
    
  </div>
</template>

<script>
import { get, imageFile } from '../plugins/request.js'
import { mapMutations } from 'vuex'
import HeaderZh from './HeaderZh.vue'
import HeaderEn from './HeaderEn.vue'
import MDropMenu from './MDropMenu.vue'
import PcHeader from './PcHeader.vue'

import logo from '../assets/logo-w.png'

import logoen from '../assets/logo-e.png'
import getQueryString from '../plugins/getQueryString.js'

export default {
  name: 'Header',
  components: {
    HeaderEn, 
    HeaderZh,
    PcHeader,
    MDropMenu
  },
  data(){
    return {
      hotline: '',
      mobileMenu: false,
      mobileMenu2:false,
      isFixed: false,
      fixedBoxHeight:'',
      scroolI: 0,
      metaAndTitle :{
        title: {
          'zh':'助力数字化转型 驱动业务创新 | 晨科软件',
          'en':'Boost Digital Transformation, Drive Business Innovation | Chenksoft'
        },
        description: {
          'zh':'晨科软件以先进的平台开发技术为基础，凭借专业的本地化团队和十多年软件开发经验，根据客户个性化需求进行快速开发、敏捷定制，致力于通过“软件产品+定制服务”的业务模式，全方位满足客户需求，为客户的持续发展打造合身的业务系统，提供强有力的信息化支持。',
          'en':'Based on advanced platform development technology, with a professional localization team and more than ten years of software development experience, Chenksoft conducts rapid development and agile customization according to the individual needs of customers, and is committed to adopting the business model of "Software Products + Custom Services" , to meet the needs of customers in all aspects, to create a suitable business system for the continuous development of customers, and to provide strong informationization support.'
        },
        keywords: {
          'zh':'快速开发,敏捷定制,定制开发,软件开发,管理软件,管理系统,系统开发,信息系统,网站开发,软件外包,后台系统,二次开发,app开发,app定制,HTML5开发,网页开发,app外包',
          'en':'custom software development,custom software,software development company,custom software programming,software development firm,custom software design,custom software development services,custom software development company,software application development company,custom software solution,custom software company,custom software application development,tailored software,bespoke development'
        }
      },
     
    }
  },
  methods: {
    dropMenu(){
      this.mobileMenu =!this.mobileMenu
      this.mobileMenu2 = false
    },
    dropMenu4(key){
      this.mobileMenu = key
    },
    dropMenu3(key){
      this.mobileMenu2 = key
    },
    dropMenu2(key){
      this.mobileMenu2 =!this.mobileMenu2
      this.mobileMenu = false
    },
    selectLanguage(key) {
      const path = this.$route.path
      //console.log(path)
      this.$store.commit('changeLanguage', {id: key, path: path})
      const toPath = this.$store.state.currentLanguage.spath+path.replace(/^\/(en|cn)(\/)?/g, '\/')
      
      if( toPath.replace(/\/$/g,'') !== path.replace(/\/$/g,'')) {
        const language0 = sessionStorage.getItem('language')
        if(language0 == 0){
          document.querySelector('meta[name="keywords"]').setAttribute('content', this.metaAndTitle.keywords.zh)
          document.querySelector('meta[name="description"]').setAttribute('content', this.metaAndTitle.description.zh)
          document.title = this.metaAndTitle.title.zh
        }else {
          document.querySelector('meta[name="keywords"]').setAttribute('content', this.metaAndTitle.keywords.en)
          document.querySelector('meta[name="description"]').setAttribute('content', this.metaAndTitle.description.en)
          document.title = this.metaAndTitle.title.en
        }
      }
    },
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.hotline = title['hotline']
      })
    },
    getCss(element, attr){
      if(element.currentStyle){
          return element.currentStyle[attr];
      }else{
          return window.getComputedStyle(element,null)[attr];
      }
    },
    watchScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const offsetTop = this.fixedBoxHeight;
      //判断window滚动条上下滚动
      const scroll = scrollTop - this.scroolI
      this.scroolI = scrollTop;
      this.isFixed = scrollTop > offsetTop ? true : false;
    },
  },
  created: function(){
    const path = this.$route.path;
    //在首页的时候更新语言
    const toPath = this.$store.state.currentLanguage.spath+path.replace(/^\/(en|cn)(\/)?/g, '\/')
    // console.log(toPath, path)
    if( toPath.replace(/\/$/g,'') !== path.replace(/\/$/g,'')) {
      this.$router.push(toPath)
    }
    
    const language0 = sessionStorage.getItem('language')
    if(language0 == 0){
      document.querySelector('meta[name="keywords"]').setAttribute('content', this.metaAndTitle.keywords.zh)
      document.querySelector('meta[name="description"]').setAttribute('content', this.metaAndTitle.description.zh)
      document.title = this.metaAndTitle.title.zh
    }else if(language0 == 1){
      document.querySelector('meta[name="keywords"]').setAttribute('content', this.metaAndTitle.keywords.en)
      document.querySelector('meta[name="description"]').setAttribute('content', this.metaAndTitle.description.en)
      document.title = this.metaAndTitle.title.en
    }
    //console.log(path.match(/^\/(en)(\/)?/g))
    //判断浏览器的语言设置
    // var jsSrc =(navigator.language || navigator.browserLanguage).toLowerCase();
    // console.log(jsSrc)
    // if(path.match(/^\/(en)(\/)?/g)!=null){
    //   //console.log(path)
    //   this.$store.commit('changeLanguage', {id: 1, path: path})
    //   document.title = 'chenksoft'
    // }else {
      
    //   if(jsSrc.indexOf('zh')>=0){
    //   //this.$router.push('/');
    //     this.$store.commit('changeLanguage', {id: 0, path: path})
    //     document.title = '晨科软件'
    //   }else{
    //     this.$router.push('/en');
    //     this.$store.commit('changeLanguage', {id: 1, path: path})
    //     document.title = '晨科软件'
    //   }
    // }
    
    const id = this.$store.state.currentLanguage.id
    this.getValue({lang: id, value: 'hotline;'})
  },
  mounted() {
    //获取滚动条距离 如果滚动条
    const offsetTop = this.$refs.myHeader.offsetTop;
    const position = this.getCss(document.getElementsByClassName('my-header')[0], 'position')
    this.fixedBoxHeight = 10
    // console.log(this.screenWidth)
    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.watchScroll);
  },
  computed: {
    logo2: function(){
      if(this.$store.state.currentLanguage.id == 0){
        return logo
      }else {
        return logoen
      }
    },
    demo: function() {
      if(this.$store.state.currentLanguage.id == 0){
        return '免费试用'
      }else {
        return 'Demo'
      }
    }
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      this.getValue({lang: id, value: 'hotline;'})
      document.body.style.overflow='auto'
    },
    mobileMenu: function(){
      //console.log(this.mobileMenu)
      if(this.mobileMenu||this.mobileMenu2) {
        document.body.style.overflow='hidden'
      }else {
        document.body.style.overflow='auto'
      }
    },
    mobileMenu2: function(){
      if(this.mobileMenu||this.mobileMenu2) {
        document.body.style.overflow='hidden'
      }else {
        document.body.style.overflow='auto'
      }
    },
  },
}
</script>

<style lang="scss">
.my-header {
  width: 100%;
  // background: rgba(255, 255, 255, 0.05);
  position: absolute;
  top:0;
  z-index: 99;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  &.isFixed{
    position: fixed;
  }
  .header-box{
    float: left;
  }
   .el-header{
     height: 4rem;
     padding-top: 0.5rem;
     .el-menu{
      background:initial;
     }
   }
   .header-right{
      display: flex;
      align-items: center;
      margin-top:0.6rem;
      float: right;
      .menu{
        display: none;
        img{
          width: 1.4rem;
        }
      }
      .language-mobile{
        display: none;
        font-size: 0.8rem;
        .el-dropdown-link{
          margin: 0 0.5rem;
          display: inline-block;
          line-height: 1.6;
          height: 1.4rem;
        }
        .get-demo-mobile{
           margin: 0 1rem 0 0.5rem;
           line-height: 1.6;
           height: 1.4rem;
           display: inline-block;
           color:#235EB6;
        }
      }
      .hotline {
        float: right;
        font-size: 0.9rem;
        color: #fff;
        font-weight: bold;       
        .call{
          text-decoration: none;
        }
        .el-icon {
          font-size: 1.2rem;
          vertical-align: middle;
          margin-right: 0.5rem;
        }
        
      }
      .language {
          font-weight: normal;
          margin-left: 1rem;
          line-height: 1.7;
        }
   }
  

  .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
    background-color: initial;
    font-weight: bold;
  }
  .el-menu--horizontal>.el-menu-item.is-active{
    font-weight: bold;
  }
  .el-menu.el-menu--horizontal{
    border: none;
  }
  .el-submenu__title i{
    color: #fff;
  }
  .el-menu--horizontal>.el-menu-item.is-active{
    border: none;
    display: block;
    position: relative;
  }
  .el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    border: none;
  }
  .el-menu--horizontal>.el-submenu.is-active .el-submenu__title:after{
    content: "";
    display: inline-block;
    position: absolute;
    bottom: -11px;
    left: calc(50% - 15px);
    width: 1.5rem;
    height: 2px;
    background: #fff;
  }
  .el-menu--horizontal > .el-menu-item.is-active:after{
    content: "";
    display: inline-block;
    position: absolute;
    bottom: -11px;
    left: calc(50% - 15px);
    width: 1.5rem;
    height: 2px;
    background: #fff;
  }
}
.my-header.white-bg{
    background: rgba(255, 255, 255, 1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transition: background-color 1s;
    box-shadow: 0px 0px 32px rgba(2,80,158,0.14) ;
    .hotline{
      color: #2a2a2a;
      transition: color 1s;
    }
  }
  .my-header.white-bg .el-menu--horizontal>.el-submenu.is-active .el-submenu__title:after{
    background: #1f87e8;
    transition: color 1s;
  }
  .my-header.white-bg .el-menu--horizontal > .el-menu-item.is-active:after{
    background: #1f87e8;
    transition: color 1s;
  }
  .my-header.white-bg .el-menu--horizontal>.el-submenu .el-submenu__title{
    color: #2a2a2a !important;
    transition: color 1s;
  }
  .my-header.white-bg .el-submenu__title i{
    color: #2a2a2a !important;
    transition: color 1s;
  }
//下拉菜单修改
.el-dropdown-menu{
  font-family: Arial,"Noto Sans",sans-serif,"Microsoft YaHei","Helvetica Neue","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
   padding: 0 !important;
   padding-top: 0.5rem !important;
  a {
    width: 100%;
    display: inline-block;
    margin-bottom: 0.5rem;
    .el-dropdown-menu__item{
      padding: 0 0.5rem;
    }
  }
}
//手机端的
.nav-mobile-vertical{
    width: 100%;
    height: 100%;
    .menubox{
       background: #fff; 
        width: 65%;
      height: 100%;
       margin-bottom: 0;
      position: fixed;
      font-size: 0.8rem;
      top: 0;
      right: 0;
      z-index: 99;
       overflow-y: scroll; //fixed状态下滚动
      .menuClose{
        float: right;
        font-size: 24px;
        line-height: 48px;
        width: 48px;
        position: fixed;
        right: 0;
        text-align: center;
        z-index: 999;
        color: #555555;
        top: 0;
      }
    }
    .first-layer {
      margin-top: 3rem;
      a{
        width: 100%;
        display: inline-block;
        background: #fff;
      }
     
      li {
        line-height: 3.6;
        background: #fff;
        &.active{
          a{
            background: #ecf5ff;
          color: #2a7acc;
          font-weight: 600;
          }
          .el-icon-check {
            display: block;
          }
        }
        &:hover{
          background: #ecf5ff;
          color: #2a7acc;
          .layer-head {
            color: #444;
          }
          
        }
        .el-icon-check {
            display: none;
          }
        
        a {
          padding: 0 1rem;
          display: inline-block;
          cursor: pointer;
          width: 100%;
          height: 100%;
          text-decoration: none;
        }
        .layer-head {
          text-decoration: none;
          font-size: 0.8rem;
          cursor: pointer;
          background: #fff;
          padding: 0 1rem;
          -webkit-tap-highlight-color:rgba(0,0,0,0);
        }
        .el-icon {
          float: right;
          font-size: 0.9rem;
          font-weight: bold;
          vertical-align: middle;
          margin-top: 0.9rem;
          margin-right: 0.5rem;
        }
        
        .second-layer {
          li {
            height: 2.4rem;
            line-height: 2.4rem;
            font-size: 0.75rem;
            border-top: none;
            &.active{
              a{
                color: #2a7acc;
                font-weight: 600;
              }
            }
            a {
              padding: 0 1.5rem;
              cursor: pointer;
              opacity: 0.7;
              background: #fff;
              &:hover{
                color: #235EB6;
              }
              .el-icon {
                margin-top: 0.7rem;
              }
            }
          }
        }
      }
    }
    .maskin{
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

</style>