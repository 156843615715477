<template>
  <div class="footer">
    <div class="row-container ">
      <div  class="linkCh link" v-if="$store.state.currentLanguage.id == 0">
        <div class="right">
          <ul>
            <li><router-link to="/"> 首页 </router-link></li>
            <li><router-link to="/platform"> 技术平台 </router-link></li>
            <li><router-link to="/customize"> 软件定制</router-link></li>
            <li><router-link to="/about">关于我们</router-link></li>
            <li><router-link to="/contact"> 联系我们 </router-link></li>
            <li><router-link to="/cases"> 客户案例 </router-link></li>
          </ul>
          <div class="copyright">
            <p>©2015 杭州晨科软件技术有限公司 版权所有</p>
            <p><span class="href" @click="toRescue">浙ICP备09081089号-1</span> <span>公安备案号-33010602005139</span></p>
          </div>
        </div>
        <div class="call">
          <span>{{hotline}}</span>
          <div class="logo"><img src="../assets/logo-w.png" alt=""></div>
        </div>
      </div>
      <div class="link" v-else>
        <div class="right">
          <ul>
            <li><router-link to="/"> Home </router-link></li>
            <li><router-link to="/platform"> Platform </router-link></li>
            <li><router-link to="/customize"> Services</router-link></li>
            <li><router-link to="/about">About Us</router-link></li>
            <li><router-link to="/contact"> Contact Us </router-link></li>
            <li><router-link to="/cases"> Our Customers </router-link></li>
          </ul>
          <div class="copyright">
            <p>©2015 Hangzhou ChenkSoft Technology Co., Ltd. All Rights Reserved</p>
            <p><span class="href" @click="toRescue">Copyright (C) 2013. All Rights Reserved</span> <span>Public Security Record Number -33010602005139</span></p>
          </div>
        </div>
        <ul class="call">
          <li class="social-icon">
            <i class="el-icon-star-off el-icon"></i><span>Follow us</span>
            <a href="https://www.facebook.com/chenksoft" target="_blank" class="item"><img src="../assets/Social_facebook.png" alt=""></a>
            <a href="https://twitter.com/chenksoft" target="_blank" class="item"><img src="../assets/Social_Linkedin.png" alt=""></a>
            <a href="https://www.linkedin.com/company/72265600" target="_blank" class="item"><img src="../assets/Social_Twitter.png" alt=""></a>
          </li>
          <li>
            <i class="el-icon-phone-outline el-icon"></i>
            <span>{{hotline}}</span>
          </li>
          <li>
            <i class="el-icon-message el-icon"></i>
            <span>{{email}}</span>
          </li>
        </ul>
      </div>
      
    </div>
  </div>
</template>

<script>
import getQueryString from '../plugins/getQueryString.js'
import { get, imageFile } from '../plugins/request.js'
import { Crisp } from "crisp-sdk-web";

export default {
  name: 'Footer',
  mounted: function(){
    window.scrollTo(0, 0)
  },
  data(){
    return {
      hotline: '',
      email: '',
      flag: 1,
    }
  },
  activated(){
      Crisp.configure("e126a0bf-12f1-4d90-b4b0-7c81f61e90f8")
      Crisp.chat.show()
	},
  methods: {
    toRescue(){
      window.open('https://beian.miit.gov.cn/')
    },
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.hotline = title['hotline']
        self.email = title['email']
      })
    }
  },
  created: function(){
    const id = this.$store.state.currentLanguage.id
    this.getValue({lang: id, value: 'hotline;email;'})
    
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      this.getValue({lang: id, value: 'hotline;email;'})
    }
  },
}
</script>

<style lang="scss">
.footer {
  padding: 0 1rem;
  background: #1A202C;
  width: 100%;
  .row-container {
    width: 100%;
    color: #fff;
    padding: 2rem 0;
    .link {
      width: 100%;
      display: flex;
      line-height: 2rem;
      justify-content: space-between;
      a{
        color: #fff;
      }
      // text-transform: capitalize;
      .call{
        margin-right: 3rem;
        font-size: 0.9rem;
        text-align: center;
        .logo {
          margin-top: 1rem;
        }
        .el-icon-phone-outline,.el-icon-star-off{
          font-size: 1rem;
        }
        .el-icon {
          margin-right: 0.4rem;
        }
        .social-icon{
          .item{
            margin-left:10px;
          }
          text-align: left;
        }
      }
    }
    .linkCh{
      ul li a{
        padding-left:0.5rem;
      }
      li+li{
         margin-left: 0.5rem;
      }
    }
    .right {
      .copyright {
        margin-top: 1rem;
        p {
          line-height: 2;
          .href{
          margin-right: 1rem;
          color: #fff;
          text-decoration: underline;
          cursor: pointer;
          }
        }
        
      }
      ul {
            border-bottom:1px solid #6b6c72 ;
            li{
              display: inline-block;
              
              &:hover {
                text-decoration: underline;
              }
            }
            li+li{
              &::before{
                content: '';
                display: inline-block;
                width: 1px;
                height: 12px;
                background: #fff;
                margin: 0 0.5rem;
              }
            }
          }
        }
    }
    
}
</style>