<template>
<div class="p-product" >
  <!-- <TitleBarLeft :msg="msg"/> -->
  <!-- <el-tabs v-model="activeName" tab-position="left" type="card" @tab-click="handleClick">
    <el-tab-pane :label="title1" name="first">
      <PPIntroduction :title="title1"/>
    </el-tab-pane>
    <el-tab-pane :label="title2" name="second">
      <PPCharacter :leftTitle="title2"/>
    </el-tab-pane>
    <el-tab-pane :label="title3" name="third">
      <PPFunDetails :leftTitle="title3"/>
    </el-tab-pane>
    <el-tab-pane :label="title4" name="fourth" v-if="$route.params.id!=9">
      <PPScheme :leftTitle="title4"/>
    </el-tab-pane>
    <el-tab-pane :label="title5" name="fifth" v-if="$route.params.id!=4">
      <PPCases :leftTitle="title5"/>
    </el-tab-pane>
  </el-tabs> -->
  <div ref="boxFixed" class="product">
    <div :class=" isFixed ? 'tab-fixed tab-right':'tab-right'" >
      <div class="row-container" style="padding-left:5%;padding-right:5%;">
          <div class="title">
            {{ $store.state.systemName }}
          </div>
          <el-menu
            mode="horizontal" :default-active="activeIndex2"
        @select="handleSelect" class="p-menu" id="tab0" >
            <el-menu-item index="1" class="p-menu-item tab0-title">
            {{title1}}
            </el-menu-item>
            <el-menu-item index="2" class="p-menu-item tab0-title">
              {{title2}}
            </el-menu-item>
            <el-menu-item index="3" class="p-menu-item tab0-title">
              {{title3}}
            </el-menu-item>
            <el-menu-item index="4" class="p-menu-item tab0-title" v-show="$route.params.id!=9&&$route.params.id!=25">
              {{title4}}
            </el-menu-item>
              <el-menu-item index="5" class="p-menu-item tab0-title" v-show="$route.params.id!=4">
              {{title5}}
            </el-menu-item>
          </el-menu>
      </div>
      
    </div>
    <div class="row-container" style="padding-left:5%;padding-right:5%;">
      <div class="tab-content">
        <PPIntroduction :title="title1" id="content1"/>
        <PPCharacter :leftTitle="title2" id="content2"/>
        <PPFunDetails :leftTitle="title3" id="content3"/>
        <PPScheme :leftTitle="title4" id="content4" v-show="$route.params.id!=9&&$route.params.id!=25"/>
        <PPCases :leftTitle="title5" id="content5" v-show="$route.params.id!=4"/>
      </div>
    </div>
   
  </div>
 </div>
</template>
<script>
import TitleBarLeft from '../components/TitleBarLeft.vue'
import PPIntroduction from '../components/PPIntroduction.vue'
import PPCharacter from '../components/PPCharacter.vue'
import PPFunDetails from '../components/PPFunDetails.vue'
import PPScheme from '../components/PPScheme.vue'
import PPCases from '../components/PPCases.vue'

import img1 from '../assets/1.png'
import { get, imageFile } from '../plugins/request.js'

export default {
  name: 'PProduct',
  components: {
    TitleBarLeft,
    PPIntroduction,
    PPCharacter,
    PPFunDetails,
    PPScheme,
    PPCases
  },
  data() {
    return {
      title: '晨科客户管理系统',
      activeName: 'first',
      img: img1,
      items: [],
      isFixed: false,
      activeIndex2: '1',
      scroolI: 0,
      fixedBoxHeight:''
    }
  },
  mounted() {
    const offsetTop = this.$refs.boxFixed.offsetTop;
    const position = this.getCss(document.getElementsByClassName('my-header')[0], 'position')
    if(position=='fixed'){
      this.fixedBoxHeight = document.getElementsByClassName('banner')[0].offsetHeight+153
    }else{
      this.fixedBoxHeight = document.getElementsByClassName('banner')[0].offsetHeight+document.getElementsByClassName('my-header')[0].offsetHeight
    }
    //console.log(this.ffixedBoxHeight)
    window.addEventListener("scroll", this.watchScroll);
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.watchScroll);
  },
  methods: {
    getCss(element, attr){
        if(element.currentStyle){
            return element.currentStyle[attr];
        }else{
            return window.getComputedStyle(element,null)[attr];
        }
    },
    watchScroll(beforeScrollTop) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const offsetTop = this.fixedBoxHeight;
      //判断window滚动条上下滚动
      const scroll = scrollTop - this.scroolI
      this.scroolI = scrollTop;
     
      //console.log(offsetTop)
      this.isFixed = scrollTop > offsetTop ? true : false;
      const ele = document.getElementsByClassName('tab0-title')
      const tab0 = document.querySelector("#tab0")
      
      for(let i=0; i<ele.length; i++) {
        const offsetTop3 =  document.querySelector(`#content${i+1}`).offsetTop;
        //console.log(scrollTop,offsetTop3, offsetTop3-scrollTop)
        if((offsetTop3-scrollTop)< 300 && (offsetTop3-scrollTop)>0) {
          //console.log(offsetTop3-scrollTop)
          this.activeIndex2 = (i+1).toString()
          //横线滚动条 左右移动
          if(scroll<0){
            //console.log('U')
            if(i>1){
              tab0.scrollTo(ele[i-1].offsetLeft, 0)
            }
          }else{
            //console.log('D')
            tab0.scrollTo(ele[i].offsetLeft, 0)
          }
          return;
        }
      }
    },
    goScroll() {
      const offsetTopPoint = document.querySelector(`#content${this.activeIndex2}`).offsetTop;
      window.scrollTo(0, offsetTopPoint-300);
    },
    handleSelect(key, keyPath) {
      //console.log(key);
      this.activeIndex2 = key
      this.goScroll()
    },
    getData() {
      const id = this.$store.state.currentLanguage.id
      const params = this.$route.params.id;
      const self = this;
      get('/custom-made.jsp', {langtype: id, sId: params}).then(function(res){
        const data0 = res.data.data;
        self.title = data0[0]['title-1']
      })
    }
  },
  created: function(){
    console.log(this.$route.params.id)
    this.getData()
    this.activeName = 'first'
  },
  computed:{
    title1: function() {
      return this.$store.state.currentLanguage.id==0?'系统简介':'System Introduction'
    },
    title2: function() {
      return this.$store.state.currentLanguage.id==0?'系统特点':'System Characteristics'
    },
    title3: function() {
      return this.$store.state.currentLanguage.id==0?'功能详情':'Functional Details'
    },
    title4: function() {
      return this.$store.state.currentLanguage.id==0?'详细方案':'Detailed Scheme'
    },
    title5: function() {
      return this.$store.state.currentLanguage.id==0?'客户案例':'Customer Cases'
    }
  }
}
</script>
<style lang="scss">
.p-product {
  //padding: 0 0 40px 0;
  .title-p {
      color: #1F87E8;
      background: #ecf5ff;
      padding: 0.5rem 0;
      //margin-top: 8rem;
      &::before{
        content: '';
        display: inline-block;
        margin-right: 0.5rem;
        background-color: #1F87E8;
      }
    }
   .product{
      width: 100%;
   }
  .el-tabs__header{
    border: none !important;
    margin-right: 5rem !important;
  }
  .tab-right {
    background: #fff;
    z-index: 90;
    width: 100%;
    .title{
      font-size: 1.4rem;
      margin-bottom:1rem;
      margin-top: 1rem;
    }
    
    &.tab-fixed{
      position: fixed;
      top: 0
    }
    .el-menu--horizontal {
    font-family: Arial,"Noto Sans",sans-serif,"Microsoft YaHei","Helvetica Neue","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
      .el-menu-item{
        font-size: 0.75rem !important;
        -webkit-tap-highlight-color:rgba(0,0,0,0);
      }
    }

  }
  .tab-content{
    padding-top: 1rem;
    margin: 0 auto;
  }
}
</style>