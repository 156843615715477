<template>
<div class="row-container c-cases">
  <TitleBarOnlyText :msg="msg" />
  <CCaseZh  v-if="$store.state.currentLanguage.id==0" />
  <CCaseEn  v-else />
</div>
</template>
<script>
import TitleBarOnlyText from '../components/TitleBarOnlyText.vue'
import company from '../plugins/company.js'
import CCaseEn from './CCaseEn.vue'
import CCaseZh from './CCaseZh.vue'
import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

import logo1 from '../assets/case-阿斯.png';  
import logo2 from '../assets/case-月星.png';  import logo4 from '../assets/case-银江.png'; import logo5 from '../assets/case-浙江.png';
import logo6 from '../assets/case-浙江大学.png'; import logo7 from '../assets/case-知味观.png'; import logo8 from '../assets/case-bank.png';
import logo9 from '../assets/case1-宁波.png'; import logo10 from '../assets/case2-大搜车.png';
import logo11 from '../assets/case3-工业大学.png'; import logo12 from '../assets/case4-华大.png';
import logo13 from '../assets/case5-华东理工.png'; import logo14 from '../assets/case6-华东医药.png';
import logo15 from '../assets/case7-创维.png'; import logo16 from '../assets/case8-京.png';
import logo18 from '../assets/case10-中石化.png';import logo17 from '../assets/case9-二更.png';
import logo19 from '../assets/case12-中国铁建.png'; import logo20 from '../assets/case13-中国石油.png';
import logo21 from '../assets/case15-中国地质大学.png'; import logo22 from '../assets/case16-中国东方航空.png';
import logo23 from '../assets/case17-中储粮.png'; import logo24 from '../assets/case18-上海.png';
import logo25 from '../assets/case19-二所.png'; 

export default {
  name: 'CCase',
  components: {
    TitleBarOnlyText,
    CCaseEn,
    CCaseZh
  },
  data() {
    return {
      tabPosition: 'left',
      companys: company.deal,
      msg: {
        title: '',
        subtitle: ''
      },
      items: [
        { logo: logo1 },{ logo: logo4 },{ logo: logo5 },{ logo: logo19 },{ logo: logo20 },
        { logo: logo22 },{ logo: logo21 },{ logo: logo23 },{ logo: logo24 },{ logo: logo25 },
        { logo: logo6 },{ logo: logo7 },{ logo: logo8 },{ logo: logo9 },{ logo: logo10 },
        { logo: logo11 },{ logo: logo14 },{logo:logo17},{ logo: logo15 },
        { logo: logo16 },{ logo: logo18 },{ logo: logo2 },{ logo: logo12 },{ logo: logo13 }
      ]
    }
  },
  mounted: function() {
    console.log(company.deal)
  },
  methods: {
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.msg.title = title['clTitle1']
        self.msg.subtitle = title['clTitle2']
      })
    }
  },
  created: function(){
    const id = this.$store.state.currentLanguage.id
    this.getValue({lang: id, value: 'clTitle2;clTitle1;'})
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      this.getValue({lang: id, value: 'clTitle1;clTitle2;'})
    }
  }
}
</script>
<style lang="scss">
.c-cases{
  .el-card__body{
    padding: 0.5rem ;
  }
}

</style>