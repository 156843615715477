<template>
<div class="contact-zh row-container">
  <div style="width:100%;text-align:center;margin-bottom: 3rem;margin-top:-2rem;">
    <img src="../assets/关注微信公众号.jpg" alt="">
  </div>
  <div v-for="item in area" :key="item.index" class="area">
    <p>{{item.area}}</p>
    <el-table :data="item.list" style="width: 100%" class="table">
      <el-table-column
        prop="city"
        label="城市"
        width="200">
      </el-table-column>
      <el-table-column
        prop="address"
        label="地址"
        >
      </el-table-column>
      <el-table-column
        prop="tel"
        label="电话"
        width="280"
        >
      </el-table-column>
    </el-table>
  </div>
  
</div>
</template>
<script>
import TitleBarOnlyText from '../components/TitleBarOnlyText.vue'
import { AREA } from '../plugins/address.js'

export default {
  name: 'ContactZh',
  components: {
    TitleBarOnlyText
  },
  data() {
    return {
      msg: {
        title: 'contact us',
        subtitle: 'Senior expert team, provide localized professional services'
      },
      area: AREA
    }
  },
  mounted: function(){
    
  }
}
</script>
<style lang="scss">
.contact-zh {
  padding: 0;
  .area {
    margin-bottom: 4rem;
    
    p {
      font-size: 1.8rem;
      padding: 2rem 1rem;
    }
    .table {
      border: 0.1rem solid #EBEEF5;
      border-radius: 0.4rem;
      padding: 2rem 4rem;
      &::before {
        background-color: transparent !important;
      }
      
    }

  }
}
</style>