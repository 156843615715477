<template>
  <div class="titlebar-left">
    <h5 class="title">{{msg.title}}</h5>
    <p class="subtitle">{{msg.subTitle}}</p>
  </div>
</template>

<script>
export default {
  name: 'TitleBarLeft',
  props: {
    msg: Object
  }
}
</script>

<style lang="scss">
  .titlebar-left {
    padding: 3rem 0 4rem 0;
    text-align: left;
    .title {
      font-size: 1.4rem;
      font-weight: 400;
      text-transform: uppercase;
    }
    .modified {
      opacity: 0.6;
      position: relative;
      font-size: 0.7rem;
      margin: 0.25rem 0 2rem 0;
      //text-transform: uppercase;
      &::after{
        content: '';
        display: block;
        width: 1.5rem;
        height: 0.1rem;
        background: #aaa;
        position: absolute;
        left: 0;
        right: 0;
        top: 1rem;
        bottom: 0;
        margin: 1rem auto;
      }
    }
    .subtitle {
      font-size: 0.8rem;
        opacity: 0.6;
      }
  }
</style>