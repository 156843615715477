<template>
  <div>
    <Header />
    <Banner />
    <AboutUs />
    <div style="position:fixed;left: 10px; top: 545px;z-index: 99;" class="code-left-fixed">
      <img src="../assets/微信公众号.png" alt="" style="width:100px;box-shadow: 0 3px 6px 0 rgba(0,0,0,.1);">
    </div>
    <Footer />
  </div>
</template>
<script>
import Banner from '../components/Banner.vue'
import Footer from '../components/Footer.vue'
import AboutUs from '../components/AboutUs.vue'
import Header from '@/components/Header.vue'

export default {
  name: 'About',
  components: {
    Banner,
    Footer,
    AboutUs,
    Header
  }
}
</script>