<template>
  <div class="news-wx row-container">
    <h1 class="title">新闻资讯</h1>
    <div class="news-box">
      <ul class="news-wxlist">
        <li v-for="(item, index) in items" :key="index">
            <div class="date-box">
              <p class="news-day">{{item.date.d}}</p>
              <p class="news-date">{{item.date.ym}}</p>
            </div>
            <a @click="gotowx(item.url)" target="_blank" class="new-content">
              <span class="news-title">{{item.title}}</span>
              <p style="color: #666666; line-height:1.6;">{{item.desc}}</p>
          </a>
        </li>
      </ul>
      <el-pagination class="pagination-desktop" @current-change="getCurrentPage" v-show="total>1"
  background
  layout="prev, pager, next"
  :page-size="10"
  :total="total">
</el-pagination>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'Newslist',
  data(){
    return {
      total: 0,
      currentPage: 1,
      items: [],
      flag: true,
      i: 0,
    }
  },
  methods: {
     getData(){
      
      const self = this;
      axios.get('https://sh.chenksoft.com/mixkit/website/getarticle', {
        params:{
          offset: 0,
          count: 10,
        }
      }).then(function(res){
        //const data0 = res.data.dataEx;
       
        self.items = res.data.dataEx
        var length = res.data.dataEx.length
        var time = ''
        var htmlStr = ''
        var a;
        //console.log(res.data.dataEx)
        for(var i=0; i<length; i++){
          self.items[i].url = res.data.dataEx[i]['content']['news_item'][0]['url']
          self.items[i].title = res.data.dataEx[i]['content']['news_item'][0]['title']
          htmlStr = res.data.dataEx[i]['content']['news_item'][0]['content']
          a = Math.ceil(Math.random()*10+100)
          self.items[i].desc = self.getHtmlPlainText(htmlStr).substr(0, a)+'...'
          time = res.data.dataEx[i]['content']['update_time']
          self.items[i].date = self.timestampToTime(time)
        }
      })
    },
    gotowx(url){
      window.open(url)
    },
    timestampToTime(timestamp){
      //时间戳为10位需乘以1000，时间戳为13不需要
      var date = new Date(timestamp * 1000)
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1): date.getMonth() + 1);
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return {'ym':Y + M, 'd':D};
    },
    getHtmlPlainText(html_str) {
      //提取字符串中的文字
      let re = new RegExp('<[^<>]+>', 'g')
      let text = html_str.replace(re, '').replace(/&nbsp;*/g, '')
      //或
      //var text = html_str.replace(/<[^<>]+>/g, "");
      return text
    },
    getTotal(){
      const self = this;
      //console.log(this.flag)
      if(this.flag && this.i<10){
        axios.get('https://sh.chenksoft.com/mixkit/website/getarticle', {
          params:{
            offset: self.i*100,
            count: 100,
          }
        }).then(function(res){
          const data0 = res.data.dataEx;
          var length = res.data.dataEx.length
          var yushu = (self.total +length)%10 ? 1 :0
          self.total = Math.floor((self.total +length)/10) +yushu
          //console.log(length)
          //console.log('页数：'+self.total+'; 循环变量i='+ self.i+';')
          self.i++;
          if(length<1){
            console.log('退出循环')
            self.flag = false
          }
          self.getTotal()
        })
      }
    },
    goToPage(page){
      this.$router.push(`/news/${page}`)
    },
  
    getCurrentPage(page){
      this.currentPage = page
      //this.$router.push(`/news/${page}`)
      this.getData()
      window.scrollTo(0, 0);
    }
  },
  created(){
    this.getData()
    this.getTotal()
  }
}
</script>
<style lang="scss">
.news-wx{
  
  .title{
    width: 85%;
    font-size: 1.2rem;
    font-weight: normal;
    margin: 1.5rem auto;
    color: #235EB6;
  }
  .news-wxlist{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 0.7rem;
    margin-top: 0.5rem;
    margin-bottom: 3rem;
    li{
      width: 100%;
      padding: 0.5rem 0 1rem 0;
      margin: 0.5rem 0.5rem 0 0.5rem;
      line-height: 2;
      text-align: left;
      border-bottom: 1px dotted #dedede;
      span:last-child{
        float: right;
        opacity: 0.8;
      }
      a{
        display: inline-block;
        width: 100%;
      }
      a:hover{
        color: #1F87E8;
      }
      .new-content{
        float:left;
        vertical-align: sub;
        width:85%;
        padding-left: 24px;
      }
      .news-title{
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        font-size: 18px;
      }
      .news-date{
        text-align: center;
        color: #666;
        line-height: 1;
      }
      .news-day{
        text-align: center;
        color: #235EB6;
        font-size: 24px;
        font-weight: 600;
      }
      .date-box{
        background: #f7f8fb;
        float: left;
        width: 80px;
        height:80px;
        margin-top: 8px;
      }
    }
  }
  .news-box{
    margin: 0 auto;
    color: #235EB6;
    .el-pagination{
      text-align: center;
    }
    .pagination-mobile{
      display: none;
    }
  }
  .el-pagination.is-background.el-pagination--small .el-pager li,
  .el-pagination.is-background.el-pagination--small .btn-next,
  .el-pagination.is-background.el-pagination--small .btn-prev{
    margin: 0px 4px;
    min-width: 24px;
  }
  .el-pagination--small .el-pager li,.el-pagination--small .btn-prev{
    font-size: 14px;
    line-height: 24px;
    height: 24px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #235EB6;
    color: #FFF;
  }
  .el-pagination.is-background .el-pager li:not(.active):hover{
    color: #235EB6;
  }
  
}

</style>