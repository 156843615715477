<template>
  <div class="pitemrow3-image-left row-container">
    <TitleBar :msg="msg"/>
    <ul class="items">
      <li v-for="item in items" :key="item.index"> 
        <div class="img">
          <img :src="item.icon" alt=""> 
        </div>
        <div class="text">
          <h3 class="title">{{ item.tname }}</h3>
          <p class="description">{{ item.content }}</p>
        </div>
      </li>
    </ul>
    <div >
      <p class="text2">{{description1}}</p>
      <p class="text2 "><span>{{description2}}</span>{{description3}}</p>
    </div>
  </div>
</template>

<script>
import TitleBar from './TitleBar.vue'

import image1 from '../assets/platform-1.png'
import image2 from '../assets/platform-2.png'
import image3 from '../assets/platform-3.png'
import image4 from '../assets/platform-4.png'
import image5 from '../assets/platform-5.png'
import image6 from '../assets/platform-6.png'

import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

export default {
  name: 'pitemrow3-image-left',
  data() {
    return {
       msg: {
        title: '',
        subTitle: ''
      },
      items: [],
      description1: '',
      description2: '',
      description3: ''
    }
  },
  components: {
    TitleBar
  },
  methods: {
    getData(params) {
      const self = this;
      get('/technology_platform.jsp', params).then(function(res){
        const data0 = res.data.data;
        self.items = data0.map(function(item){
          item.icon = imageFile(item.logo)
          return item
        })
      })
    },
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.msg.title = title['hTitle6']
        self.msg.subTitle = title['hTitle7']
        self.description1 = title['platformD1']
        self.description2 = title['platformD2']
        self.description3 = title['platformD3']
      })
    }
  },
  created: function(){
    const id = this.$store.state.currentLanguage.id
    this.getData({langtype: id})
    this.getValue({lang: id, value: 'hTitle6;hTitle7;platformD3;platformD2;platformD1;'})
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      this.getData({langtype: id})
      this.getValue({lang: id, value: 'hTitle6;hTitle7;platformD3;platformD2;platformD1;'})
    }
  },
}
</script>

<style lang="scss">
.pitemrow3-image-left {
  text-align: center;
  padding-bottom: 2rem;
  .items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: space-between;
     -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    margin-bottom: 0.5rem;
    padding: 0 6rem;
    li {
      flex: 0 0 48%;
      text-align: left;
      margin-bottom: 2rem;
      border-radius: 0.5rem;
      box-sizing: border-box;
      display: flex;
      .img{
        margin-right: 1rem;
        img {
          width: 4.2rem;
        } 
      }
      
      .text{
        flex: 1;
        font-size: 0.75rem;
        .title {
          font-weight: normal;
          //text-transform: uppercase;
          margin-bottom: 0.5rem;
        }
        .description {
          opacity: 0.6;
          // hyphens:auto; //英文换行加-
          // text-align: justify;
        }
      }
    }
  }
  .text2 {
    font-size: 0.8rem;
    line-height: 2;
    span{
      font-weight: bold;
    }
  }
  .padding-top20 {
    padding-top: 1rem;
  }
}
</style>