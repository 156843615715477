<template>
  <div class="my-header2" ref="myHeader">
    <div class="el-header row-container">
      <router-link to="/"> <img alt="Vue logo" :src="logo2"> </router-link>
      <div class="header-right">
        <div class="hotline hotline-desktop">
          <i class="el-icon-phone-outline el-icon"></i>
          <span class="call">{{hotline}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, imageFile } from '../plugins/request.js'

import logo from '../assets/logo.png'
import getQueryString from '../plugins/getQueryString.js'

export default {
  name: 'Header',
  components: {
   
  },
  data(){
    return {
      hotline: '',
      mobileMenu: false,
      mobileMenu2:false,
      metaAndTitle :{
        title: {
          'zh':'助力数字化转型 驱动业务创新 | 晨科软件',
          'en':'Boost Digital Transformation, Drive Business Innovation | Chenksoft'
        },
        description: {
          'zh':'晨科软件以先进的平台开发技术为基础，凭借专业的本地化团队和十多年软件开发经验，根据客户个性化需求进行快速开发、敏捷定制，致力于通过“软件产品+定制服务”的业务模式，全方位满足客户需求，为客户的持续发展打造合身的业务系统，提供强有力的信息化支持。',
          'en':'Based on advanced platform development technology, with a professional localization team and more than ten years of software development experience, Chenksoft conducts rapid development and agile customization according to the individual needs of customers, and is committed to adopting the business model of "Software Products + Custom Services" , to meet the needs of customers in all aspects, to create a suitable business system for the continuous development of customers, and to provide strong informationization support.'
        },
        keywords: {
          'zh':'快速开发,敏捷定制,定制开发,软件开发,管理软件,管理系统,系统开发,信息系统,网站开发,软件外包,后台系统,二次开发,app开发,app定制,HTML5开发,网页开发,app外包',
          'en':'custom software development,custom software,software development company,custom software programming,software development firm,custom software design,custom software development services,custom software development company,software application development company,custom software solution,custom software company,custom software application development,tailored software,bespoke development'
        }
      }
    }
  },
  methods: {
    selectLanguage(key) {
      const path = this.$route.path
      //console.log(path)
      this.$store.commit('changeLanguage', {id: key, path: path})
      const toPath = this.$store.state.currentLanguage.spath+path.replace(/^\/(en|cn)(\/)?/g, '\/')
      
      if( toPath.replace(/\/$/g,'') !== path.replace(/\/$/g,'')) {
        const language0 = sessionStorage.getItem('language')
        if(language0 == 0){
          document.querySelector('meta[name="keywords"]').setAttribute('content', this.metaAndTitle.keywords.zh)
          document.querySelector('meta[name="description"]').setAttribute('content', this.metaAndTitle.description.zh)
          document.title = this.metaAndTitle.title.zh
        }else {
          document.querySelector('meta[name="keywords"]').setAttribute('content', this.metaAndTitle.keywords.en)
          document.querySelector('meta[name="description"]').setAttribute('content', this.metaAndTitle.description.en)
          document.title = this.metaAndTitle.title.en
        }
      }
    },
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.hotline = title['hotline']
      })
    }
  },
  created: function(){
    const path = this.$route.path;
    //在首页的时候更新语言
    const toPath = this.$store.state.currentLanguage.spath+path.replace(/^\/(en|cn)(\/)?/g, '\/')
    // console.log(toPath, path)
    if( toPath.replace(/\/$/g,'') !== path.replace(/\/$/g,'')) {
      this.$router.push(toPath)
    }
    
    const language0 = sessionStorage.getItem('language')
    if(language0 == 0){
      document.querySelector('meta[name="keywords"]').setAttribute('content', this.metaAndTitle.keywords.zh)
      document.querySelector('meta[name="description"]').setAttribute('content', this.metaAndTitle.description.zh)
      document.title = this.metaAndTitle.title.zh
    }else if(language0 == 1){
      document.querySelector('meta[name="keywords"]').setAttribute('content', this.metaAndTitle.keywords.en)
      document.querySelector('meta[name="description"]').setAttribute('content', this.metaAndTitle.description.en)
      document.title = this.metaAndTitle.title.en
    }
    const id = this.$store.state.currentLanguage.id
    this.getValue({lang: id, value: 'hotline;'})
  },
  computed: {
    logo2: function(){
      if(this.$store.state.currentLanguage.id == 0){
        return logo
      }else {
        return logoen
      }
    },
    demo: function() {
      if(this.$store.state.currentLanguage.id == 0){
        return '免费试用'
      }else {
        return 'Demo'
      }
    }
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      this.getValue({lang: id, value: 'hotline;'})
      document.body.style.overflow='auto'
    }
  },
}
</script>

<style lang="scss">
.my-header2 {
  width: 100%;
  background: #fff;
  height: 4.2rem;
  .header-box{
    padding-bottom:1rem;
  }
   .el-header{
     padding: 1rem 1rem 0.5rem 1rem;
     background: #fff;
   }
   .header-right{
      display: flex;
      align-items: center;
      margin-top:0.25rem;
      float: right;
      .menu{
        display: none;
        img{
          width: 1.4rem;
        }
      }
      .language-mobile{
        display: none;
        font-size: 0.8rem;
        .el-dropdown-link{
          margin: 0 0.5rem;
          display: inline-block;
          line-height: 1.6;
          height: 1.4rem;
        }
        .get-demo-mobile{
           margin: 0 1rem 0 0.5rem;
           line-height: 1.6;
           height: 1.4rem;
           display: inline-block;
           color:#235EB6;
        }
      }
      .hotline {
        float: right;
        font-size: 1rem;
        color: #235EB6;
        font-weight: bold;
        .call{
          text-decoration: none;
        }
        .el-icon {
          font-size: 1.3rem;
          vertical-align: top;
          margin-right: 0.5rem;
        }
        
      }
      .language {
          font-weight: normal;
          margin-left: 1rem;
          line-height: 1.7;
        }
   }

}

</style>