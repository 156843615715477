<template>
<div class="pf-recommend2">
<!-- <TitleBarOnlyText :msg="msgZH" v-if="$store.state.currentLanguage.id==0" />
<TitleBarOnlyText :msg="msg" v-else /> -->
<div class="pf-recommend row-container clearfix">
   <div class="text"> 
     <h1>{{msg.title33}}</h1>
     <h2 class="title title-line" >
        {{msg.title}}
     </h2>
     <p>{{msg.subtitle}}</p>
   </div>
   <img src="../assets/platform-peitu.png" alt="">
 </div>
</div>
</template>

<script>
import TitleBarOnlyText from '../components/TitleBarOnlyText.vue'
import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

  export default {
    name: 'PFRecommend',
    components: {
      TitleBarOnlyText
    },
    data() {
      return{
        msg: {
          title33: '',
          title: '',
          subtitle: ''
        },
      }
      
    },
    methods: {
      getValue(params) {
        const self = this;
        get('/get_value.jsp', params).then(function(res){
          //console.log(res.data.data[0].result)
          const title = getQueryString(res.data.data[0].result)
          self.msg.title33 = title['p1Title1']
          self.msg.title = title['p1Title2']
          self.msg.subtitle = title['p1Title3']
        
        })
      }
    },
    created: function(){
      const id = this.$store.state.currentLanguage.id
      this.getValue({lang: id, value: 'p1Title1;p1Title2;p1Title3;'})
    },
    watch: {
      '$route': function(to, from) {
        const id = this.$store.state.currentLanguage.id
        this.getValue({lang: id, value: 'p1Title1;p1Title2;p1Title3;'})
      }
    }
  }
</script>

<style lang="scss">
.pf-recommend {
  .titlebar-only-text{
    padding: 2rem 0 0.5rem 0;
  }
}
.pf-recommend {
  padding: 2rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  .text {
    margin-right: 3rem;
    h1 {
      font-size: 1.5rem;
      font-weight: normal;
      margin-bottom: 1rem;
    }
    .title {
      //text-transform: uppercase;
      font-weight: normal;
      font-size: 1rem;
      position: relative;
      margin-bottom: 2rem;
      opacity: 0.6;
      &::after, &::before{
        content: '';
        display: block;
        background: #1F87E8;
        border-radius:0.4rem;
        height: 0.2rem;
        bottom: -1rem;
        position: absolute;
      }
      &::before{
        width: 6rem;
      }
      &::after {
        width: 0.2rem;
        left: 0.65rem;
      }
    }
    p {
      line-height: 1.7;
      opacity: 0.7;
      padding-top: 1rem;
    }
  }
  
}
</style>