<template>
  <div class="PItemRowVideo row-container">
    <div class="videoItem">
      <div style="width:100%">
          <video-player class="video-player vjs-custom-skin" ref="videoPlayer"
          :palysinline="true"
          :options="playerOptions"></video-player>
      </div>
      <div class="video-list">
        <ul>
           <li :class="{'active': activeVideo.index==index}" v-for="(item, index) in video" :key="index" @click="changeVideo(index, item)">
            <p>{{item.name}}</p>
            <!-- <span>{{item.desc}}</span> -->
            <!-- <i class="arrow-right-icon"></i> -->
            </li>
        </ul>
      </div>
    </div>    
  </div>
</template>

<script>
import TitleBar from './TitleBar.vue'

import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'
import imgzichan from '../assets/video-zichan.jpg'
import imgshebei from '../assets/video-shebei.jpg'
import imgdoc from '../assets/video-doc.jpg'
import imgxunjian from '../assets/video-xunjian.jpg'
import imgdidaima from '../assets/video-didaima.jpg'
import imglab from '../assets/video-lab.jpg'
import imgguochan from '../assets/video-guochan.jpg'
import imgzcpd from '../assets/video-zcpd.jpg'
import imgdangan from '../assets/video-dangan.jpg'

export default {
  name: 'PItemRowVideo',
  data() {
    return {
      item3: '',
      activeVideo:{index:0},
      video:[
        {name:'资产管理系统',desc:'资产全生命周期管理', src:'https://sites.chenksoft.com/video/ams.mp4',poster:imgzichan},
        {name:'设备管理系统', desc:'设备全生命周期管理',src:'https://sites.chenksoft.com/video/ems.mp4',poster:imgshebei},
        {name:'文件档案管理系统', desc:'功能强大，简单易用',src:'https://sites.chenksoft.com/video/doc.mp4',poster:imgdoc},
        {name:'智慧实验室管理系统', desc:'全面覆盖实验室、仪器设备、人员、环境等管理',src:'https://sites.chenksoft.com/video/lab.mp4',poster:imglab},
        {name:'工厂巡检场景下的制度管控', desc:'设备系统与AI智能眼镜相结合',src:'https://sites.chenksoft.com/video/xunjian.mp4',poster:imgxunjian},
        {name:'晨科低代码开发平台', desc:'可视化设计，拖拉式模式，简单易上手',src:'https://sites.chenksoft.com/video/didaima.mp4',poster:imgdidaima},
        {name:'支持信创',desc:'',src:'https://sites.chenksoft.com/video/guochanhua.mp4',poster:imgguochan},
        {name:'RFID资产盘点',desc:'',src:'https://sites.chenksoft.com/video/zcpd.mp4',poster:imgzcpd},
        {name:'档案库房监控',desc:'',src:'https://sites.chenksoft.com/video/dangan.mp4',poster:imgdangan},
      ],
       playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4", // 类型
          src: 'http://sites.chenksoft.com/video/设备动画.mp4' // url地址
        }],
        poster: imgzichan, // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
    }
  },
  components: {
   
  },
  methods: {
    changeVideo(index, item){
      this.activeVideo.index = index
      const self = this;
      self.playerOptions.sources[0].src = item['src']
      self.playerOptions.poster = item['poster']
    },
  },
  created: function(){
    const id = this.$store.state.currentLanguage.id
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
    }
  }
}
</script>

<style lang="scss">
.PItemRowVideo {
  text-align: center;
  margin-top: 80px;
  .videoItem{
    justify-content: space-between; 
    display: flex;
    padding:4rem 1rem;
  }
  .video-list{
    margin-left: 2rem;
    li{
      cursor: pointer;
      width: 14rem;
      padding: 0.76rem 1.5rem;
      border-radius: 2px;
      text-align: left;
      background-color: #22242f;
      margin-bottom: 0.5rem;
      position: relative;
      p{
        font-size: 18px;
        font-weight: 400;
        color: #ebeefe;
        width: 100%;
        margin-bottom: 0.2rem;
      }
      span{
        font-size: 13px;
        color: #93949b;
      }
      .arrow-right-icon{
        background: url(../assets/arrow-right-icon.png);
        display: inline-block;
        background-size: 100%;
        width: 24px;
        height: 24px;
        position: absolute;
        right: 1rem;
        top: 1.2rem;
        opacity: 0.5;
      }
    }
    li:hover{
      background-color:#2b2c37;
      .arrow-right-icon{
        opacity: 1;
      }
    }
    li.active{
      background-color:#1f87e8;
      p{
        color: #fff;
      }
      span{
        color: #fff;
      }
    }
  }
}
.video-js .vjs-big-play-button{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>