import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

let defaultLanguage = { id: 1, name: 'English', path: '/en', spath: '/en'};

const href = window.location.href;

// if(process.env.NODE_ENV!=='development'){
//   if(href.split(':')[0]=='http'){
//     window.location.href = 'https:'+href.split(':')[1]
//   }
// }
//let metaList = document.getElementsByTagName("meta");

//获取浏览器的语言设置
const language0 = sessionStorage.getItem('language')
var jsSrc =(navigator.language || navigator.browserLanguage).toLowerCase();
const path = window.location.pathname
if(path.replace('/','')=='' && !language0){
  if(jsSrc.indexOf('zh')>=0){
    sessionStorage.setItem('language', 0)
    sessionStorage.setItem('spath',  '')
    defaultLanguage = {id: 0, name: '中文', path: '/', spath: ''}
  }else{
    sessionStorage.setItem('language', 1)
    sessionStorage.setItem('spath',  '/en')
    defaultLanguage = { id: 1, name: 'English', path: '/en', spath: '/en'}
  }
}else if(path.replace('/','')==''&&language0){
  if(language0==0){
    defaultLanguage = {id: 0, name: '中文', path: '/', spath: ''}
  }else if(language0==1){
    defaultLanguage = { id: 1, name: 'English', path: '/en', spath: '/en'}
  }
}

if(path.replace('/','')){
  if(path.match(/^\/(en)(\/)?/g)!=null){
    sessionStorage.setItem('language', 1)
    sessionStorage.setItem('spath',  '/en')
    defaultLanguage = { id: 1, name: 'English', path: '/en', spath: '/en'}
  }else{
    sessionStorage.setItem('language', 0)
    sessionStorage.setItem('spath',  '')
    defaultLanguage = {id: 0, name: '中文', path: '/', spath: ''}
  }
}



// if(jsSrc.indexOf('zh')>=0){
//   sessionStorage.setItem('language', 0)
//   sessionStorage.setItem('spath',  '')
//   defaultLanguage = {id: 0, name: '中文', path: '/', spath: ''}
// }else{
//   sessionStorage.setItem('language', 1)
//   sessionStorage.setItem('spath',  '/en')
//   defaultLanguage = { id: 1, name: 'English', path: '/en', spath: '/en'}
// }

export default new Vuex.Store({
  state: {
    systemName:'',
    solutionName: '',
    currentLanguage: defaultLanguage,
    language: [
      { id: 0, name: '中文', path: '/', spath: ''},
      { id: 1, name: 'English', path: '/en', spath: '/en'},
    ]
  },
  mutations: {
    changeCurrentLanguage(y){
      state.currentLanguage = y
    },
    changeLanguage(state, params){
      
      //console.log(params.path)
      
      let path0 = state.language[params.id].spath+params.path.replace(/^\/(en|cn)(\/)?/g, '\/')
      //console.log(path0)
      if(path0=='/en'||path0=='/en/'){
        state.language[0].path = '/'
        state.language[1].path = '/en'
        state.currentLanguage = state.language[params.id]
      }else{
        state.language[params.id].path = path0
        state.currentLanguage = state.language[params.id]
      }
      sessionStorage.setItem('language', params.id)
      sessionStorage.setItem('spath',  state.language[params.id].spath)
      
      // if(state.currentLanguage.id!=params.id){
      //   let path0 = state.language[params.id].spath+params.path.replace(/^\/(en|cn)\//g, '\/')
      //   console.log(state.language[params.id].spath+',',params.path.replace(/^\/(en|cn)\//g, '\/')+',',path0)
      //   state.language[params.id].path = path0
      //   state.currentLanguage = state.language[params.id]
      //   console.log(state.currentLanguage)
      // }else{
      //   return
      // }
    },
    changeSystemName(state,params){
      state.systemName = params
    },
    changeSolutionName(state, params){
      state.solutionName = params
    }
    
  },
  actions: {
  },
  modules: {
  }
})
