<template>
  <div class="customize">
    <Header />
    <Banner />
    <CService />
    <CFlow />
    <CService2 />
    <div style="position:fixed;left: 10px; top: 545px;z-index: 99;" class="code-left-fixed">
      <img src="../assets/微信公众号.png" alt="" style="width:100px;box-shadow: 0 3px 6px 0 rgba(0,0,0,.1);">
    </div>
    <Footer />
  </div>
</template>
<script>
import CService from '../components/CService.vue'
import Banner from '../components/Banner.vue'
import Footer from '../components/Footer.vue'
import CFlow from '../components/CFlow.vue'
import CService2 from '../components/CService2.vue'
import Header from '@/components/Header.vue'

export default {
  name: 'customize',
  components: {
    CService,
    CService2,
    Banner,
    CFlow,
    Footer,
    Header
  }
}
</script>