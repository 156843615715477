<template>
  <div class="pitemrow2">
    <TitleBar :msg="msg"/>
    <div class="pitemrow2-item">
      <div class="content">
        <p class="title1">资产管理系统</p>
        <p class="title2">固定资产数字化管理系统，软硬件一体化综合解决方案，支持手机扫码和RFID等多种盘点方式</p>
        <ul>
          <li><span class="management-icon" style="background-position:0 0"></span><p>采购管理</p></li>
          <li><span class="management-icon" style="background-position:-38px 0"></span><p>资产台账</p></li>
          <li><span class="management-icon" style="background-position:-76px 0"></span><p>转移调拨</p></li>
          <li><span class="management-icon" style="background-position:-114px 0"></span><p>折旧处置</p></li>
          <li><span class="management-icon" style="background-position:-152px 0"></span><p>借用领用</p></li>
          <li><span class="management-icon" style="background-position:0 -38px"></span><p>资产盘点</p></li>
          <li><span class="management-icon" style="background-position:-38px -38px"></span><p>资产维修</p></li>
          <li><span class="management-icon" style="background-position:-76px -38px"></span><p>耗材管理</p></li>
          <li><span class="management-icon" style="background-position:-114px -38px"></span><p>无形资产</p></li>
          <li><span class="management-icon" style="background-position:-152px -38px"></span><p>资产地图</p></li>
        </ul>
        <el-button type="primary" style="width:130px;margin-top:1.5rem" @click="openConsultBox">免费试用</el-button>
      </div>
      <div class="imgright">
        <img :src="aimg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import TitleBar from './TitleBar.vue'

import arrow from '../assets/arrow-right.png'
import a from '../assets/软件产品-pow1.png'
import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'
import axios from "axios"

export default {
  name: 'PItemRow2',
  data() {
    return {
      msg:{
        title: '软件产品',
        subTitle: '资产 | 设备 | 文件档案 | eGMP | 智慧实验室'
      },
      aimg:a,
      arrow: arrow,
      item3:''
    }
  },
  components: {
    TitleBar
  },
  methods: {
    openConsultBox(){
      window.$crisp.push(['do', 'chat:open']);
    },
    getProducts(params) {
      const self = this;
      get('/product_module.jsp', params).then(function(res){
        const data0 = res.data.data;
        self.item3 = data0.map(function(item){
          item.icon = imageFile(item.logo)
          return item
        })
      })
    },
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.msg.title = title['hTitle1']
        self.msg.subTitle = title['hTitle2']
      })
    }
  },
  created: function(){
    const id = this.$store.state.currentLanguage.id
    this.getProducts({langtype: id})
    // this.getValue({lang: id, value: 'hTitle1;hTitle2;'})
    var logidUrl = window.location.href

    //this.$router.push({path:'/contact'})
    axios({
      method:'post',
      url:'https://hz.chenksoft.com/mixkit/jimuyu/eventupload',
      data: 'eventType=1&logidUrl='+logidUrl,
      headers: {
          contentType:'application/json'
      },
    }).then(function(res){
      console.log(res)
    })
      
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      this.getProducts({langtype: id})
      this.getValue({lang: id, value: 'hTitle1;hTitle2;'})
    }
  }
}
</script>

<style lang="scss">
.pitemrow2 {
  max-width: 1440px;
  margin: 1rem auto;
  .pitemrow2-item{
    display: flex;
    justify-content: space-between;
    max-width: 1440px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .content{
    width: 20.6rem;
    margin-top: 5rem;
    .title1{
      font-size: 1.6rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }
    .title2{
      font-size: 0.8rem;
      margin-bottom: 2.5rem;
      color:#666666;
    }
    ul{
      width: 18rem;
      li{
        display: inline-block;
        text-align: center;
        margin-right: 0.8rem;
        margin-bottom:1rem;
        font-size: 0.7rem;
        .management-icon{
          display: inline-block;
          width: 38px;
          height: 38px;
          background-image: url(../assets/projecticons.png);
          background-size: 500%;
          background-position:0 0;
          border: 0;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  .imgright{
    margin-top: 2rem;
  }
}
</style>