<template>
  <div class="banner">
    <!-- <el-carousel trigger="click" :autoplay="false" class="banner-desktop">
      <el-carousel-item v-for="item in items" :key="item.index">
         <img :src="item.picture" class="bg-img">
      </el-carousel-item>
    </el-carousel> -->
    <div class="swiper-container banner-des">
      <div class="link">
        <router-link :to="'/get-demo'" >
           <el-button class="border-button">免费试用</el-button>
        </router-link>
        <el-button type="primary" @click="openConsultBox">免费咨询</el-button>
      </div>
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in items" :key="item.index">
            <div class="text"><p style="font-size:28px" class="title1">{{item.title1}}</p>
            <p style="font-size:60px;font-weight:bold;" class="title2">{{item.title2}}</p>
            <p style="font-size:16px;margin-top:40px" class="title3" v-html="item.title3"></p>
            <p style="font-size:16px" class="title4">{{item.title4}}</p></div>
            <div style="width:100%;overflow:hidden;text-align:center;height:36rem" class="bg-img-box">
              <div :style="{backgroundImage:'url('+ item.picture +')'}" class="bg-img"></div>
              <!-- <img :src="item.picture" class="bg-img"> -->
            </div>
        </div>       
      </div>
      <!-- 分页器 -->
      <div class="swiper-pagination"></div>
      <!-- 导航按钮 -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
    <wc-swiper class="banner-mobile swiper-c" v-if="$store.state.currentLanguage.id==1">
      <wc-slide  v-for="(item, index) in itemsM" :key="index" class="swiper-slider">
        <div class="text row-container center">
            <div class="text-content">
              <h5 class="title" v-html="item.title"></h5>
              <p class="des" v-show="item.des" v-html="item.des"></p>
              <p class="box" v-html="areaEn"></p>
            </div>
          </div>
          <img :src="item.bg" alt="">
      </wc-slide>
    </wc-swiper>
    <wc-swiper class="banner-mobile swiper-c" v-else>
      <wc-slide  v-for="(item, index) in itemsMZh" :key="index" class="swiper-slider">
        <div class="text row-container center">
            <div class="text-content">
              <h5 class="title" v-html="item.title"></h5>
              <p class="des" v-show="item.des" v-html="item.des"></p>
              <p class="box" v-html="areaZh"></p>
            </div>
          </div>
          <img :src="item.bg" alt="">
      </wc-slide>
    </wc-swiper>
    <!-- <el-carousel trigger="click" :autoplay="false" class="banner-mobile" v-if="$store.state.currentLanguage.id==1">
      <el-carousel-item v-for="item in itemsM" >
        <div class="text row-container center">
         <div class="text-content">
            <h5 class="title" v-html="item.title"></h5>
            <p class="des" v-show="item.des" v-html="item.des"></p>
            <p class="box" v-html="areaEn"></p>
         </div>
        </div>
        <img :src="item.bg" alt="">
      </el-carousel-item>
    </el-carousel> -->
    <!-- <el-carousel trigger="click" :autoplay="false" class="banner-mobile" v-else>
      <el-carousel-item v-for="item in itemsMZh" :key="item.index">
        <div class="text row-container center">
         <div class="text-content">
            <h5 class="title" v-html="item.title"></h5>
            <p class="des" v-show="item.des" v-html="item.des"></p>
            <p class="box" v-html="areaZh"></p>
         </div>
        </div>
        <img :src="item.bg" alt="">
      </el-carousel-item>
    </el-carousel> -->
  </div>
</template>

<script>

import banner1 from '../assets/banner1.jpg'
import banner2 from '../assets/banner2.jpg'
import banner3 from '../assets/banner3.jpg'
import banner4 from '../assets/banner4.jpg'
import banner5 from '../assets/banner5.jpg'

import bg1 from '../assets/m-bg1.jpg'
import bg2 from '../assets/m-bg2.jpg'
import bg3 from '../assets/m-bg3.jpg'
import bg4 from '../assets/m-bg4.jpg'
import bg5 from '../assets/m-bg5.jpg'
import { get, imageFile } from '../plugins/request.js'

import Swiper from 'swiper';
import 'swiper/css/swiper.css'


export default {
  name: 'Banner',
  data(){
    return {
      
      areaEn:'China Singapore Mauritius Germany France Australia',
      areaZh:'上海  杭州  深圳  北京  武汉  沈阳  成都  重庆  南京  长沙 ...',
      items: [
        {picture:banner1,title1:'晨科软件 · CHENKSOFT',title2:'高品质管理软件供应商',title3:'聚焦核⼼产品&emsp;｜ 助⼒组织数智化转型',title4:'晨科低代码平台｜ 成熟稳定，灵活定制，⾼效快捷'},
        {picture:banner2,title1:'快速开发 · 敏捷定制',title2:'满足客户各种深度定制需求',title3:'资产、设备、文件档案、eGMP、智慧实验室...',title4:'杭州、上海、北京、武汉、南京、深圳、成都、广州...'},
        {picture:banner3,title1:'值得客户的信赖',title2:'7000多成功项目案例',title3:'资产、设备、文件档案、eGMP、智慧实验室...',title4:'杭州、上海、北京、武汉、南京、深圳、成都、广州...'},
        {picture:banner4,title1:'十五年行业专注',title2:'成就软件定制专家',title3:'资产、设备、文件档案、eGMP、智慧实验室...',title4:'杭州、上海、北京、武汉、南京、深圳、成都、广州...'},
        {picture:banner5,title1:'可视化表单和UI设计',title2:'晨科低代码产品构建平台',title3:'资产、设备、文件档案、eGMP、智慧实验室...',title4:'杭州、上海、北京、武汉、南京、深圳、成都、广州...'},
      ],
      itemsM: [
        {title: 'Management Software Customization Expert', des: 'Meet Your Management Needs In All Aspects, Realize Enterprise | Informationization Construction Easily | Based on Visual Custom Platform, Mature, Stable, Fast, Convenient | Creatively Integrate C/S and B/S', bg: bg1},
        {title: 'Rapid Development and Agile Customization<br/>Meet VariousIn-depth Custom Need of Customers', des: '', bg: bg2},
        {title: 'More than 5000 Successful Project Cases Worthy of Your Trust ', des: '', bg: bg3},
        {title: 'Ten Years of Industry Focus Achievement of Software Customization Expert', des: '', bg: bg4},
        {title: 'Low Cost    High Quality', des: '', bg: bg5}
      ],
      itemsMZh: [
        {title: '管理软件定制专家', des: '全方位满足您的管理所需 | 轻松实现企业信息化建设 | 基于可视化定制平台 | 成熟稳定，高效快捷 | 创造性地融合C/S 和B/S', bg: bg1},
        {title: '快速开发 敏捷定制<br/>满足客户各种深度定制需求', des: '', bg: bg2},
        {title: '五千多成功项目案例<br/>值得用户信赖', des: '', bg: bg3},
        {title: '十年行业专注<br/>成就软件定制专家', des: '', bg: bg4},
        {title: '低成本 高质量', des: '', bg: bg5}
      ]
    }
  },
  beforeCreate: function() {
    //console.log(1)
  },
  methods: {
    openConsultBox(){
      window.$crisp.push(['do', 'chat:open']);
    },
    getBanner(params) {
      const self = this;
      get('/getBanner.jsp', params).then(function(res){
        
        const data0 = res.data.data;
        self.items = data0.map(function(item, index){
          item.picture = imageFile(item.picture)
          return item
        })

      })
      // get('/getBanner.jsp', params).then(function(res){
      //   console.log(res.data.data)
      //   const data0 = res.data.data;
      //   let arr = [];
      //   self.items = data0.map(function(item, index){
      //     arr[index] = item.picture
      //     sessionStorage.setItem(`banner${params.langtype}`, arr.toString())
      //     item.picture = imageFile(item.picture)
      //     return item
      //   })

      // })
    },
  },
  mounted(){
    var swiperInstance = new Swiper('.swiper-container',{
      pagination:{
        el: '.swiper-pagination'
      },
      loop: true,
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false
      },
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    })
  },
  created: function(){
    
    const id = this.$store.state.currentLanguage.id
    const banner = sessionStorage.getItem(`banner${id}`)
    //this.getBanner({langtype: id})
    // if(banner){
    //   const arr = banner.split(',');
    //   const len = arr.length;
      
    //   let obj = []
    //   for(var i=0; i<len; i++){
    //     let obj2 = {}
    //     obj2.picture = imageFile(arr[i])
    //     obj[i] = obj2
    //   }
    //   self.items = obj
    // }else{
    //   this.getBanner({langtype: id})
    // }
    
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      //this.getBanner({langtype: id})
    }
  }
}
</script>
<style lang="scss">
.banner {
   position: relative;
   .el-carousel__container{
    height: 400px;
   }
   .link{
    width: 1040px;
    position: absolute;
    top: 70%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: 99;
    .el-button--primary{
      margin-left: 10px;
    }
   }
   .bg-img{
    background: url(/newsite/images/banner/bnerbghome_1.jpg) center center #001c47;
    background-size: auto 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
   }
   .text{
    width: 1040px;
    position: absolute;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    color: #fff;
   }
   .swiper-c{
     width: 100%;
     display: none;
     .wc-dot{
       background: rgba(255,255,255,0.8);
     }
     .wc-dot-active{
       background: #258DEC;
     }
    .swiper-slider{
      height: 16rem;
      position: relative;
    }
    .text-content{
      width: 100%;
    }
    .text{
      width: 100%;
      position: absolute;
      z-index: 2;
      color: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90%;
      flex-wrap: wrap;
      .title{
        font-size: 1.4rem;
        padding: 1rem 1.5rem 1rem 1.5rem;
      }
      .des{
        font-size: 0.7rem;
        line-height: 1.6;
        padding: 0 2rem 1rem 2rem;
        width: 100%;
      }
      .box{
        font-size: 0.6rem;
        font-weight: bold;
        border: 1px solid #258DEC;
        padding: 0.5rem 0;
        width: 17rem;
        margin: 0 auto;
      }
    }
    img{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
    }
   }
  
}

</style>
