<template>
  <div class="pitemrow25">
    <div class="content">
      <div class="text">
        <p class="title1">成熟稳定｜灵活定制｜⾼效快捷</p>
        <p class="title2">晨科低代码产品开发平台</p>
        <p class="title3">创新开发平台 . 可视化产品开发</p>
        <el-button class="border-button"  @click="openConsultBox">免费试用</el-button>
      </div>
      <img :src="aimg" alt="" style="width:100%;">
    </div>
     <div class="content2">
      <ul>
        <li><span class="management-icon" style="background-position:0 0"></span><p class="title">信创适配</p><p class="desc">全⾯⽀持国产化</p></li>
        <li><span class="management-icon" style="background-position:-10rem 0"></span><p class="title">安全是一切的基础</p><p class="desc">先进安全架构，确保数据安全</p></li>
        <li><span class="management-icon" style="background-position:-20rem 0"></span><p class="title">可视化开发</p><p class="desc">对⽐传统开发模式10倍效率提升</p></li>
        <li><span class="management-icon" style="background-position:-30rem 0"></span><p class="title">灵活流程引擎</p><p class="desc">⾃定义⼯作流程，简单易⽤</p></li>
        <li><span class="management-icon" style="background-position:-40rem 0"></span><p class="title">数据集成中心</p><p class="desc">⽀持各类外部数据源，⾮结构化数据，硬件集成</p></li>
        <li><span class="management-icon" style="background-position:0 -10rem"></span><p class="title">商业智能BI引擎</p><p class="desc">处处可BI，可对任意列表数据做BI展示</p></li>
        <li><span class="management-icon" style="background-position:-10rem -10rem"></span><p class="title">全终端适用</p><p class="desc">Web, IOS, 安卓，鸿蒙，⼩程序，H5</p></li>
        <li><span class="management-icon" style="background-position:-20rem -10rem"></span><p class="title">内置自动化测试引擎</p><p class="desc">可视化TA设计，⾃动⽣成TA脚本</p></li>
        <li><span class="management-icon" style="background-position:-30rem -10rem"></span><p class="title">多人开发+国际化</p><p class="desc">⽀持多⼈同时开发，⽀持多国语⾔⼀键翻译</p></li>
        <li><span class="management-icon" style="background-position:-40rem -10rem"></span><p class="title">创新中心</p><p class="desc">⾃定义表单，OCR，全⽂检索，⽂档在线编辑，专业⽂件预览…</p></li>
      </ul>
     </div>
  </div>
</template>

<script>
import TitleBar from './TitleBar.vue'

import arrow from '../assets/arrow-right.png'
import a from '../assets/didaima01.jpg'
import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'
import axios from "axios"

export default {
  name: 'PItemRow2',
  data() {
    return {
      msg:{
        title: '软件产品',
        subTitle: '资产 | 设备 | 文件档案 | eGMP | 智慧实验室'
      },
      aimg:a,
      arrow: arrow,
      item3:''
    }
  },
  components: {
    TitleBar
  },
  methods: {
    openConsultBox(){
      window.$crisp.push(['do', 'chat:open']);
    },
  },
  created: function(){
    
      
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      
    }
  }
}
</script>

<style lang="scss">
.pitemrow25 {
  max-width: 1920px;
  margin: 0 auto;
  .content{
     position: relative;
    .text{
      width: 1140px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: #fff;
      text-align: left;
    }
    .title1{
      font-size: 1.1rem;
      margin-bottom: 0.3rem;
    }
    .title2{
       font-weight: bold;
      font-size: 2.4rem;
      width: 30rem;
       margin-bottom: 2rem;
    }
  }
  .content2{
    padding: 2rem 0;
    ul{
      max-width: 72rem;
      margin: 0 auto;
      text-align: center;
      li{
        display: inline-block;
        text-align: center;
        margin: 1.5rem 0.5rem;
        font-size: 0.7rem;
        width: 12rem;
        vertical-align: top;
        .title{
          font-size: 1.2rem;
          font-weight: 600;
          margin-top: 1.5rem;
          margin-bottom: 0.5rem;
        }
        .desc{
          font-size: 14px;
          color: #707070;
        }
        .management-icon{
          display: inline-block;
          width: 10rem;
          height: 10rem;
          background-image: url(../assets/projecticons5.png);
          background-size: 500%;
          background-position:0 0;
          border: 0;
        }
      }
    }
  }
  .imgright{
    float: right;
    margin-top: 2rem;
  }
  .border-button{
    background-color:transparent;
    color: #fff;
    width: 7rem;
    margin-top:1.5rem;
  }
}
</style>