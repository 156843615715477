<template>
  <div class="h-news">
    <div class="header">
      <span class="title">新闻资讯</span>
      <router-link to="/news">查看全部<i class="el-icon el-icon-d-arrow-right"></i></router-link>
    </div>
    <ul class="news-box">
      <li v-for="(item, index) in items" :key="index">
        <!-- <div><img :src="item.img" alt="" ></div> -->
          <div class="date-box">
            <p class="news-day">{{item.date.d}}</p>
            <p class="news-date">{{item.date.ym}}</p>
          </div>
          <a @click="gotowx(item.url)" target="_blank" class="new-content">
            <span class="news-title">{{item.title}}</span>
            <p style="color: #666666; line-height:1.6;">{{item.desc}}</p>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import { get } from '../plugins/request.js'
import axios from 'axios'

export default {
  name: 'HNews',
  data(){
    return {
      items: []
    }
  },
  methods: {
    getData(){
      
      const self = this;
      axios.get('https://sh.chenksoft.com/mixkit/website/getarticle', {
        params:{
          offset: 0,
          count: 4,
        }
      }).then(function(res){
        //const data0 = res.data.dataEx;
       
        self.items = res.data.dataEx
        var length = res.data.dataEx.length
        var time = ''
        var htmlStr = ''
        var a;
        console.log(self.items)
        for(var i=0; i<length; i++){
          self.items[i].url = res.data.dataEx[i]['content']['news_item'][0]['url']
          self.items[i].title = res.data.dataEx[i]['content']['news_item'][0]['title']
          self.items[i].img = res.data.dataEx[i]['content']['news_item'][0]['thumb_url']
          htmlStr = res.data.dataEx[i]['content']['news_item'][0]['content']
          a = Math.ceil(Math.random()*10+50)
          self.items[i].desc = self.getHtmlPlainText(htmlStr).substr(0, a)+'...'
          time = res.data.dataEx[i]['content']['update_time']
          self.items[i].date = self.timestampToTime(time)
        }
      })
    },
    gotowx(url){
      window.open(url)
    },
    timestampToTime(timestamp){
      //时间戳为10位需乘以1000，时间戳为13不需要
      var date = new Date(timestamp * 1000)
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1): date.getMonth() + 1);
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return {'ym':Y + M, 'd':D};
    },
    getHtmlPlainText(html_str) {
      //提取字符串中的文字
      let re = new RegExp('<[^<>]+>', 'g')
      let text = html_str.replace(re, '').replace(/&nbsp;*/g, '')
      //或
      //var text = html_str.replace(/<[^<>]+>/g, "");
      return text
    },
  },
  created(){
    this.getData()
    var s = this.timestampToTime(1683184252)
    console.log(s)
  }
}
</script>
<style lang="scss">
.h-news{
  padding: 1rem;
  margin-top: 1rem;
  background: #fff;
  border: 1px solid #EBEEF5;
  border-radius: 4px;
  .header{
    text-align: left;
    font-size: 0.75rem;
    padding: 0.5rem;
    .title{
      font-size: 0.9rem;
    }
    a{
      float: right;
      opacity: 0.8;
      font-size: 0.7rem;
      color: #1F87E8;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .news-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 0.7rem;
    margin-top: 0.5rem;
    li{
      width: 46%;
      padding: 0.5rem 0 1rem 0;
      margin: 0.5rem 0.5rem 0 0.5rem;
      line-height: 2;
      text-align: left;
      border-bottom: 1px dotted #dedede;
      display: inline-block;
      &:nth-last-child(1),&:nth-last-child(2){
        border-bottom: 0;
      }
      span:last-child{
        float: right;
        opacity: 0.8;
      }
      a{
        display: inline-block;
        width: 100%;
      }
      a:hover{
        color: #1F87E8;
      }
      .new-content{
        float:right;
        vertical-align: sub;
        width:83%;
        padding-left: 10px;
      }
      .news-title{
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        font-size: 18px;
      }
      .news-date{
        text-align: center;
        color: #666;
        line-height: 1;
      }
      .news-day{
        text-align: center;
        color: #235EB6;
        font-size: 24px;
        font-weight: 600;
      }
      .date-box{
        background: #f7f8fb;
        float: left;
        width: 80px;
        height:80px;
        margin-top: 8px;
      }
    }
  }
}
</style>
