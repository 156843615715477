<template>
  <div class="platform">
    <Header />
    <Banner />
    <PFRecommend />
    <div class="bg-grey">
      <PFCore />
    </div>
    <PFEfficiency />
    <div class="bg-grey">
      <PFTools />
    </div>
    <div style="position:fixed;left: 10px; top: 545px;z-index: 99;" class="code-left-fixed">
      <img src="../assets/微信公众号.png" alt="" style="width:100px;box-shadow: 0 3px 6px 0 rgba(0,0,0,.1);">
    </div>
    <Footer />
  </div>
</template>

<script>
import Banner from '../components/Banner.vue'
import Footer from '../components/Footer.vue'
import PFRecommend from '../components/PFRecommend.vue'
import PFCore from '../components/PFCore.vue'
import PFEfficiency from '../components/PFEfficiency.vue'
import PFTools from '../components/PFTools.vue'
import Header from '@/components/Header.vue'

export default {
  name: 'platform',
  components: {
    PFRecommend,
    Header,
    Banner,
    Footer,
    PFCore,
    PFEfficiency,
    PFTools
  }
}
</script>

<style lang="scss">

</style>