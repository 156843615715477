<template>
<div class="c-service2 row-container">
  <TitleBarOnlyText :msg="msg"/>
  <div class="image-text">
    <div>
      <img src="../assets/dingzhi03.png" alt="">
    </div>
    <ul class="items flow" >
      <li v-for="item in items" :key="item.index">
        <span>{{ item.index }}</span>
        <div class="text">
          <h2>{{ item.title }}</h2>
        <p>{{ item.description }}</p>
        </div>
      </li>
    </ul>
  </div>
</div>
</template>
<script> 
import TitleBarOnlyText from './TitleBarOnlyText.vue'
import { get, imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

export default {
  name: 'cservice',
  data() {
    return{
      msg: {
        title: 'Meticulous Service, Professional Team',
        subtitle: ''
      },
      items: [
        {index: '01', title: '', description: ''},
        {index: '02',title: '', description: ''}
      ]
    }
  },
  components: {
    TitleBarOnlyText
  },
  methods: {
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.msg.title = title['scTitle5']
       
        for(var i=0;i<2; i++){
          self.items[i].title = title[`sc3Title${i+1}`]
          self.items[i].index = `0${i+1}`
          self.items[i].description = title[`sc3D${i+1}`]
        }
      })
    }
  },
   created: function(){
    const id = this.$store.state.currentLanguage.id
    this.getValue({lang: id, value: 'scTitle5;sc3Title1;sc3D1;sc3Title2;sc3D2;'})
  },
  watch: {
    '$route': function(to, from) {
      const id = this.$store.state.currentLanguage.id
      this.getValue({lang: id, value: 'scTitle5;sc3Title1;sc3D1;sc3Title2;sc3D2;'})
    }
  }
}
</script>
<style lang="scss">
.c-service2 {
  padding: 2rem 0;
  .image-text {
    display: flex;
    flex-grow: nowrap;
    align-items: center;
    img {
      margin-right: 2rem;
    }
    ul {
      li{
        display: flex;
        margin-bottom: 2rem;
        span {
          font-size: 1.8rem;
         font-family: Arial,"Noto Sans",sans-serif,"Microsoft YaHei","Helvetica Neue","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          color: #1F87E8;
          margin-right: 1rem;
        }
        h2 {
          font-weight: normal;
          text-transform: capitalize;
          margin-bottom: 0.5rem;
        }
        p {
          opacity: 0.7;
        }
        .text{flex:1}
        
      }
    }
  }
}

</style>
