<template>
  <div class="get-my-demo">
    <div class="row-container">
       <div class="step-form">
        <div class="form-header">
          <div class="step-tab step1" v-for="item in stepItem" :key="item.index">
            <div :class="{'active':item.active,'step-tab-box':true, 'step-ispass': item.isPass} ">
                <div class="number-ispass" v-if="item.isPass"><img src="../assets/验证通过.png" alt=""></div>
                <div class="number" v-else>{{item.step}}</div>
                <span class="title">{{item.title}}</span>
            </div>
          </div>
        </div>
        <div class="form-box">
          <div class="step1-container step-container" v-show="stepPass==1">
            <div class="container-left">
              <h2>请先扫，访客邀请码！</h2> 
              <div class="code">
                <img :src="imgCode" alt="">
              </div>
            </div>
            <div class="container-right">
              <img src="../assets/验证配图.png" alt="">
              <h4>{{descTitle}}</h4>
              <p style="font-size:14px">{{desc1}}</p>
            </div>
          </div>
          <div class="step2-container step-container" v-show="stepPass==2">
            <div class="container-left">
              <h2>免费，注册试用！</h2> 
              <div class="form">
                <el-form :model="form" ref="form" :rules="rules" class="form-inline">
                  <el-form-item prop="phone" >
                    <el-input v-model="form.phone"  placeholder="请输入手机号"></el-input>
                  </el-form-item>
                  <el-form-item prop="code" >
                    <el-input v-model="form.code" placeholder="请输入验证码" style="width:187px"></el-input>
                    <el-button type="info" @click="sendCode('form')" plain :disabled="codeDisabled" style="margin-left:20px;width:112px;">{{buttonText}}</el-button>
                  </el-form-item>
                  <el-form-item style="margin-bottom: 6px">
                    <el-button type="primary" @click="submitForm('form')" style="width:100%;margin-left:0;">提 交</el-button>
                  </el-form-item>
                  <el-form-item prop="privacy">
                    <el-checkbox-group v-model="form.privacy">
                      <el-checkbox name="privacy"><span> 我已阅读并同意 <router-link to="/privacy" style="color:#009AED">《隐私政策》</router-link> </span></el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div class="container-right" style="height:324px">
              <img src="../assets/验证配图.png" alt="">
              <h4>{{descTitle}}</h4>
              <p style="font-size:14px">{{desc1}}</p>
            </div>
          </div>
          <div class="step3-container step-container" v-show="stepPass==3">
            <div class="container">
              <img src="../assets/验证成功.png" alt="">
              <h2>您已成功注册免费试用</h2>
              <p>将马上跳转至试用页面<span class="dotting"></span></p>
            </div>
          </div>
        </div>
       </div>
       <div style="color:#898989; font-size: 12px;text-align: center"><span>©晨科软件 </span><span> 浙ICP备09081089号-1</span></div>
    </div>
  </div>
</template>
<script>
import { Crisp } from "crisp-sdk-web";
import axios from "axios"
import codefailedImg from "../assets/二维码失败.png"

export default {
    name: 'GetMyDemo',
    data(){
      return {
        stepPass: 0,
        stepItem:[{step:'1',title:'扫码验证',active:true,isPass: false},
        {step:'2',title:'手机验证',active:false,isPass: false},
        {step:'3',title:'注册成功',active:false,isPass: false}],
        descTitle: '晨科全业务管理平台',
        desc1: '全新一代数字化中台，创新性快速开发平台，强力支撑前台业务！晨科全业务管理平台是全新一代基于微服务、分布式架构的数字化中台、能为快速变化的前台业务提供强有力支撑。',
        form:{
          phone: '',
          code: '',
          privacy:[],
        },
        imgCode:'',
        imgcodeTimer:null,
        //验证码按钮
        codeDisabled: false,
        buttonText: '获取验证码',
        timeCount:59,
        rules:{
          phone: [
            { required: true, message: '手机号不能为空',trigger: "blur"},
            { pattern: "^1[3|4|5|7|8][0-9]{9}$", message: '请输入正常的手机号',trigger: ['blur','change'] }
          ],
          code: [
            { required: true, message: '验证码不能为空',trigger: ['blur','change']},
          ],
          privacy: [
            { type:'array',required: true, message: '请勾选隐私政策',trigger:"change"}
          ],
        }
      }
    },
    activated(){
      Crisp.configure("e126a0bf-12f1-4d90-b4b0-7c81f61e90f8")
      Crisp.chat.hide()
      var uuidCookie = this.getUuidCookie()
      var self = this
      //console.log(uuidCookie )
      //判断手机号是否注册
      axios({
        method:'get',
        url:'https://sh.chenksoft.com:281/mixkit/website/checkmobile?uuid='+uuidCookie,
        headers: {
          contentType:'application/json'
        },
      }).then(function(res){
        //表示用户已注册手机号
        
        if(res.data.code==1){
          //console.log('用户已注册')
          self.stepPass = 3
          self.activeStepTab()
          setTimeout( function(){
            window.open("https://sh.chenksoft.com:280/auto.jsp", '_blank')
          }, 2500)
          setTimeout( function(){
            self.$router.push('/');
          }, 3500)
        }else{
          //获取二维码
          //console.log('用户未注册')
          self.stepPass = 1
          self.activeStepTab()
          self.setCookie('chenkuuid','')
          uuidCookie = self.getUuidCookie()
          //console.log(uuidCookie)
          axios({
            method:'post',
            url:'https://sh.chenksoft.com:281/mixkit/website/getqrcodeticket',
            data: 'uuid='+uuidCookie,
            headers: {
              contentType:'application/json'
            },
          }).then(function(res){
            if(res.data.code==0){
              //console.log('获取二维码成功')
              self.imgCode = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket='+res.data.data
              
              //定时判断是否扫码了
              //console.log(self.imgcodeTimer)
              if (self.imgcodeTimer) {
                //console.log('关闭定时')
                clearInterval(self.imgcodeTimer);
                 self.imgcodeTimer = null;
              } else {
                self.imgcodeTimer = setInterval(self.scanCode, 1000);
                //console.log('开始定时循环')
              }
            }else{
              //console.log('获取二维码失败')
              self.imgCode = codefailedImg
            }
          })
        }
      })
   
      
    },
    methods: {
      activeStepTab(){
        var len = this.stepItem.length
        for(var i=0; i<len; i++){
          this.stepItem[i].active = false
          this.stepItem[i].isPass = false
          if(i+1==this.stepPass){
            this.stepItem[i].active = true
          }
          if(i+1<this.stepPass){
            this.stepItem[i].isPass = true
          }
        }
      },
      doLoop(){
        var timeout = setInterval(()=>{
          if(this.timeCount < 1){
            this.codeDisabled = false;
            this.buttonText = '获取验证码'
            this.timeCount = 59;
            clearInterval(timeout);
          }else{
            this.codeDisabled = true;
            this.buttonText = this.timeCount-- + "s后获取"
          }
        }, 1000)
      },
      sendCode() {
        var self = this;
        var uuidCookie = this.getCookie("chenkuuid")
        this.$refs["form"].validateField("phone", (errorMessage) => {
          if (!errorMessage) {
            //发送验证码.
            axios({
              method:'post',
              url:'https://sh.chenksoft.com:281/mixkit/website/regist',
              //data: 'mobile='+self.form.phone+'uuid='+uuidCookie,
              data:{'mobile':self.form.phone,'uuid':uuidCookie},
              headers: {
                contentType:'application/json'
              },
            }).then(function(res){
              
              if(res.data.code==1){
                //console.log('发送验证码成功')
                self.doLoop();
              }
            })
          } else {
            return false;
          }
        });
      },
      submitForm(formName) {
        var self = this;
        var uuidCookie = this.getCookie("chenkuuid")
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //表单验证成功
            axios({  
              method:'get',
              url:'https://sh.chenksoft.com:281/mixkit/website/checkmobile?uuid='+uuidCookie+'&mobile='+self.form.phone+'&code='+self.form.code,
              headers: {
                contentType:'application/json'
              },
            }).then(function(res){
              //console.log(self.form.phone+' '+self.form.code)
              if(res.data.code==1){
                self.stepPass = 3
                self.activeStepTab()
                //
                axios.get('https://hz.chenksoft.com/ckapi/api/1/v2/add_customer_information.jsp',{
                  params: {
                    token: "chenksoft!@!",
                    customer_name: self.form.phone,
                    cellphone_number: self.form.phone,
                    city: '',
                    corporate_number: '',
                    note: '',
                  }
                }).then(function(response){
                  
                })
                setTimeout( function(){
                  window.open("https://sh.chenksoft.com:280/auto.jsp", '_blank')
                }, 2500)
                setTimeout( function(){
                  self.$router.push('/');
                }, 3500)
              }else{
                alert('提交失败!');
              }
            })
            //let data = JSON.parse(JSON.stringify(this.ruleForm));
            //delete data.delivery
            //alert('submit!');
          } else {
            //console.log('error submit!!');
            return false;
          }

        });
      },
      getUuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        });
      },
      setCookie(cname,cvalue,exdays){
        var d = new Date();
        d.setTime(d.getTime()+(exdays*24*60*60*1000));
        var expires = "expires="+d.toGMTString();
        document.cookie = cname + "=" + cvalue + "; " + expires;
      },
      getCookie(cname){
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i=0; i<ca.length; i++) 
        {
          var c = ca[i].trim();
          if (c.indexOf(name)==0) return c.substring(name.length,c.length);
        }
        return "";
      },
      getUuidCookie(){
        var uuidCookie = this.getCookie("chenkuuid")
        if(uuidCookie==""||typeof(uuidCookie)=="undefined"){
          var uuid = this.getUuid()
          uuidCookie = uuid
          this.setCookie('chenkuuid',uuid, 7)
        }
        return uuidCookie
      },
      scanCode() {
        var self = this;
        var uuidCookie = this.getUuidCookie()
        axios({
          method:'get',
          url:'https://sh.chenksoft.com:281/mixkit/website/checkuuid?uuid='+uuidCookie,
          headers: {
            contentType:'application/json'
          },
        }).then(function(res){
          
          if(res.data.code==1){
            //console.log('扫描成功')
            axios({
              method:'get',
              url:'https://sh.chenksoft.com:281/mixkit/website/checkmobile?uuid='+uuidCookie,
              headers: {
                contentType:'application/json'
              },
            }).then(function(res){
              //表示用户已注册手机号
              clearInterval(self.imgcodeTimer)
              self.imgcodeTimer = null;
              if(res.data.code==1){
                //console.log('用户已注册')
                self.stepPass = 3
                self.activeStepTab()
                setTimeout( function(){
                  window.open("https://sh.chenksoft.com:280/auto.jsp", '_blank')
                }, 2500)
                setTimeout( function(){
                  self.$router.push('/');
                }, 3500)
              }else{
                self.stepPass = 2
                self.activeStepTab()
                
              }
            })
          }
        })
      }
    },
    beforeDestroy() {
        clearInterval(this.imgcodeTimer);        
        this.imgcodeTimer = null;
        //console.log(this.imgcodeTimer)            //输出为: null,但是任务依然在继续运行
    }
  }
</script>
<style lang="scss">

.get-my-demo{
  width: 100%;
  .step-form{
    background: #fff;
    margin: 70px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px 0px;
    height: 28rem;
    .form-header{
      width: 100%;
      border-bottom: 2px solid #F5F7F9;
      text-align: center;
      .step-tab{
        display: inline-block;
        width: 33.3%;
        text-align: center;
        height: 3.5rem;
        color: #A4A4A4;
        padding: 1.1rem 0;
        vertical-align: top;
        .step-tab-box{
          margin: 0 4rem;
        }
        .active{
          color: #235EB6;
          .number{
            color: #fff;
            border-color: #235EB6;
            background-color: #235EB6;
          }
        }
        .step-ispass{
          color: #222222;
          .number-ispass{
            display: inline-block;
            margin-right: 0.5rem;
          }
        }
        .number{
          display: inline-block;
          width: 1.3rem;
          height: 1.3rem;
          line-height: 1.1rem;
          border: 2px solid #A4A4A4;
          border-radius: 50%;
          margin-right: 0.5rem;
        }
        .title{
          font-size: 0.9rem;
          vertical-align: top;
        }
      }
    }
  }
  .form-box{
    padding: 3.5rem 5.5rem;
    .el-button--info.is-plain{
      color: #909399;
      background: #fff;
      border-color: #d3d4d6;
    }
    .el-button--info.is-plain:focus, .el-button--info.is-plain:hover{
      color: #235EB6;
      background: #f4f4f5;
      border-color: #d3d4d6;
    }
    .el-button--primary{
      background-color: #235EB6;
    border-color: #235EB6;
    }
    .el-button--primary:focus, .el-button--primary:hover{
      background-color: #2D76E3;
      border-color: #2D76E3;
    }
    .step-container{
      .container-left {
        width: 55%;
        display: inline-block;
        vertical-align: bottom;
        .code{
          width: 10rem;
          height: 10rem;
          background: #EDF7FF;
          margin-top: 1.5rem;
          img{
            width: 100%;
            padding: 0.5rem;
          }
        }
        .form{
          margin-top: 1.5rem;
          width: 320px;
        }
        h2{
          font-weight: normal;
          color: #222222;
          font-size: 1rem;
          margin-bottom: 1rem;
        }
      }
      .container-right {
        width: 38%;
        display: inline-block;
        line-height: 1.7;
        color: #5C5C5C;
        font-size: 0.7rem;
        h4{
          font-size: 0.9rem;
          color: #333;
          font-weight: 600;
          margin: 10px 0;
        }
      }
      .container{
        text-align: center;
        h2{
          font-size: 1rem;
          font-weight: 600;
          margin-top: 1rem;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
.dotting { display: inline-block; width: 10px; min-height: 2px;
            padding-right: 2px;
            border-left: 2px solid #222222; border-right: 2px solid #222222;
            background-color: #222222; background-clip: content-box;
            box-sizing: border-box;
            -webkit-animation: dot 1.5s infinite step-start both;
            animation: dot 1.5s infinite step-start both;
 }
 .dotting::before { content: ''; }
  @keyframes dot {
            25% { border-color: transparent; background-color: transparent; }
            50% { border-right-color: transparent; background-color: transparent; }
            75% { border-right-color: transparent; }
  }
 /** 其他内核浏览器，用于pc*/
 .dotting:before { content: ''; } /* IE8 */
  :root .dotting { margin-left: 2px; padding-left: 2px; } /* IE9+ */
 @-o-keyframes dot {
            25% { border-color: transparent; background-color: transparent; }
            50% { border-right-color: transparent; background-color: transparent; }
            75% { border-right-color: transparent; }
 }
 @-ms-keyframes dot {
            25% { border-color: transparent; background-color: transparent; }
            50% { border-right-color: transparent; background-color: transparent; }
            75% { border-right-color: transparent; }
 }
@-moz-keyframes dot {
            25% { border-color: transparent; background-color: transparent; }
            50% { border-right-color: transparent; background-color: transparent; }
            75% { border-right-color: transparent; }
 }
 @-webkit-keyframes dot {
            25% { border-color: transparent; background-color: transparent; }
            50% { border-right-color: transparent; background-color: transparent; }
            75% { border-right-color: transparent; }
 }

</style>