<template>
  <div class="content pp-character">
    <!-- <h2>{{$store.state.systemName}}</h2> -->
    <h3 class="title-p">{{ leftTitle }}</h3>
    <div v-for="item in items" :key="item.index">
      <h5>{{item['title-2']}}</h5>
      <el-divider></el-divider>
      <p>{{item.content}}</p>
    </div>
  </div>
</template>
<script>
import img1 from '../assets/1.png'
import { get, imageFile } from '../plugins/request.js'

export default {
  name: 'PPCharacter',
  props: {
    leftTitle: String
  },
  data() {
    return {
      systemName: '',
      items: []
    }
  },
  methods: {
    getData() {
      const id = this.$store.state.currentLanguage.id
      const params = this.$route.params.id;
      const self = this;
      get('/system_trait.jsp', {langtype: id, sId: params}).then(function(res){
        //console.log(res)
        const data0 = res.data.data;
        self.title = data0[0]['title-2']
        self.items = data0
      })
    }
  },
  created: function(){
    this.getData()
  },
  watch: {
    '$route': function(to, from) {
      if(to.params.id){
         this.getData()
      }
    }
  }
}
</script>
<style lang="scss">
.pp-character {
  
   
    
    h2 {
      font-size: 1.4rem;
      margin: 0;
      font-weight: 400;
    }
    .title {
      color: #1F87E8;
      background: #ecf5ff;
      padding: 0.5rem 0;
      &::before{
        content: '';
        display: inline-block;
        width: 0.2rem;
        height: 0.9rem;
        margin-right: 0.5rem;
        background-color: #1F87E8;
      }
    }
    h3 {
      font-size: 1.1rem;
      margin: 1.5rem 0 1rem;
      font-weight: 400;
    }
    h4 {
      font-size: 0.9rem;
      font-weight: 400;
      margin: 1.5rem 0 0.5rem;
    }
    h5 {
      font-size: 0.8rem;
      margin: 1.5rem 0 0.5rem;
      font-weight: 400;
    }
    p{
      opacity: 0.7;
      line-height: 1.8;
    }
    img {
      margin-top: 2rem;
    }
 
}
.el-divider--horizontal {
  margin: 0.5rem 0 !important;
}
</style>
